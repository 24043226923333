import { Subject, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import * as SearchApi from '../../apis/Search';

class SearchService {
    constructor(mode) {
        this.subject = new Subject();
        this.mode = mode;

        this.fetch = this.fetch.bind(this);
    }

    search(value) {
        this.subject.next(value);
    }

    fetch(value) {
        const mode = this.mode;

        return from(SearchApi.get(value, mode));
    }

    suggestions() {
        return this.subject.pipe(debounceTime(500), distinctUntilChanged(), switchMap(this.fetch));
    }
}

export default SearchService;
