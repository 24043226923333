import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';
import { SearchIcon } from '@fiverr-private/icons';
import * as SearchSelector from '../../selectors/Search';
import { isTheme } from '../utils';
import { THEME, SUBMIT_BUTTON_TYPE } from '../../utils/constants';

import './style.scss';

const SubmitButton = ({ className, searchTitle = 'search_bar.search', theme, type }) => {
    const { locale } = getContext();
    const isButtonInside = type === SUBMIT_BUTTON_TYPE.BUTTON_INSIDE;

    /*
     * We need to set theme to DARK_GREEN if the theme is DEFAULT and the button has type BUTTON_INSIDE
     * in order to don't break existing usages.
     */
    const isDarkGreenTheme = isTheme(theme, THEME.DARK_GREEN) || (isTheme(theme, THEME.DEFAULT) && isButtonInside);

    const ThemeButton = () => {
        if (type === SUBMIT_BUTTON_TYPE.TEXT) {
            return translate(searchTitle, { locale });
        }

        return (
            <div className="submit-button-icon">
                <SearchIcon />
            </div>
        );
    };

    return (
        <Button
            className={classnames(
                'submit-button',
                { 'flex-center': type === SUBMIT_BUTTON_TYPE.BUTTON_INSIDE },
                { 'dark-search-button': isTheme(theme, THEME.DARK) },
                { 'dark-green-search-button': isDarkGreenTheme },
                className
            )}
        >
            <ThemeButton />
        </Button>
    );
};

SubmitButton.propTypes = {
    className: PropTypes.string,
    searchTitle: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
};

SubmitButton.defaultProps = {
    theme: 'default',
};

const mapStateToProps = (state) => ({
    searchTitle: SearchSelector.searchTitle(state),
    rollouts: state.rollouts,
});

export { SubmitButton };

export default connect(mapStateToProps)(SubmitButton);
