import { createContext, useContext, type RefObject } from 'react';
import type { UseFloatingReturn } from '@floating-ui/react-dom';
import type { TriggerEvent } from '../types';

interface PopoverContextType {
    floatingData: UseFloatingReturn;
    setOpen: (value: boolean) => void;
    open: boolean;
    arrowRef: RefObject<HTMLDivElement>;
    triggerEvent: TriggerEvent;
}

export const PopoverContext = createContext<PopoverContextType>({} as PopoverContextType);

export const usePopoverContext = () => {
    const context = useContext(PopoverContext);
    if (context === undefined) {
        throw new Error('usePopoverContext must be used within a PopoverContextProvider');
    }
    return context;
};
