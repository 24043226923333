import React, { useContext } from 'react';
import { isBuilderChildren } from '../utils';
import { renderBuilderChildren } from './renderBuilderChildren';
import { ParentPropsOverrideContext } from './parentPropsOverrideContext';

/**
 * Safely gets a renderable output of most given values.
 */
const FALLBACK_VALUE = null;

export const useParentPropsOverrideContext = () => useContext(ParentPropsOverrideContext);

export const renderChildren = (child: any, props = {}) => {
    let rendered = child;

    if (isBuilderChildren(rendered)) {
        return renderBuilderChildren(rendered, props);
    }

    const mergedProps = {
        ...(rendered ? rendered.props : {}),
        ...props,
    };
    if (React.isValidElement(rendered)) {
        return React.cloneElement(rendered, mergedProps);
    }

    if (typeof child === 'function') {
        rendered = child(mergedProps);
    }

    if (['undefined', 'boolean'].includes(typeof rendered)) {
        return FALLBACK_VALUE;
    }

    return rendered;
};
