import SearchService from '../../services/Search';
import * as SearchSelector from '../../selectors/Search';
import { SET_SEARCH, SUBMIT_SEARCH, SEARCH_WILL_SUBMIT, setSuggestions, searchWillSubmit } from '../../actions';
import { USER_SEARCH } from '../../selectors/Suggestions';
import { appendSearchToLocalStorage } from '../../utils/localStorageHistory';
import { MAX_QUERY_LENGTH } from '../../utils/localStorageHistory/constants';

const getSuggestionsWithoutUsers = (suggestions) => {
    const result = {};

    Object.entries(suggestions).forEach(([key, value]) => {
        if (value.group !== USER_SEARCH) {
            result[key] = value;
        }
    });

    return result;
};

const Search = (store) => {
    const state = store.getState();
    const { mode } = state;
    const searchService = new SearchService(mode);

    searchService
        .suggestions()
        .subscribe((suggestions) => store.dispatch(setSuggestions(getSuggestionsWithoutUsers(suggestions))));

    return (next) => (action) => {
        const { type, payload } = action;

        const result = next(action);

        if (type === SET_SEARCH) {
            const query = SearchSelector.search(store.getState());

            searchService.search(query);
            store.getState().onSearch?.(query);
        }

        if (type === SUBMIT_SEARCH) {
            const { submitSource, queryParams } = payload;
            const state = store.getState();
            const search = SearchSelector.search(state);
            const searchPath = SearchSelector.searchPath(state, queryParams);

            if (search.length && SearchSelector.shouldSubmitSearch(state, submitSource)) {
                search.length < MAX_QUERY_LENGTH && appendSearchToLocalStorage(search);
                store.dispatch(searchWillSubmit({ searchPath }));
                window.location.assign(searchPath);
            }
        }

        if (type === SEARCH_WILL_SUBMIT) {
            const { searchPath } = payload;
            store.getState().searchWillSubmit?.(searchPath);
        }

        return result;
    };
};

export default Search;
