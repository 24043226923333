import React from 'react';
import { L2_ICON_SIZES } from '../../constants';
import type { L2IconType } from '../../types';

const RewardsProgramL2Icon = ({ size = 'sm' }: L2IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={L2_ICON_SIZES[size]}
    height={L2_ICON_SIZES[size]}
    fill="none"
    viewBox="0 0 48 48"
  >
    <g clipPath="url(#rewards_program_svg__a)">
      <g filter="url(#rewards_program_svg__b)">
        <path
          fill="#fff"
          d="M11.382 4.306a2.26 2.26 0 0 1 2.236 0l7.244 4.134A2.26 2.26 0 0 1 22 10.399v8.221c0 .81-.434 1.558-1.138 1.96l-7.244 4.133a2.26 2.26 0 0 1-2.236 0l-7.244-4.134A2.26 2.26 0 0 1 3 18.62v-8.221c0-.81.434-1.558 1.138-1.96z"
        />
        <path
          stroke="#E4E5E7"
          strokeWidth={0.5}
          d="M11.506 4.523a2 2 0 0 1 1.988 0l7.244 4.134a2 2 0 0 1 1.012 1.742v8.221c0 .72-.386 1.385-1.012 1.742l-7.244 4.134a2 2 0 0 1-1.988 0l-7.244-4.134A2 2 0 0 1 3.25 18.62v-8.221c0-.72.386-1.385 1.012-1.742z"
        />
      </g>
      <path
        fill="#C5C6C9"
        d="M11.5 6.577a2 2 0 0 1 2 0L19 9.75a2 2 0 0 1 1 1.732v6.344a2 2 0 0 1-1 1.732l-5.5 3.173a2 2 0 0 1-2 0L6 19.558a2 2 0 0 1-1-1.732v-6.344A2 2 0 0 1 6 9.75z"
      />
      <g filter="url(#rewards_program_svg__c)">
        <path
          fill="#fff"
          d="M34.382 4.306a2.26 2.26 0 0 1 2.236 0l7.244 4.134A2.26 2.26 0 0 1 45 10.399v8.221c0 .81-.434 1.558-1.138 1.96l-7.244 4.133a2.26 2.26 0 0 1-2.236 0l-7.244-4.134A2.26 2.26 0 0 1 26 18.62v-8.221c0-.81.434-1.558 1.138-1.96z"
        />
        <path
          stroke="#E4E5E7"
          strokeWidth={0.5}
          d="M34.506 4.523a2 2 0 0 1 1.988 0l7.244 4.134a2 2 0 0 1 1.012 1.742v8.221c0 .72-.386 1.385-1.012 1.742l-7.244 4.134a2 2 0 0 1-1.988 0l-7.244-4.134a2 2 0 0 1-1.012-1.742v-8.221c0-.72.386-1.385 1.012-1.742z"
        />
      </g>
      <path
        fill="#E8F094"
        d="M34.5 6.577a2 2 0 0 1 2 0L42 9.75a2 2 0 0 1 1 1.732v6.344a2 2 0 0 1-1 1.732l-5.5 3.173a2 2 0 0 1-2 0L29 19.558a2 2 0 0 1-1-1.732v-6.344a2 2 0 0 1 1-1.732z"
      />
      <g filter="url(#rewards_program_svg__d)">
        <path
          fill="#fff"
          d="M22.897 2.288a2.26 2.26 0 0 1 2.206 0l8.744 4.9A2.26 2.26 0 0 1 35 9.158v9.687c0 .816-.44 1.568-1.153 1.967l-8.744 4.901a2.26 2.26 0 0 1-2.206 0l-8.744-4.9A2.26 2.26 0 0 1 13 18.844V9.157c0-.817.44-1.57 1.153-1.968z"
        />
        <path
          stroke="#E4E5E7"
          strokeWidth={0.5}
          d="M23.02 2.506a2 2 0 0 1 1.96 0l8.745 4.9a2 2 0 0 1 1.025 1.75v9.688c0 .725-.392 1.394-1.025 1.75l-8.744 4.9a2 2 0 0 1-1.962 0l-8.744-4.9a2 2 0 0 1-1.025-1.75V9.157c0-.726.392-1.395 1.025-1.75z"
        />
      </g>
      <path
        fill="url(#rewards_program_svg__e)"
        d="M23.029 4.54a2 2 0 0 1 1.942 0l7 3.888A2 2 0 0 1 33 10.177v7.646a2 2 0 0 1-1.029 1.749l-7 3.888a2 2 0 0 1-1.942 0l-7-3.889A2 2 0 0 1 15 17.824v-7.646a2 2 0 0 1 1.029-1.749z"
      />
      <path
        fill="#fff"
        d="m21.016 12.403 1.395.726 1.39-1.52c.107-.117.314-.144.464-.06q.045.024.077.06l1.39 1.52 1.395-.726c.152-.08.358-.048.46.072a.22.22 0 0 1 .054.174l-.546 3.622c-.02.13-.162.229-.33.229h-5.387c-.169 0-.31-.098-.33-.23l-.546-3.621c-.021-.143.11-.271.291-.288a.4.4 0 0 1 .223.041"
      />
      <rect width={42} height={2} x={3} y={36} fill="#C5C6C9" rx={1} />
      <g filter="url(#rewards_program_svg__f)">
        <circle cx={24} cy={37} r={7} fill="#fff" />
        <circle cx={24} cy={37} r={6.75} stroke="#E4E5E7" strokeWidth={0.5} />
      </g>
      <path
        fill="#222325"
        fillRule="evenodd"
        d="M27.226 34.859a.657.657 0 0 1 0 .929l-3.503 3.503a.657.657 0 0 1-.929 0l-1.752-1.751a.657.657 0 1 1 .93-.93l1.287 1.288 3.038-3.039a.657.657 0 0 1 .93 0"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="rewards_program_svg__b"
        width={25}
        height={27}
        x={0}
        y={1.01}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_42047_3863" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_42047_3863" result="shape" />
      </filter>
      <filter
        id="rewards_program_svg__c"
        width={25}
        height={27}
        x={23}
        y={1.01}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_42047_3863" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_42047_3863" result="shape" />
      </filter>
      <filter
        id="rewards_program_svg__d"
        width={28}
        height={30}
        x={10}
        y={-1}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_42047_3863" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_42047_3863" result="shape" />
      </filter>
      <filter
        id="rewards_program_svg__f"
        width={20}
        height={20}
        x={14}
        y={27}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_42047_3863" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_42047_3863" result="shape" />
      </filter>
      <linearGradient id="rewards_program_svg__e" x1={15} x2={40.475} y1={-2} y2={5.745} gradientUnits="userSpaceOnUse">
        <stop stopColor="#005C25" />
        <stop offset={1} stopColor="#BBCE00" />
      </linearGradient>
      <clipPath id="rewards_program_svg__a">
        <rect width={48} height={48} fill="#fff" rx={5.565} />
      </clipPath>
    </defs>
  </svg>
);
RewardsProgramL2Icon.displayName = 'RewardsProgramL2Icon';
export default RewardsProgramL2Icon;
