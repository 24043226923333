import { defaults } from 'lodash';
import { calculateGroup, clientAllocate } from '@fiverr-private/experiments_package';
import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_EXPERIMENT_FIELDS } from './constants';
import { clientExperiments } from '.';

export const calculateClientExperimentsGroups = (rollouts) => {
    const context = getContext();
    const calculatedGroups = {};

    Object.values(clientExperiments).forEach((experiment) => {
        const { experimentId, experimentType, numOfGroups } = defaults(experiment, DEFAULT_EXPERIMENT_FIELDS);

        if (experiment.getRolloutResult(rollouts) && experiment.shouldAllocate(context)) {
            calculatedGroups[experimentId] = calculateGroup({ experimentId, experimentType, numOfGroups, context });
        }
    });

    return calculatedGroups;
};

export const allocateToClientExperiment = (experiment, rollouts) => {
    const { experimentId, experimentType, numOfGroups } = defaults(experiment, DEFAULT_EXPERIMENT_FIELDS);

    return clientAllocate({
        experimentId,
        experimentType,
        numOfGroups,
        rolloutResult: experiment.getRolloutResult(rollouts),
    });
};
