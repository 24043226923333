import React from 'react';
import { theme } from '@fiverr-private/theme';
import { SYSTEM_ICON_SIZES } from '../../constants';
import type { SystemIconType } from '../../types';

const SearchIcon = ({ size = 'sm', color = 'currentColor' }: SystemIconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SYSTEM_ICON_SIZES[size]}
    height={SYSTEM_ICON_SIZES[size]}
    fill={theme.colors[color]}
    viewBox="0 0 16 16"
  >
    <path d="m15.89 14.653-3.793-3.794a.37.37 0 0 0-.266-.109h-.412A6.499 6.499 0 0 0 6.5 0C2.91 0 0 2.91 0 6.5a6.499 6.499 0 0 0 10.75 4.919v.412c0 .1.04.194.11.266l3.793 3.794a.375.375 0 0 0 .531 0l.707-.707a.375.375 0 0 0 0-.53M6.5 11.5c-2.762 0-5-2.238-5-5s2.238-5 5-5 5 2.238 5 5-2.238 5-5 5" />
  </svg>
);
SearchIcon.id = 'SearchIcon';
SearchIcon.displayName = 'SearchIcon';
export default SearchIcon;
