const initDimBackgroundUtil = (isEnabled) => {
    let dimBackgroundElement;
    const mainWrapperElement = document.querySelector('#main-wrapper');
    const headerElement = document.querySelector('.main-header, #main-wrapper-header');
    const categoriesMenuElement = document.querySelector('#CategoriesMenu');

    const isHeaderSticky = () => {
        const stickyHeaderElement = document.querySelector('.main-header.sticky');

        return !!stickyHeaderElement;
    };

    const isSticky = isHeaderSticky();

    const createDimBackgroundElement = () => {
        if (!isEnabled || !mainWrapperElement || !headerElement) {
            return;
        }

        dimBackgroundElement = document.createElement('div');
        dimBackgroundElement.classList.add('search-bar-dim-background');

        if (isSticky) {
            headerElement.insertBefore(dimBackgroundElement, headerElement.firstChild);
        } else {
            mainWrapperElement.appendChild(dimBackgroundElement);
        }
    };

    const setDimBackgroundElementPosition = () => {
        if (!isEnabled || !headerElement || !dimBackgroundElement) {
            return;
        }

        const headerRect = headerElement.getBoundingClientRect();
        const categoriesMenuRect = categoriesMenuElement?.getBoundingClientRect() || {};

        const { height: headerHeight } = headerRect;
        const { height: categoriesMenuHeight = 0 } = categoriesMenuRect;

        const pageHeight = isSticky ? '100vh' : '100%';
        const calculatedHeight = headerHeight - categoriesMenuHeight;

        dimBackgroundElement.style.top = `${calculatedHeight}px`;
        dimBackgroundElement.style.height = `calc(${pageHeight} - ${calculatedHeight}px)`;
    };

    const showDimBackground = () => {
        if (!isEnabled || !dimBackgroundElement) {
            return;
        }

        setDimBackgroundElementPosition();

        dimBackgroundElement.style.visibility = 'visible';
        dimBackgroundElement.style.opacity = '0.6';
    };

    const hideDimBackground = () => {
        if (!isEnabled || !dimBackgroundElement) {
            return;
        }

        dimBackgroundElement.style.visibility = 'hidden';
        dimBackgroundElement.style.opacity = '0';
    };

    createDimBackgroundElement();
    hideDimBackground();

    return {
        showDimBackground,
        hideDimBackground,
    };
};

export default initDimBackgroundUtil;
