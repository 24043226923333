import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from '@fiverr-private/i18n-react';
import { BriefIcon } from '@fiverr-private/icons';
import { Container, Stack } from '@fiverr-private/layout_components';
import { theme } from '@fiverr-private/theme';
import { useOpenBriefCreationDrawer } from '@fiverr-private/dm_brief_creation_triggers';
import { useOpenExpertsSourcingDrawer } from '@fiverr-private/experts_sourcing';
import { RoutingButton } from '../RoutingButton';
import {
    collapseTextWhenSearchBarClosed,
    collapseTextWhenSearchBarOpen,
    onRoutingButtonClick,
    useRoutingSearchBarContext,
} from '../lib';
import { adjustCurrencyObject } from '../../../utils';
import { Buttons } from '../lib/types';
import PersonIcon from './PersonIcon';

const TRANSLATIONS_PREFIX = 'search_bar.pro.routing';

const RoutingButtons = ({ currency, source }) => {
    const { isRoutingSearchBarOpen, isSearchOnly } = useRoutingSearchBarContext();
    const { openBriefCreationDrawer } = useOpenBriefCreationDrawer();
    const { openExpertsSourcingDrawer } = useOpenExpertsSourcingDrawer();

    const handleBriefClick = () => {
        openBriefCreationDrawer({
            currency: adjustCurrencyObject(currency),
            source: 'search_bar',
        });
        onRoutingButtonClick(source, Buttons.POST_A_BRIEF);
    };

    const handleSourcingClick = () => {
        openExpertsSourcingDrawer({
            currency: adjustCurrencyObject(currency),
            source: 'search_bar',
        });
        onRoutingButtonClick(source, Buttons.EXPERT_SOURCING);
    };

    return (
        <Container display={{ default: 'none', sm: 'flex' }}>
            <Stack
                gap={isSearchOnly ? 0 : '1'}
                display="inlineFlex"
                direction="row"
                alignItems="center"
                justifyContent="center"
                padding={isSearchOnly ? 0 : '1'}
                borderTopRightRadius="circle"
                borderBottomRightRadius="circle"
            >
                <RoutingButton
                    onClick={handleBriefClick}
                    icon={<BriefIcon color="grey_1200" />}
                    text={
                        <I18n
                            k={`${TRANSLATIONS_PREFIX}.post_a_brief`}
                            templates={{
                                collapse: (text) => (
                                    <span
                                        className={
                                            isRoutingSearchBarOpen
                                                ? collapseTextWhenSearchBarOpen
                                                : collapseTextWhenSearchBarClosed
                                        }
                                    >
                                        {text}
                                    </span>
                                ),
                            }}
                        />
                    }
                />
                <RoutingButton
                    onClick={handleSourcingClick}
                    icon={<PersonIcon size={14} color={theme.colors.grey_1200} />}
                    text={
                        <I18n
                            k={`${TRANSLATIONS_PREFIX}.sourcing_experts`}
                            templates={{
                                collapse: (text) => (
                                    <span
                                        className={
                                            isRoutingSearchBarOpen
                                                ? collapseTextWhenSearchBarOpen
                                                : collapseTextWhenSearchBarClosed
                                        }
                                    >
                                        {text}
                                    </span>
                                ),
                            }}
                        />
                    }
                />
            </Stack>
        </Container>
    );
};

export const currencyObjectType = PropTypes.shape({
    code: PropTypes.string,
    template: PropTypes.string,
    symbol: PropTypes.string,
    name: PropTypes.string,
    exchange_rate: PropTypes.number,
});

RoutingButtons.propTypes = {
    currency: currencyObjectType,
    source: PropTypes.string,
};

const mapStateToProps = (state) => ({
    currency: state.currency,
    source: state.source,
});

export default connect(mapStateToProps)(RoutingButtons);
