import { stats } from '@fiverr-private/obs';
import { STATS_PREFIX } from '../constants';

/**
 * Report a request time measurement metric.
 *
 * @param {string} operationStatus The request operation status
 * @param {string} apiName The request api name
 * @param {number} startTime The request starting time
 */
export const reportRequestTime = (apiName, operationStatus, startTime) => {
    const timePassed = Date.now() - startTime;

    stats.time(STATS_PREFIX, `api.${apiName}.${operationStatus}`, timePassed);
};

/**
 * Report a request status metric.
 *
 * @param apiName The request api name
 * @param {string} suffix The metric suffix
 */
export const reportRequestStatus = (apiName, suffix) => {
    stats.count(STATS_PREFIX, `api.${apiName}.${suffix}`);
};
