import { logger } from '@fiverr-private/obs';
import { toParts } from '../utils/toParts';
import { overrideCurrencySymbol } from '../utils/currencySymbol';
import { validateOptions } from '../utils/validateOptions';
import { ERROR_MESSAGES } from '../constants';
import { LocalizationContext } from '../../../shared/constants';
import { CURRENCY_STYLES, CURRENCY_FORMATTING_OPTIONS } from './constants';

const CURRENCY_PARTS = {
    INTEGER: 'integer',
    DECIMAL: 'decimal',
    FRACTION: 'fraction',
    MINUS_SIGN: 'minusSign',
    LITERAL: 'literal',
    CURRENCY: 'currency',
};

export interface currencyFormatOptions {
    fallbackValue?: string;
    formattingLocale?: string;
    style?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    noFractionDigits?: boolean;
    asNumber?: boolean;
    asParts?: boolean;
}

export interface currencyFormatParams extends LocalizationContext {
    value: number;
    currencyCode: string;
    options?: currencyFormatOptions;
}

/**
 * Format a currency.
 * Use this to format currencies.
 * Return the number as a string, formatted to user formatting locale.
 * @param {Object} localizationContext - the localization context that is being injected
 * dynamically according to the run time env by one of the wrappers - withBrowserContext / withMainContext / withModuleContext
 * @param {number} value Amount to be formatted.
 * @param {string} currencyCode Currency code.
 * @param {Object} [options={}] Optional formatting configurations.
 * @returns {string|number} Formatted value string.
 */
const currencyFormat = ({ localizationContext, value, currencyCode, options = {} }: currencyFormatParams) => {
    const fallbackValue = options.fallbackValue || `${value} ${currencyCode}`;

    try {
        if (Intl === undefined) {
            logger.warn(ERROR_MESSAGES.INTL_IS_NOT_DEFINED);
            return fallbackValue;
        }

        if (!localizationContext || Object.keys(localizationContext).length === 0) {
            return fallbackValue;
        }

        const { asNumber, asParts } = options;
        const formattingLocale = options.formattingLocale || localizationContext.formattingLocale;
        const parsedOptions = parseOptions(value, currencyCode, options);
        const formattedString = new Intl.NumberFormat(formattingLocale, parsedOptions).format(value);
        const formattedStringOverriddenCurrency = overrideCurrencySymbol({
            formattedString,
            parsedOptions,
            currencyCode,
        });

        if (asNumber || asParts) {
            const formattedNumber = parseFloat(
                formattedStringOverriddenCurrency.replace(/[^0-9,.-]/gi, '').replace(/,/g, '.')
            );
            return asNumber
                ? formattedNumber
                : toParts({
                      localizationContext,
                      parsedOptions,
                      formattingLocale,
                      currencyCode,
                      formattedString: formattedStringOverriddenCurrency,
                      formattedNumber,
                  });
        }

        return formattedStringOverriddenCurrency;
    } catch (error) {
        logger.error(error as Error, { value, options, formatter: 'currencyFormat' });
        return fallbackValue;
    }
};

/**
 * Parse formatter options.
 * This method validates the properties in the options object and overrides properties for Fiverr custom formatting rules.
 * @param {Object} options Optional formatting configurations.
 * @returns {Object} Validated formatting configurations.
 */
const parseOptions = (value: number, currencyCode: string, options: currencyFormatOptions) => {
    const validatedOptions = validateOptions(CURRENCY_FORMATTING_OPTIONS, options);

    if (
        value % 1 === 0 &&
        !Object.prototype.hasOwnProperty.call(validatedOptions, 'maximumFractionDigits') &&
        !Object.prototype.hasOwnProperty.call(validatedOptions, 'minimumFractionDigits')
    ) {
        validatedOptions.maximumFractionDigits = 0;
        validatedOptions.minimumFractionDigits = 0;
    }

    if (options.noFractionDigits) {
        validatedOptions.minimumFractionDigits = 0;
        validatedOptions.maximumFractionDigits = 0;
    }

    validatedOptions.style = 'currency';
    validatedOptions.currency = currencyCode;

    if (options.asNumber || options.asParts) {
        validatedOptions.useGrouping = false;
    }

    return validatedOptions;
};

export { CURRENCY_STYLES, CURRENCY_PARTS, currencyFormat, parseOptions };
