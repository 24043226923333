export enum ModalType {
    FullMigration = 'full_migration',
    SeamlessInitialRegistration = 'seamless:initial_registration',
    SeamlessRegistration = 'seamless:after_registration',
    SeamlessCollaboration = 'seamless:by_collaboration_need',
    SeamlessProjectPartner = 'seamless:by_project_partner_need',
    HeadlessSeamlessMigration = 'seamless:headless_migration',
    SeamlessPayment = 'seamless:by_payment_need',
    SeamlessHourly = 'seamless:by_hourly_need',
    SeamlessLoyalty = 'seamless:by_loyalty_need',
    SeamlessMoneyBack = 'seamless:by_money_back_need',
    SeamlessUpgradeToPro = 'seamless:upgrade_to_pro',
    AcquisitionFlowsSeamlessRegistration = 'acquisition_flows:seamless_registration',
}

export enum ViewType {
    Default = 'default',
    LowDetailsProfile = 'low_details_profile',
    AcquisitionFlowSeamlessModal = 'acquisition_flow_seamless_modal',
    AcquisitionFlowSeamlessModalSubscription = 'acquisition_flow_seamless_modal_subscription',
}
