import React, { forwardRef } from 'react';
import Layout from '../Layout';
import { DividerProps } from './types';
import { getDotStylingProps, getLineStylingProps } from './utils';

const Divider = forwardRef<HTMLElement, DividerProps>(
  (
    {
      direction = { default: 'horizontal' },
      borderStyle = 'solid',
      borderWidth = 'sm',
      borderColor = 'border',
      variant = 'line',
      width,
      height,
      dataTestId,
      ...restProps
    }: DividerProps,
    ref
  ) => {
    const stylingProps =
      variant === 'dot'
        ? getDotStylingProps({ height, width, borderColor })
        : getLineStylingProps({ direction, borderWidth, borderStyle, borderColor, height, width });

    return <Layout ref={ref} data-testid={dataTestId} {...restProps} {...stylingProps} />;
  }
);

Divider.displayName = 'Divider';

export default Divider;
