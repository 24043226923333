import { camelCase, snakeCase } from 'lodash';
import { stats } from '@fiverr-private/obs';
import { ModalType } from '../components/MigrationModalManager/types';
import { GRAFANA_APP } from './constants';

interface CountParams {
    type: ModalType;
    source: string;
}

export const countEvent = ({ metric, type, source }: CountParams & { metric: string }) => {
    stats.count(`${GRAFANA_APP}.${metric}.${camelCase(type)}`, snakeCase(source || 'source_undefined'));
};

export const countEventTrigger = ({ metric, type, source }: CountParams & { metric: string }) => {
    countEvent({ metric: `trigger.${metric}`, type, source });
};

export const countEventCall = ({ type, source }: CountParams) => {
    countEventTrigger({ metric: 'call', type, source });
};

export const countEventDispatch = ({ type, source }: CountParams) => {
    countEventTrigger({ metric: 'dispatched', type, source });
};

export const countEventShowModal = ({ type, source }: CountParams) => {
    countEventTrigger({ metric: 'shown', type, source });
};

export const countEventNotMounted = ({ type, source }: CountParams) => {
    countEventTrigger({ metric: 'not_mounted', type, source });
};

export const countEventUserMigrated = ({ success, type, source }: CountParams & { success: boolean }) => {
    countEvent({ metric: `user_migrated.${success ? 'success' : 'error'}`, type, source });
};

export const countEventUserInvited = ({ success, type, source }: CountParams & { success: boolean }) => {
    countEvent({ metric: `user_invited.${success ? 'success' : 'error'}`, type, source });
};

export const countEventError = ({ type, source }: CountParams) => {
    countEvent({ metric: 'error', type, source });
};
