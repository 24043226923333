import { useState } from 'react';
import { logger } from '@fiverr-private/obs';
import { ExpertsSourcingDrawerProps } from '.';

export const OPEN_DRAWER_EVENT_NAME = 'openExpertsSourcingDrawer';
export const WINDOW_INDICATION = 'isExpertsSourcingDrawerLoaded';

export const isExpertsSourcingDrawerMounted = () => !!window[WINDOW_INDICATION];

export const setIsExpertsSourcingDrawerMounted = (isOpen: boolean) => {
    window[WINDOW_INDICATION] = isOpen;
};

export interface LazyDrawerProps extends Omit<ExpertsSourcingDrawerProps, 'setIsOpen'> {
    source: string;
    setIsLoading?: (isOpen: boolean) => void;
}

export const openExpertsSourcingDrawerInternal = ({ currency, source, setIsLoading, onClose }: LazyDrawerProps) => {
    if (!isExpertsSourcingDrawerMounted()) {
        logger.error('Experts sourcing drawer is not mounted');
        return false;
    }

    const event = new CustomEvent<LazyDrawerProps>(OPEN_DRAWER_EVENT_NAME, {
        detail: {
            source,
            currency,
            isOpen: true,
            setIsLoading,
            onClose,
        },
    });

    window.dispatchEvent(event);
    return true;
};

export const useOpenExpertsSourcingDrawer = () => {
    const [isLoading, setIsLoading] = useState(false);

    const openExpertsSourcingDrawer = ({ currency, source, onClose }: LazyDrawerProps) => {
        openExpertsSourcingDrawerInternal({ currency, source, setIsLoading, onClose });
    };

    return { openExpertsSourcingDrawer, isLoading };
};
