import * as actions from '../../actions';

const originalTerm = (state = '', action) => {
    switch (action.type) {
        case actions.SET_ORIGINAL_TERM:
            return action.payload.originalTerm;
        default:
            return state;
    }
};

export default originalTerm;
