import { useEffect, useState } from 'react';
import { clientAllocate, EXPERIMENT_TYPE } from '@fiverr-private/experiments';
import { EXPERIMENTS, VARIANTS } from '../../../../utils/experiments';

export const useDolphinsFibLogoutMenusExperimentAllocation = ({ inRollout }) => {
    const [group, setGroup] = useState<number | undefined>();
    const inControlGroup = inRollout ? group === VARIANTS.A : true;
    const inGroupB = inRollout && group === VARIANTS.B;
    const inGroupC = inRollout && group === VARIANTS.C;

    useEffect(() => {
        const allocatedGroup = clientAllocate({
            experimentId: EXPERIMENTS.DOLPHINS_DOLPHINS_FIB_LOGOUT_PRO_MENUS_ID,
            experimentType: EXPERIMENT_TYPE.GUEST,
            rolloutResult: inRollout,
            numOfGroups: 3,
        });
        setGroup(allocatedGroup);
    }, [inRollout]);

    return { inControlGroup, inGroupB, inGroupC };
};
