import React from 'react';
import { ParentPropsOverrideContext } from './parentPropsOverrideContext';

export const renderBuilderChildren = (child: any, props = {}) => {
  const mergedProps = {
    ...(child?.props?.block?.component?.options || {}),
    ...props,
  };

  return <ParentPropsOverrideContext.Provider value={mergedProps}>{child}</ParentPropsOverrideContext.Provider>;
};
