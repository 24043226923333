import React from 'react';
import { L2_ICON_SIZES } from '../../constants';
import type { L2IconType } from '../../types';

const ProServicesL2Icon = ({ size = 'sm' }: L2IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={L2_ICON_SIZES[size]}
    height={L2_ICON_SIZES[size]}
    fill="none"
    viewBox="0 0 48 48"
  >
    <g clipPath="url(#pro_services_svg__a)">
      <g filter="url(#pro_services_svg__b)">
        <path fill="#fff" d="M43 40.25h-4v-32h4a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2" />
      </g>
      <g filter="url(#pro_services_svg__c)">
        <path
          fill="#E4E5E7"
          d="M39 40.5h4a2.25 2.25 0 0 0 2.25-2.25v-28A2.25 2.25 0 0 0 43 8h-4v.5h4c.967 0 1.75.784 1.75 1.75v28A1.75 1.75 0 0 1 43 40h-4z"
        />
      </g>
      <path fill="#C5C6C9" d="M39 38.25h4v-28h-4z" />
      <g filter="url(#pro_services_svg__d)">
        <path fill="#fff" d="M5.25 40.25h4v-32h-4a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2" />
      </g>
      <g filter="url(#pro_services_svg__e)">
        <path
          fill="#E4E5E7"
          d="M9.25 40.5h-4A2.25 2.25 0 0 1 3 38.25v-28A2.25 2.25 0 0 1 5.25 8h4v.5h-4a1.75 1.75 0 0 0-1.75 1.75v28c0 .967.784 1.75 1.75 1.75h4z"
        />
      </g>
      <path fill="#C5C6C9" d="M9.5 38.25h-4v-28h4z" />
      <g filter="url(#pro_services_svg__f)">
        <path fill="#fff" d="M37.25 4.25a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2h-26a2 2 0 0 1-2-2v-36a2 2 0 0 1 2-2z" />
      </g>
      <g filter="url(#pro_services_svg__g)">
        <path
          fill="#E4E5E7"
          fillRule="evenodd"
          d="M37.25 4a2.25 2.25 0 0 1 2.25 2.25v36a2.25 2.25 0 0 1-2.25 2.25h-26A2.25 2.25 0 0 1 9 42.25v-36A2.25 2.25 0 0 1 11.25 4zM39 6.25a1.75 1.75 0 0 0-1.75-1.75h-26A1.75 1.75 0 0 0 9.5 6.25v36c0 .967.784 1.75 1.75 1.75h26A1.75 1.75 0 0 0 39 42.25z"
          clipRule="evenodd"
        />
      </g>
      <path
        fill="#222325"
        d="M32.566 35.7a.198.198 0 0 1 .368 0l.717 1.755c.028.07.095.118.17.123l1.923.122c.18.012.252.238.112.352l-1.474 1.197a.2.2 0 0 0-.067.204l.474 1.819a.198.198 0 0 1-.296.219l-1.639-1.01a.2.2 0 0 0-.208 0l-1.639 1.01a.198.198 0 0 1-.296-.22l.474-1.818a.2.2 0 0 0-.067-.204l-1.474-1.197a.198.198 0 0 1 .112-.352l1.923-.122a.2.2 0 0 0 .17-.123z"
      />
      <path fill="#C5C6C9" d="m12.25 36.256 15-.006v1.494l-15 .006zM12.25 39.75h13.5v1.5h-13.5z" />
      <path fill="url(#pro_services_svg__h)" d="M12.25 7.25h24v26h-24z" />
      <path
        fill="#fff"
        d="m31.06 24.354-2.727-2.726a.66.66 0 0 0-.465-.191h-.445a5.687 5.687 0 0 0-4.485-9.187 5.687 5.687 0 1 0 0 11.374 5.66 5.66 0 0 0 3.5-1.203v.445c0 .175.069.342.192.465l2.726 2.726a.653.653 0 0 0 .927 0l.774-.773a.66.66 0 0 0 .003-.93m-8.122-2.917a3.5 3.5 0 0 1-3.5-3.5c0-1.933 1.564-3.5 3.5-3.5 1.933 0 3.5 1.564 3.5 3.5 0 1.933-1.564 3.5-3.5 3.5"
      />
    </g>
    <defs>
      <filter
        id="pro_services_svg__b"
        width={12}
        height={38}
        x={36}
        y={5.25}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_45326_72164" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_45326_72164" result="shape" />
      </filter>
      <filter
        id="pro_services_svg__c"
        width={12.25}
        height={38.5}
        x={36}
        y={5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_45326_72164" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_45326_72164" result="shape" />
      </filter>
      <filter
        id="pro_services_svg__d"
        width={12}
        height={38}
        x={0.25}
        y={5.25}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_45326_72164" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_45326_72164" result="shape" />
      </filter>
      <filter
        id="pro_services_svg__e"
        width={12.25}
        height={38.5}
        x={0}
        y={5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_45326_72164" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_45326_72164" result="shape" />
      </filter>
      <filter
        id="pro_services_svg__f"
        width={36}
        height={46}
        x={6.25}
        y={1.25}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_45326_72164" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_45326_72164" result="shape" />
      </filter>
      <filter
        id="pro_services_svg__g"
        width={36.5}
        height={46.5}
        x={6}
        y={1}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_45326_72164" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_45326_72164" result="shape" />
      </filter>
      <linearGradient id="pro_services_svg__h" x1={24.5} x2={22.47} y1={4.5} y2={64.431} gradientUnits="userSpaceOnUse">
        <stop stopColor="#256020" />
        <stop offset={1} stopColor="#D0E500" />
      </linearGradient>
      <clipPath id="pro_services_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
ProServicesL2Icon.displayName = 'ProServicesL2Icon';
export default ProServicesL2Icon;
