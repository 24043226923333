import React, { forwardRef } from 'react';
import Layout from '../Layout';
import { GridProps } from './types';

const Grid = forwardRef<HTMLElement, GridProps>(({ children, ...restProps }: GridProps, ref) => (
  <Layout ref={ref} display={{ default: 'grid' }} {...restProps}>
    {children}
  </Layout>
));

Grid.displayName = 'Grid';

export default Grid;
