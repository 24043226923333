import React, { ReactElement } from 'react';

interface ComponentType {
    type: { id: string };
}

const isObject = (child: any) => typeof child === 'object';

export const isBuilderChildren = (child: any) =>
    isObject(child) && child?.props?.block && child.props.block['@type'] === '@builder.io/sdk:Element';

export const childrenIdMatch = (child: React.ReactChild | React.ReactFragment | React.ReactPortal, id: string) => {
    if (isBuilderChildren(child)) {
        return (child as ReactElement).props.block?.component?.name?.endsWith(`:${id}`);
    }

    return isObject(child) && (child as ComponentType).type.id === id;
};
