import React, { forwardRef } from 'react';
import BaseButton from '../BaseButton';
import type { IconButtonProps } from './types';
import { BORDER_RADIUS_SIZES, ICON_BUTTON_SIZES } from './constants';

const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
  (
    { intent = 'secondary', variant = 'filled', size = 'md', loading = false, shape = 'square', children, ...rest },
    ref
  ) => (
    <BaseButton
      ref={ref}
      shape={shape}
      loading={loading}
      intent={intent}
      variant={variant}
      borderRadius={shape === 'square' ? BORDER_RADIUS_SIZES[size] : undefined}
      {...ICON_BUTTON_SIZES[size]}
      {...rest}
    >
      {children}
    </BaseButton>
  )
);

IconButton.displayName = 'IconButton';

export default IconButton;
