import { snakeCase } from 'lodash';

/**
 * Convert object with camelCase keys, to snake_case keys
 * e.g. { myKey: 1 } will return { my_key: 1 }
 * @param camelCaseObject
 */
export const toSnakeCase = (camelCaseObject: Record<string, unknown> = {}): Record<string, unknown> => {
    const snakeCaseObject = {};

    Object.entries(camelCaseObject).forEach(([key, value]) => {
        const newKey = snakeCase(key);
        snakeCaseObject[newKey] = value;
    });

    return snakeCaseObject;
};

/**
 * Checks if the code is running on server.
 */
export const isServer = () => typeof window === 'undefined' || typeof window?.document === 'undefined';
