import md5 from 'md5';
import type { BigIntegerStatic } from 'big-integer';
import { logger, stats } from '@fiverr-private/obs';
import { ERROR_MSG, MIN_NUMBER_OF_GROUPS, LOGGER_ENRICHMENT, CalculateGroupParams, UserInputError } from './helpers';
import { getParticipantIdentifier } from './identifiers';

/**
 * Calculates the group (variant) for the given participant/experiment combination.
 *
 * @param participantId
 * @param experimentId
 * @param numOfGroups Number of groups in the test: integer, at least 2.
 *
 * @returns Assigned group number for the given participant in the given experiment: integer between 1 and numOfGroups (inclusive).
 */
export const groupHash = (participantId: string | number, experimentId: number, numOfGroups: number): number => {
    if (numOfGroups < MIN_NUMBER_OF_GROUPS) {
        throw new UserInputError(ERROR_MSG.ALLOC_GROUPS);
    }
    const hash = md5(`${participantId}:${experimentId}`) as string;
    const bigIntNativeHashValue = getBigIntNativeHash(hash);

    if (bigIntNativeHashValue !== null) {
        stats.count('services.experiments_package', 'bigint_polyfill_not_used');
        return Number(bigIntNativeHashValue % BigInt(numOfGroups)) + 1;
    }

    stats.count('services.experiments_package', 'bigint_polyfill_used');
    return getGroupWithPolyfill(hash, numOfGroups);
};

/**
 *
 * @param participantId
 * @param experimentId
 * @returns Either a bigint or null. If the result is null it means native BigInt is not supported.
 */
const getBigIntNativeHash = (hash: string): bigint | null => {
    try {
        return BigInt(`0x${hash}`);
    } catch (error) {
        return null;
    }
};

const getGroupWithPolyfill = (hash: string, numOfGroups: number): number => {
    const parsedHashOutput = (BigInt as unknown as BigIntegerStatic)(hash, 16);
    const groupToAllocate = parsedHashOutput.mod(numOfGroups);

    return Number(groupToAllocate) + 1;
};

/**
 * Calculates the group (variant) for the given experiment, using guest/user identifiers from supplied context argument.
 * Return previous allocation if it exists.
 *
 * @param - params
 * @param params.experimentId - The experiment identifier.
 * @param params.experimentType - The type of experiment. Either 'user' or 'guest'.
 * @param params.numOfGroups - The total number of groups in the experiment (must be >= 2).
 * @param params.context - Either Perseus `RequestContext` or `FiverrContext` (must contain `userGuid` and `userId` keys and optional abTests)
 *
 * @return group on success, else undefined.
 */
export const calculateGroup = ({
    experimentId,
    experimentType,
    numOfGroups,
    context,
}: CalculateGroupParams): number | undefined => {
    try {
        const { userId, userGuid, abTests } = context;

        const prevAllocation = Number(abTests?.[experimentId]);
        if (prevAllocation) {
            return prevAllocation;
        }

        const identifier = getParticipantIdentifier({ experimentType, userGuid, userId });

        return groupHash(identifier, experimentId, numOfGroups);
    } catch (error) {
        logger.error(error as Error, { experimentId, experimentType, numOfGroups, ...LOGGER_ENRICHMENT });

        return undefined;
    }
};
