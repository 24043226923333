import { isEmpty } from 'lodash';
import {
    loadPromotedGigsByAutocompleteQueries,
    SET_SEARCH,
    SET_SUGGESTIONS,
    setPromotedGigsSuggestions,
} from '../../actions';
import PromotedGigsService from '../../services/PromotedGigs';
import * as SuggestionsSelector from '../../selectors/Suggestions';
import { EMPTY_PROMOTED_GIGS_SUGGESTIONS } from '../../apis/PromotedGigsApi/constants';

const PromotedGigs = (store) => {
    const promotedService = new PromotedGigsService();

    return (next) => (action) => {
        const { type, payload } = action;
        const {
            rollouts: { inPromotedGigsSearchBarRollout },
            mode,
        } = store.getState();
        const { currentDLC } = SuggestionsSelector.promotedGigsSubCategoryConfig(store.getState());

        if (type === SET_SEARCH && payload?.search === '') {
            store.dispatch(setPromotedGigsSuggestions(EMPTY_PROMOTED_GIGS_SUGGESTIONS));
        }

        if (type === SET_SUGGESTIONS) {
            if (
                inPromotedGigsSearchBarRollout &&
                promotedService.showPromotedGigsSuggestions(mode) &&
                !isEmpty(payload?.suggestions)
            ) {
                const promotedGigsSuggestions = Object.values(payload.suggestions)

                    // We want only organic gig suggestions
                    .filter((suggestion) => suggestion.group === SuggestionsSelector.GIGS_SEARCH)
                    .sort((suggestion) => suggestion.order - suggestion.order)
                    .map((suggestion) => suggestion.value);

                if (promotedGigsSuggestions.length) {
                    store.dispatch(loadPromotedGigsByAutocompleteQueries(promotedGigsSuggestions, currentDLC));
                }
            }
        }

        next(action);
    };
};

export default PromotedGigs;
