import React from 'react';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { Domingo } from '@fiverr-private/domingo';
import { getContext } from '@fiverr-private/fiverr_context';
import { HEADER_POPUP_SOURCE } from '../../../../constants';
import { reportBigQueryClick, reportMixpanelClick } from '../../../../utils/reporter';
import { getBQData } from '../../../../utils/business/bi-utils';

export const FiverrProButton = () => {
    const { url } = getContext();
    const link = Domingo(url)
        .removePath()
        .pro()
        .path('content_pages_page', { page_slug: 'mp-vs-pro' }, { query: { source: HEADER_POPUP_SOURCE } }).url;

    const onClick = () => {
        reportBigQueryClick(getBQData({ type: 'go_to_fiverr_pro' }));
        reportMixpanelClick({
            eventName: 'Pro button on fiverr header menu - clicked',
        });
    };

    return (
        <Button onClick={onClick} href={link} target="_blank" variant="ghost">
            <I18n k="header.fiverr_pro_entry.title" />
        </Button>
    );
};
