import * as actions from '../../actions';

const selectedSuggestionId = (state = '', action) => {
    switch (action.type) {
        case actions.SELECT_SUGGESTION:
            return action.payload.id;
        default:
            return state;
    }
};

export default selectedSuggestionId;
