import { first } from 'lodash';
import { clearRecentSearches, getRecentSearches, setRecentSearches } from './utils';
import { EXPIRATION_TIME_MS, NUM_OF_RECENT_SEARCHES_IN_LOCAL_STORAGE } from './constants';

export const getLocalStorageHistory = () => {
    const recentSearches = getRecentSearches();
    const currentTimestamp = Date.now();

    const validRecentSearches = recentSearches.filter(
        ({ updatedAt }) => currentTimestamp - updatedAt < EXPIRATION_TIME_MS
    );

    setRecentSearches(validRecentSearches);

    return validRecentSearches;
};

export const appendSearchToLocalStorage = (search) => {
    const recentSearches = getLocalStorageHistory();

    const currentTimestamp = Date.now();
    let currentRecentSearch = { search, createdAt: currentTimestamp, updatedAt: currentTimestamp };

    const currentSearchIndex = recentSearches.findIndex((recentSearch) => recentSearch.search === search);

    if (currentSearchIndex !== -1) {
        currentRecentSearch = first(recentSearches.splice(currentSearchIndex, 1));
        currentRecentSearch.updatedAt = currentTimestamp;
    }

    recentSearches.unshift(currentRecentSearch);

    setRecentSearches(recentSearches.slice(0, NUM_OF_RECENT_SEARCHES_IN_LOCAL_STORAGE));
};

export const clearLocalStorageHistory = () => clearRecentSearches();
