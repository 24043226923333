import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { Fade, SlideFade, type DurationType, type Duration } from '@fiverr-private/transition';
import { Portal } from '@fiverr-private/ui_utils';
import { useClickOutside, useKeyboardShortcuts, useIsomorphicLayoutEffect } from '@fiverr-private/hooks';
import { Box, theme, type Types } from '@fiverr-private/theme';
import { PopoverContentProps } from '../types';
import { usePopoverContext } from '../PopoverContext';

const BACKWARD_COMPATIBILITY_COLORS: { [key: string]: Types.Color } = {
  [theme.colors.white]: 'white',
  [theme.colors.grey_1100]: 'grey_1100',
  [theme.colors.blue_700]: 'blue_700',
};

const PopoverContent = ({
  children,
  appendToBody = false,
  closeOnClickOutside = true,
  initialFocusRef,
  disablePadding = false,
  renderWhenClosed = false,
  disableBoxShadow = false,
  backgroundColor = 'white',
  zIndex = 'popover',
  animationType = 'fade',
  dataTestId,
  onAnimationStart,
  onAnimationEnd,
  borderRadius = 'lg',
  disableAutoFocus,
  minWidth,
  disableExitAnimation,
  withDelay = false,
}: PopoverContentProps) => {
  const {
    open,
    setOpen,
    triggerEvent,
    floatingData: { strategy, y, x, refs, isPositioned },
  } = usePopoverContext();
  const { isMobile } = getContext();

  const actualBackgroundColor = Object.keys(BACKWARD_COMPATIBILITY_COLORS).includes(backgroundColor)
    ? BACKWARD_COMPATIBILITY_COLORS[backgroundColor]
    : backgroundColor;

  const clickOutsideHandler = (e) => {
    const clickedOnAnchor = e.target === refs.reference.current;
    const clickedOnAnchorChild = (refs.reference.current as HTMLElement)?.contains(e.target as Node);
    if (clickedOnAnchor || clickedOnAnchorChild) {
      return;
    }
    setOpen(false);
  };

  useClickOutside(refs.floating, open && closeOnClickOutside ? clickOutsideHandler : undefined);
  useKeyboardShortcuts([
    {
      trigger: 'escape',
      handler: () => setOpen(false),
    },
  ]);

  useIsomorphicLayoutEffect(() => {
    if (isPositioned && refs.floating.current && !disableAutoFocus) {
      refs.floating.current.focus();
      if (initialFocusRef?.current) {
        initialFocusRef.current.focus();
      }
    }
  }, [isPositioned, refs.floating, initialFocusRef, disableAutoFocus]);

  const contentProps: Types.BoxType = {
    tabIndex: open ? -1 : undefined,
    padding: disablePadding ? '0' : '5',
    width: 'max-content',
    boxSizing: 'borderBox',
    overflowWrap: 'breakWord',
    borderRadius,
    position: strategy,
    top: y || 0,
    left: x || 0,
    minWidth: minWidth ? minWidth : undefined,
    maxWidth: isMobile ? '100%' : 500,
    backgroundColor: actualBackgroundColor,
    zIndex,
    dropShadow: disableBoxShadow ? undefined : 'z2',
    role: triggerEvent === 'click' ? 'dialog' : 'tooltip',
    'aria-hidden': !open,
    onMouseEnter:
      triggerEvent === 'hover'
        ? () => {
            setOpen(true);
          }
        : undefined,
    onMouseLeave:
      triggerEvent === 'hover'
        ? () => {
            setOpen(false);
          }
        : undefined,
  };

  const Animation = animationType === 'slideFade' ? SlideFade : Fade;
  const duration: Duration | undefined = animationType === 'slideFade' ? 'moderate2' : undefined;
  const delay: DurationType | undefined =
    triggerEvent === 'hover' ? { enter: withDelay ? 'slow3' : 'fast3', exit: 'fast3' } : undefined;
  const animationProps = {
    delay,
    duration,
    in: open,
    unmountOnExit: !renderWhenClosed,
    onAnimationStart,
    onAnimationEnd,
    reverse: animationType === 'slideFade' ? !disableExitAnimation : undefined,
  };
  if (appendToBody) {
    return (
      <Portal>
        <Animation {...animationProps}>
          <Box ref={refs.setFloating} data-testid={dataTestId} {...contentProps}>
            {children}
          </Box>
        </Animation>
      </Portal>
    );
  }
  return (
    <Animation {...animationProps}>
      <Box ref={refs.setFloating} data-testid={dataTestId} {...contentProps}>
        {children}
      </Box>
    </Animation>
  );
};

PopoverContent.displayName = 'PopoverContent';

export default PopoverContent;
