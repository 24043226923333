export const FORM_SOURCE = 'form';
export const PANEL_SOURCE = 'panel';
export const HTTP_STATUS = {
    SUCCESS: 200,
    INTERNAL_SERVER_ERROR: 500,
};
export const THEME = {
    DARK: 'dark',
    DEFAULT: 'default',
    DARK_GREEN: 'dark_green',
};
export const SUBMIT_BUTTON_TYPE = {
    ICON: 'icon',
    TEXT: 'text',
    BUTTON_INSIDE: 'button_inside',
    ROUNDED: 'rounded',
};

export const STATS_PREFIX = 'packages.search_bar_package';
