import React, { ComponentProps, ReactElement } from 'react';
import { translate, TranslateOptionsParams, TranslateOptionsWithTemplatesParams } from '../translate';
import { hasTranslation } from '../hasTranslation';
import { I18n } from '../components';

type DeepKeysOf<T, Key extends keyof T = keyof T> = Key extends string
    ? T[Key] extends Record<'one' | 'other', string>
        ? Key
        : T[Key] extends Record<string, any>
        ? `${Key}.${DeepKeysOf<T[Key]>}`
        : Key | (T[Key] extends string ? Key : never)
    : never;

export interface AppI18nProps<T extends Record<string, unknown>, K extends keyof T & string>
    extends Omit<ComponentProps<typeof I18n>, 'k'> {
    k: DeepKeysOf<T, K>;
}

export const createAppTranslation = <
    T extends Record<string, unknown>,
    K extends (keyof T & string) | undefined = undefined
>(
    appCopies: T,
    prefix?: K
) => {
    type Copies = typeof appCopies;
    type AppKey = K extends keyof Copies ? DeepKeysOf<Copies[K]> : DeepKeysOf<Copies>;

    function appTranslate<T extends string | ReactElement = string>(key: AppKey, options?: TranslateOptionsParams): T;

    function appTranslate<T extends string | ReactElement = ReactElement>(
        key: AppKey,
        options: TranslateOptionsWithTemplatesParams
    ): T;

    function appTranslate(key: AppKey, options?: TranslateOptionsParams | TranslateOptionsWithTemplatesParams) {
        const fullKey = prefix ? `${prefix}.${key}` : key;
        return translate(fullKey, options);
    }

    const AppI18n = ({ k, ...rest }: { k: AppKey } & ComponentProps<typeof I18n>) => {
        const fullKey = prefix ? `${prefix}.${k}` : k;
        return <I18n k={fullKey} {...rest} />;
    };

    const hasAppTranslation = (key: string): key is AppKey => hasTranslation(prefix ? `${prefix}.${key}` : key);

    return {
        AppI18n,
        appTranslate,
        hasAppTranslation,
    };
};
