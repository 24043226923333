import { getValueOrField } from '../../utils';
import { ENTER, EXIT, DURATION, EASING, SLIDE_FADE_OFFSETS } from '../../constants';
import type { Duration, DurationType, Easing, EasingType, SlideFadeProps, VariantsType } from '../../types';

const getEnterTiming = (duration: DurationType = 'moderate1', easing: EasingType = 'easeIn', delay?: DurationType) => ({
    duration: DURATION[getValueOrField(duration, ENTER) as Duration],
    ease: EASING[getValueOrField(easing, ENTER) as Easing],
    delay: DURATION[getValueOrField(delay, ENTER) as Duration],
});

const getExitTiming = (duration: DurationType = 'moderate1', easing: EasingType = 'easeOut', delay?: DurationType) => ({
    duration: DURATION[getValueOrField(duration, EXIT) as Duration],
    ease: EASING[getValueOrField(easing, EXIT) as Easing],
    delay: DURATION[getValueOrField(delay, EXIT) as Duration],
});

const variants: VariantsType<SlideFadeProps> = {
    enter: ({ delay, duration, easing }) => ({
        opacity: [1],
        x: [0],
        y: [0],
        timing: getEnterTiming(duration, easing, delay),
    }),
    exit: ({ offsetX = 'none', offsetY = 'none', delay, duration, easing }) => ({
        opacity: [0],
        x: [SLIDE_FADE_OFFSETS[offsetX]],
        y: [SLIDE_FADE_OFFSETS[offsetY]],
        timing: getExitTiming(duration, easing, delay),
    }),
};

export default variants;
