// The supported keyboard shortcuts triggers
export const EVENTS: Record<string, keyof HTMLElementEventMap> = {
    KEYDOWN: 'keydown',
    KEYUP: 'keyup',
};

// The target tag names to ignore upon event checking
export const BLACKLISTED_TARGETS = ['input', 'textarea'];

export const ANY_KEY = '*';
