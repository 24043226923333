import * as actions from '../../actions';

const search = (state = '', action) => {
    switch (action.type) {
        case actions.SET_SEARCH:
            return action.payload.search;
        default:
            return state;
    }
};

export default search;
