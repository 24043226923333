import { useCallback, useEffect, useMemo } from 'react';
import { throttle } from 'lodash';
import { usePopoverContext } from '@fiverr-private/popover';
import useDropdownContext from '../../Dropdown/useDropdownContext';
import { MenuItem } from '../../Dropdown/types';
import { NAVIGATION_THROTTLE_DELAY } from './constants';
import { UseMenuNavigationProps } from './types';
import { getItemToFocus } from './utils';

const useMenuNavigation = ({ items, onEnter, onTab }: UseMenuNavigationProps) => {
    const { open } = usePopoverContext();
    const { setFocusedItem, focusedItem, anchorRef, keyboardNavigationLoop, searchOnKeyPress, headerActionsRef } =
        useDropdownContext();
    const getMenuItemIndex = useCallback(
        (toFind: MenuItem['ref']) => items.findIndex(({ ref }) => ref === toFind),
        [items]
    );

    const moveUpFocus = useCallback(() => {
        const index = getMenuItemIndex(focusedItem);
        const startIndex = index === 0 && keyboardNavigationLoop ? items.length - 1 : index;

        for (let i = startIndex; i >= 0; i--) {
            const item = items[i];

            if (!item.disabled && i !== index) {
                setFocusedItem(item.ref);
                break;
            }
        }
    }, [keyboardNavigationLoop, focusedItem, getMenuItemIndex, items, setFocusedItem]);

    const moveDownFocus = useCallback(() => {
        const index = getMenuItemIndex(focusedItem);
        const startIndex = index === items.length - 1 && keyboardNavigationLoop ? 0 : index + 1;

        for (let i = startIndex; i <= items.length - 1; i++) {
            const item = items[i];

            if (!item.disabled) {
                setFocusedItem(getItemToFocus({ startIndex, items }));
                break;
            }
        }
    }, [keyboardNavigationLoop, focusedItem, getMenuItemIndex, items, setFocusedItem]);

    const moveUpFocusThrottled = useMemo(() => throttle(moveUpFocus, NAVIGATION_THROTTLE_DELAY), [moveUpFocus]);
    const moveDownFocusThrottled = useMemo(() => throttle(moveDownFocus, NAVIGATION_THROTTLE_DELAY), [moveDownFocus]);

    const selectItem = useCallback(
        (event: KeyboardEvent) => {
            event.preventDefault();
            const index = getMenuItemIndex(focusedItem);

            if (index > -1) {
                const item = items[index];
                item.ref.current?.click?.();
            }
        },
        [getMenuItemIndex, focusedItem, items]
    );

    const searchItem = useCallback(
        (event: KeyboardEvent) => {
            const { key } = event;
            const focusedItemIndex = getMenuItemIndex(focusedItem);
            const listStartIndex = focusedItemIndex === -1 ? 0 : focusedItemIndex;
            const list = [...items.slice(listStartIndex), ...items.slice(0, listStartIndex)];

            for (const item of list) {
                const itemElement = item.ref.current;
                const isFocused = itemElement === focusedItem?.current;
                const firstLetter = itemElement?.textContent?.[0].toLocaleLowerCase();

                if (firstLetter === key.toLowerCase() && !isFocused && !item.disabled) {
                    setFocusedItem(item.ref);
                    break;
                }
            }
        },
        [focusedItem, getMenuItemIndex, items, setFocusedItem]
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            const { code, shiftKey } = event;

            switch (code) {
                case 'Tab': {
                    event.preventDefault();
                    if (shiftKey) {
                        const firstHeaderAction = headerActionsRef?.current?.children.item(0) as HTMLElement;
                        firstHeaderAction?.focus();
                        setFocusedItem({ current: null });
                    } else {
                        onTab?.();
                    }
                    break;
                }

                case 'ArrowUp': {
                    event.preventDefault();
                    moveUpFocusThrottled();

                    break;
                }

                case 'ArrowDown': {
                    event.preventDefault();
                    moveDownFocusThrottled();

                    break;
                }

                case 'Enter': {
                    selectItem(event);
                    onEnter?.();

                    break;
                }

                default: {
                    if (searchOnKeyPress) {
                        searchItem(event);
                    }
                    break;
                }
            }
        },
        [
            onTab,
            moveUpFocusThrottled,
            moveDownFocusThrottled,
            selectItem,
            onEnter,
            searchOnKeyPress,
            searchItem,
            headerActionsRef,
            setFocusedItem,
        ]
    );

    useEffect(() => {
        const currentRef = anchorRef.current;
        if (open && currentRef) {
            currentRef.addEventListener('keydown', handleKeyDown);
        } else {
            currentRef?.removeEventListener('keydown', handleKeyDown);
        }

        return () => currentRef?.removeEventListener('keydown', handleKeyDown);
    }, [anchorRef, handleKeyDown, open]);
};

export default useMenuNavigation;
