import React from 'react';
import { Grid, Stack, Divider, Center } from '@fiverr-private/layout_components';
import { getProMenusCopiesConfig } from '@fiverr-private/layout_menus';
import { Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import {
    ProSellerL2Icon,
    ProServicesL2Icon,
    RewardsProgramL2Icon,
    SatisfactionGuaranteeL2Icon,
    TeamCollaborationL2Icon,
} from '@fiverr-private/visuals';
import { buildLinkHref } from '../../../../utils/buildLinkHref';
import Link from '../../../Links/Link';
import { getBQData } from '../../../../utils/business/bi-utils';

const mpEventData = (menuItemName: string) => ({
    eventName: 'Pro button on fiverr header menu - clicked',
    eventData: {
        'Menu item': menuItemName,
    },
});

const LinkItem = ({ item }: any) => {
    const itemKeyToProp = {
        satisfaction: {
            icon: <SatisfactionGuaranteeL2Icon size="md" />,
            bq: getBQData({ type: 'satifaction_guarantee' }),
            mp: mpEventData('Satisfaction guarantee'),
        },
        offer_services: {
            icon: <ProSellerL2Icon size="md" />,
            bq: getBQData({ type: 'offer_pro_services' }),
            mp: mpEventData('Offer pro services'),
        },
        collaboration: {
            icon: <TeamCollaborationL2Icon size="md" />,
            bq: getBQData({ type: 'collaboration_and_payments' }),
            mp: mpEventData('Collaborations and payments'),
        },
        sourcing: {
            icon: <ProServicesL2Icon size="md" />,
            bq: getBQData({ type: 'sourcing_services' }),
            mp: mpEventData('Sourcing services'),
        },
        rewards: {
            icon: <RewardsProgramL2Icon size="md" />,
            bq: getBQData({ type: 'rewards_program' }),
            mp: mpEventData('Rewards program'),
        },
    };

    const link = buildLinkHref({ link: item.link, source: 'HEADER_POPUP_SOURCE' });

    return (
        <Link mpData={itemKeyToProp[item.key].mp} bigQueryData={itemKeyToProp[item.key].bq} target="_blank" href={link}>
            <Stack gap="6" paddingY="3">
                <Center flexShrink="0">{itemKeyToProp[item.key].icon}</Center>
                <Stack direction="column" gap="0">
                    <Text size="b_sm" fontWeight="bold">
                        {item.text}
                    </Text>
                    <Text size="b_sm">{item.description}</Text>
                </Stack>
            </Stack>
        </Link>
    );
};

export const ExtendedMenu = () => {
    const { pro_dropdown } = getProMenusCopiesConfig();

    const proServicesItem = pro_dropdown.items.find((el) => el.key === 'offer_services');

    return (
        <Stack direction="column" gap="0" paddingTop="4">
            <Text paddingX="8" fontWeight="bold">
                <I18n k="header.pro_extended_dropdown.hire_title" />
            </Text>
            <Grid templateColumns={{ default: '100%' }} gap="4" rowGap="2" paddingX="8" paddingTop="4">
                {pro_dropdown.items
                    .filter((el) => el.key !== 'offer_services')
                    .map((item) => (
                        <LinkItem key={item.key} item={item} />
                    ))}
            </Grid>
            <Divider marginY="6" />
            {proServicesItem && (
                <Stack direction="column" gap="1" paddingX="8" paddingBottom="4">
                    <Text fontWeight="bold">
                        <I18n k="header.pro_extended_dropdown.find_work_title" />
                    </Text>
                    <LinkItem item={proServicesItem} />
                </Stack>
            )}
        </Stack>
    );
};
