import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { events } from '@fiverr-private/gtm';
import { useClickOutside } from '@fiverr-private/hooks';
import { translate } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { formDataIterable } from '@fiverr-private/futile';
import { SearchIcon } from '@fiverr-private/visuals';
import { Container } from '@fiverr-private/layout_components';
import SubmitButton from '../SubmitButton';
import {
    setSearch,
    setOriginalTerm,
    submitSearch,
    selectSuggestion,
    selectPreviousSuggestion,
    selectNextSuggestion,
    DIRECTIONS,
    setDirection,
    getInitialState,
} from '../../actions';
import { FORM_SOURCE, SUBMIT_BUTTON_TYPE, THEME } from '../../utils/constants';
import { displaySearch, displaySearchCategory, searchPlaceholder } from '../../selectors/Search';
import { initDimBackgroundUtil, isTheme } from '../utils';
import { RoutingLayout, RoutingSearchBarContext } from '../RoutingLayout';
import { routingSearchFormClass } from './styles.ve.css';

import './style.scss';

const Input = ({ search, placeholder, className, handleChange, handleFocus, handleBlur, handleKeyDown }) => (
    <input
        className={className}
        type="search"
        autoComplete="off"
        placeholder={placeholder}
        value={search}
        onInput={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
    />
);

const SearchForm = ({
    search,
    searchCategory,
    searchPlaceholder,
    placeholder,
    onSubmit,
    onChange,
    onFocus,
    onKeyDown,
    onKeyDownArrowUp,
    onKeyDownArrowDown,
    children,
    formToQueryParams,
    onKeyDownEnter,
    submitOnEnter,
    inSearchBarAnimationExp,
    onEscapeKeyDown,
    close,
    theme,
    history,
    popular,
    onFetchInitialState,
    dimBackgroundEnabled,
    submitButtonType,
    containerRef,
    isSearchOnly,
    formInputRef,
    isRoutingSearchBarOpen,
    setIsRoutingSearchBarOpen,
    showRoutingSearchBarAnimation,
}) => {
    const { locale, isTouch, userId, experience } = getContext();
    const dimBackgroundUtil = useRef(null);
    const formRef = useRef(null);
    const LONG_SEARCH_PLACEHOLDER = 'search_bar.placeholder_long';
    const showSearchIcon = submitButtonType === SUBMIT_BUTTON_TYPE.TEXT;
    const isSearchButtonInsideInput = submitButtonType === SUBMIT_BUTTON_TYPE.BUTTON_INSIDE;
    const isRounded = submitButtonType === SUBMIT_BUTTON_TYPE.ROUNDED;
    const [disableSubmit, setDisableSubmit] = useState(true);

    useClickOutside(containerRef, () => {
        dimBackgroundUtil.current?.hideDimBackground();
    });

    useEffect(() => {
        const { activeElement } = document;
        const { current: form } = formRef;

        if (!activeElement || !form) {
            return;
        }

        if (form.contains(activeElement)) {
            handleFocus({ target: activeElement });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dimBackgroundUtil.current = initDimBackgroundUtil(dimBackgroundEnabled);

        return () => dimBackgroundUtil.current?.hideDimBackground();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchInitialStateDebounced = debounce(onFetchInitialState, 3000, { leading: true, trailing: false });

    const handleInitialStateRequest = () => {
        if (history && popular) {
            return;
        }

        if (userId) {
            fetchInitialStateDebounced();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let queryParams = formToQueryParams(e.target);
        if (searchCategory) {
            queryParams = { ...queryParams, sub_category: searchCategory };
        }
        events.searched.searched();
        onSubmit(search, queryParams);
    };

    const handleChange = (e) => {
        setDisableSubmit(e.target.value === '');
        onChange(e.target.value);
    };

    const handleFocus = (e) => {
        onFocus(e.target.value);
        dimBackgroundUtil?.current?.showDimBackground();
        handleInitialStateRequest();
        setIsRoutingSearchBarOpen(true);
    };

    const handleBlur = (event) => {
        if (!containerRef.current?.contains(event.relatedTarget)) {
            dimBackgroundUtil?.current?.hideDimBackground();
        }
    };

    const handleKeyDown = (e) => {
        onKeyDown(e);

        if (e.key === 'Enter' && !submitOnEnter) {
            e.preventDefault();
            onKeyDownEnter();
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();
            onKeyDownArrowUp();
        }

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            onKeyDownArrowDown();
        }

        if (e.key === 'Escape') {
            e.preventDefault();
            onEscapeKeyDown(e, close);
        }
    };

    const handleMouseOver = () => {
        handleInitialStateRequest();
    };
    const getFormClassName = () =>
        isRounded
            ? classNames(routingSearchFormClass, 'routing-search-form', {
                  'search-only': isSearchOnly,
                  shadow: !isSearchOnly,
              })
            : classNames('search-form', {
                  'search-icon-shown': showSearchIcon,
                  dark: isTheme(theme, THEME.DARK),
                  'button-inside': isSearchButtonInsideInput,
              });
    const inputProps = {
        search,
        handleChange,
        handleFocus,
        handleBlur,
        handleKeyDown,
    };

    const shortPlaceholder = placeholder || translate(searchPlaceholder, { locale });
    const longPlaceholder = placeholder || translate(LONG_SEARCH_PLACEHOLDER, { locale }) || shortPlaceholder;

    const isBusinessPro = experience?.isBusiness;

    return (
        <form onSubmit={handleSubmit} onMouseOver={handleMouseOver} className={getFormClassName()} ref={formRef}>
            {showSearchIcon && (
                <div className="search-bar-icon">
                    <SearchIcon color="grey_800" />
                </div>
            )}

            <Container ref={formInputRef} display="flex" width="100%">
                {!isTouch && (
                    <Input
                        placeholder={longPlaceholder}
                        className={classNames('long-placeholder', {
                            pro: isBusinessPro,
                            'routing-search-bar': isRounded,
                        })}
                        {...inputProps}
                    />
                )}
                <Input
                    placeholder={shortPlaceholder}
                    className={classNames({
                        'short-placeholder': !isTouch,
                        pro: isBusinessPro,
                        'routing-search-bar': isRounded,
                    })}
                    {...inputProps}
                />
            </Container>

            {inSearchBarAnimationExp && <div className="focused-animation" />}

            {!React.Children.count(children) &&
                (isRounded ? (
                    <RoutingSearchBarContext.Provider
                        value={{
                            isSearchOnly,
                            isRoutingSearchBarOpen,
                            showRoutingSearchBarAnimation,
                            disabled: disableSubmit,
                        }}
                    >
                        <RoutingLayout />
                    </RoutingSearchBarContext.Provider>
                ) : (
                    <SubmitButton
                        className={classNames({ pro: isBusinessPro })}
                        theme={theme}
                        type={submitButtonType}
                    />
                ))}
            {children}
        </form>
    );
};

SearchForm.propTypes = {
    search: PropTypes.string,
    searchCategory: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    placeholder: PropTypes.string,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onEscapeKeyDown: PropTypes.func,
    onKeyDownArrowDown: PropTypes.func,
    onKeyDownArrowUp: PropTypes.func,
    onFetchInitialState: PropTypes.func,
    close: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
    formToQueryParams: PropTypes.func,
    onKeyDownEnter: PropTypes.func,
    submitOnEnter: PropTypes.bool,
    inSearchBarAnimationExp: PropTypes.bool,
    theme: PropTypes.string,
    history: PropTypes.object,
    popular: PropTypes.object,
    rollouts: PropTypes.object,
    dimBackgroundEnabled: PropTypes.bool,
    submitButtonType: PropTypes.string,
    containerRef: PropTypes.object,
    isSearchOnly: PropTypes.bool,
    formInputRef: PropTypes.shape({ current: PropTypes.any }),
    isRoutingSearchBarOpen: PropTypes.bool,
    setIsRoutingSearchBarOpen: PropTypes.func,
    showRoutingSearchBarAnimation: PropTypes.bool,
};

Input.propTypes = {
    search: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    handleChange: PropTypes.func,
    handleFocus: PropTypes.func,
    handleBlur: PropTypes.func,
    handleKeyDown: PropTypes.func,
};

SearchForm.defaultProps = {
    theme: 'default',
    search: '',
    searchCategory: '',
    searchPlaceholder: 'search_bar.placeholder',
    onSubmit: () => true,
    onChange: () => true,
    onFocus: () => true,
    onKeyDown: () => true,
    onKeyDownArrowDown: () => true,
    onKeyDownArrowUp: () => true,
    onKeyDownEnter: () => true,
    onFetchInitialState: () => true,
    formToQueryParams: (form) => {
        const queryParams = {};

        formDataIterable(form).forEach((formItemArr) => {
            const key = formItemArr[0],
                value = formItemArr[1];

            if (key && value) {
                queryParams[key] = value;
            }
        });

        return queryParams;
    },
    submitOnEnter: true,
    inSearchBarAnimationExp: false,
    rollouts: {},
    dimBackgroundEnabled: false,
    isSearchOnly: false,
};

export { SearchForm };

const mapStateToProps = (state, { formToQueryParams }) => ({
    search: displaySearch(state),
    searchCategory: displaySearchCategory(state),
    searchPlaceholder: searchPlaceholder(state),
    placeholder: state.placeholder,
    formToQueryParams,
    history: state.history,
    popular: state.popular,
    rollouts: state.rollouts,
});

const mapDispatchToProps = (dispatch, { onFocus, onSubmit, onKeyDown }) => ({
    onChange: (search) => {
        dispatch(selectSuggestion(''));
        dispatch(setSearch(search));
        dispatch(setOriginalTerm(search));
    },
    onFocus: (search) => {
        onFocus(search);
        dispatch(selectSuggestion(''));
        dispatch(setSearch(search));
    },
    onSubmit: (search, queryParams) => {
        onSubmit();
        dispatch(setSearch(search));
        dispatch(submitSearch({ queryParams, submitSource: FORM_SOURCE }));
    },
    onKeyDown: (e) => {
        onKeyDown(e);
    },
    onKeyDownArrowDown: () => {
        dispatch(selectNextSuggestion());
        dispatch(setDirection(DIRECTIONS.NEXT));
    },
    onKeyDownArrowUp: () => {
        dispatch(selectPreviousSuggestion());
        dispatch(setDirection(DIRECTIONS.BACK));
    },
    onEscapeKeyDown: (e, close) => {
        e.target.blur();
        close();
    },
    onKeyDownEnter: () => {
        onSubmit();
    },
    onFetchInitialState: () => dispatch(getInitialState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
