import type { BaseButtonProps } from '../BaseButton/types';
import type { SizeType } from './types';

export const SHAPES = {
    circle: 'circle',
    square: 'square',
} as const;

export const ICON_BUTTON_SIZES: {
    [key in SizeType]: {
        height: BaseButtonProps['height'];
        width: BaseButtonProps['width'];
    };
} = {
    '3xs': {
        height: 24,
        width: 24,
    },
    '2xs': {
        height: 28,
        width: 28,
    },
    xs: {
        height: 32,
        width: 32,
    },
    sm: {
        height: 36,
        width: 36,
    },
    md: {
        height: 42,
        width: 42,
    },
    lg: {
        height: 48,
        width: 48,
    },
};

export const BORDER_RADIUS_SIZES: { [key in SizeType]: BaseButtonProps['borderRadius'] } = {
    '3xs': 'sm',
    '2xs': 'sm',
    xs: 'md',
    sm: 'lg',
    md: 'lg',
    lg: 'lg',
};
