import React, { forwardRef } from 'react';
import { Box } from '@fiverr-private/theme';

import { TypographyProps } from './types';

const Typography = forwardRef<HTMLElement, TypographyProps>(
  (
    {
      as = 'p',
      children,
      fontFamily = 'primary',
      textAlign,
      fontWeight,
      decoration,
      color,
      lineHeight,
      fontSize,
      size,
      ...restProps
    }: TypographyProps,
    ref
  ) => (
    <Box
      ref={ref}
      color={color}
      textAlign={textAlign}
      fontSize={fontSize || size}
      lineHeight={lineHeight || size}
      fontWeight={fontWeight}
      decoration={decoration}
      fontFamily={fontFamily}
      as={as}
      {...restProps}
    >
      {children}
    </Box>
  )
);

Typography.displayName = 'Typography';

export default Typography;
