import { searchOnlyWidthClass, defaultStateWidthClass, openStateWidthClass } from './styles.ve.css';

const getRoutingSearchBarWidthClass = (isSearchOnly, isRoutingSearchBarOpen) => {
    if (isSearchOnly) {
        return searchOnlyWidthClass;
    }

    if (isRoutingSearchBarOpen) {
        return openStateWidthClass;
    }

    return defaultStateWidthClass;
};

export { getRoutingSearchBarWidthClass };
