import { logger } from '@fiverr-private/obs';
import { validateOptions } from '../utils/validateOptions';
import { toParts } from '../utils/toParts';
import { LocalizationContext } from '../../../shared/constants';
import { ERROR_MESSAGES } from '../constants';
import { NUMBER_FORMATTING_OPTIONS, NUMBER_STYLES } from './constants';

const NUMBER_PARTS = {
    INTEGER: 'integer',
    DECIMAL: 'decimal',
    FRACTION: 'fraction',
    MINUS_SIGN: 'minusSign',
    LITERAL: 'literal',
    PERCENTAGE: 'percentage',
};

interface numberFormatOptions {
    fallbackValue?: string;
    formattingLocale?: string;
    style?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    noFractionDigits?: boolean;
    asNumber?: boolean;
    asParts?: boolean;
}

export interface numberFormatParams extends LocalizationContext {
    value: number;
    options?: numberFormatOptions;
}

/**
 * Format a number.
 * Use this to format numbers, either decimal (i.e '3', '5.3') or percentages (i.e '40%', '98.5%').
 * Return the number as a string, formatted to user formatting locale.
 * @param {Object} localizationContext - the localization context that is being injected
 * dynamically according to the run time env by one of the wrappers - withBrowserContext / withMainContext / withModuleContext
 * @param {number} value Number to be formatted.
 * @param {Object} [options={}] Optional formatting configurations.
 * @returns {string|number} Formatted value string.
 */
const numberFormat = ({ localizationContext, value, options = {} }: numberFormatParams) => {
    const fallbackValue = (options.fallbackValue || value).toString();

    try {
        if (Intl === undefined) {
            logger.warn(ERROR_MESSAGES.INTL_IS_NOT_DEFINED);
            return fallbackValue;
        }

        if (!localizationContext || Object.keys(localizationContext).length === 0) {
            return fallbackValue;
        }

        const { asNumber, asParts } = options;
        const formattingLocale = options.formattingLocale || localizationContext.formattingLocale;
        const parsedOptions = parseOptions(options);
        const formattedString = new Intl.NumberFormat(formattingLocale, parsedOptions).format(value);
        if (asNumber || asParts) {
            const formattedNumber = parseFloat(formattedString.replace(/,/g, '.'));
            return asNumber
                ? formattedNumber
                : toParts({
                      localizationContext,
                      formattingLocale,
                      formattedString,
                      formattedNumber,
                  });
        }
        return formattedString;
    } catch (e) {
        logger.error(e as Error, { value, options, formatter: 'numberFormat' });
        return fallbackValue;
    }
};

/**
 * Parse formatter options.
 * This method validates the properties in the options object and overrides properties for Fiverr custom formatting rules.
 * @param {Object} options Optional formatting configurations.
 * @returns {Object} Validated formatting configurations.
 */
const parseOptions = (options) => {
    const validatedOptions = validateOptions(NUMBER_FORMATTING_OPTIONS, options);

    if (options.noFractionDigits) {
        validatedOptions.minimumFractionDigits = 0;
        validatedOptions.maximumFractionDigits = 0;
    }

    if (options.asNumber || options.asParts) {
        validatedOptions.useGrouping = false;
    }

    return validatedOptions;
};

export { NUMBER_STYLES, NUMBER_PARTS, numberFormat, parseOptions };
