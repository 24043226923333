import axios from 'axios';
import { logger, LOG_LEVELS } from '@fiverr-private/obs';
import { ExperimentType, EXPERIMENT_STATE, ExperimentState, ExperimentEnrichment } from './helpers';
import { AllocationMismatchError, ExperimentStateError } from './helpers/errors';
import { toSnakeCase } from './helpers/utils';
import { LOGGER_ENRICHMENT } from './helpers/constants';

interface AllocationResponse {
    group: number;
    state: ExperimentState;
}

/**
 * Makes an API call to the public allocate endpoint.
 * Uses only Contexto values for uid / userId.
 *
 * @param experimentId - The experiment to allocate to.
 * @param experimentType - The type of experiment.
 * @param numOfGroups - Number of groups in the experiment.
 * @param group - The group calculated in the client to validate against the service response.
 * @param enrichment - Optional ExperimentEnrichment object
 */
export const allocateApi = async (
    experimentId: number,
    experimentType: ExperimentType,
    numOfGroups: number,
    group: number,
    enrichment: ExperimentEnrichment = {}
): Promise<void> => {
    const route = '/experiment_allocate';
    const snakeCaseEnrichment = toSnakeCase(enrichment);

    try {
        const { data }: { data: AllocationResponse } = await axios.post(route, {
            experiment_id: experimentId,
            type: experimentType,
            num_of_groups: numOfGroups,
            ...snakeCaseEnrichment,
        });

        handleServerResponse(data, experimentId, group);
    } catch (error) {
        handleError(error as Error, { experimentId, experimentType, group });
    }
};

/**
 * Makes an API call to the public dangerously allocate endpoint.
 * Uses only Contexto values for uid / userId.
 *
 * @param experimentId - The experiment to allocate to.
 * @param experimentType - The type of experiment.
 * @param group - The group to dangerously allocate to.
 * @param enrichment - Optional ExperimentEnrichment object
 */
export const dangerouslyAllocateApi = async (
    experimentId: number,
    experimentType: ExperimentType,
    group: number,
    enrichment: ExperimentEnrichment = {}
): Promise<void> => {
    const route = '/experiment_dangerously_allocate';
    const snakeCaseEnrichment = toSnakeCase(enrichment);

    try {
        const { data }: { data: AllocationResponse } = await axios.post(route, {
            experiment_id: experimentId,
            type: experimentType,
            group,
            ...snakeCaseEnrichment,
        });

        handleServerResponse(data, experimentId, group);
    } catch (error) {
        handleError(error as Error, { experimentId, experimentType, group });
    }
};

/**
 * Throws the appropriate error in the event 200 response from service, but error in response body.
 * - attempting to allocate to locked / disabled experiments.
 * - group calculation different than that in service (should never happen).
 *
 * @param response - Experiment service response.
 * @param experimentId Experiment identifier.
 * @param group - group as calculated in the client.
 */
const handleServerResponse = (response: AllocationResponse, experimentId: number, group: number): void => {
    if (response.state === EXPERIMENT_STATE.LOCKED || response.state === EXPERIMENT_STATE.DISABLED) {
        const message = `Attempt to allocate to an experiment in state: ${response.state} failed. ExperimentId: ${experimentId}.`;
        throw new ExperimentStateError(message);
    }

    if (response.group !== group) {
        const message = `Allocation Mismatch - package group calculation: ${group}, experiment service group response: ${response.group}`;
        throw new AllocationMismatchError(message);
    }
};

/**
 * Maps error class name to log level severity.
 */
const ERROR_TO_LOG_LEVEL_MAPPER = new Map<string, string>([
    [AllocationMismatchError.name, LOG_LEVELS.ERROR],
    [ExperimentStateError.name, LOG_LEVELS.ERROR],
]);

/**
 * Maps error to correct log level (default is warn, for network errors etc) and logs error.
 *
 * @param error - Error.
 * @param enrichment - Enrichment to pass to logger.
 */
const handleError = (error: Error, enrichment: { [key: string]: any }): void => {
    const level = ERROR_TO_LOG_LEVEL_MAPPER.get(error.constructor.name) ?? LOG_LEVELS.WARN;
    logger[level](error as Error, {
        ...enrichment,
        ...LOGGER_ENRICHMENT,
    });
};
