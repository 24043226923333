import { LocalizationContext } from '../../../../shared/constants';
import { DOLLAR_SYMBOL, DOLLAR_SYMBOL_OVERRIDE, CURRENCY_STYLE_TO_OVERRIDE } from './constants';

export interface currencyFormatParams extends LocalizationContext {
    currencyCode: string;
    formattingLocale?: string;
}

/**
 * Returns the currency symbol of a specific currency in a specific formattingLocale.
 * Formatting locale is optional. If it's not passed, it is taken from the localizationContext
 * @param {Object} localizationContext - The localization context in the runtime.
 * injected dynamically by the relevant wrapper (withBrowserContext / withMainContext / withModuleContext)
 * @param {string} currencyCode - The currency code you would like to get its symbol, for ex: 'USD'
 * @param {string} formattingLocale - The formatting locale you want to get the symbol in. for ex: 'fr-FR'
 * @returns {string} - The currency symbol. examples: '$' / 'US $' / '€' / 'MX$'
 */
export const getCurrencySymbol = ({
    localizationContext,
    currencyCode,
    formattingLocale = '',
}: currencyFormatParams) => {
    const amount = 1;
    const resolvedFormattingLocale = formattingLocale || localizationContext!.formattingLocale;
    const formattedString = amount.toLocaleString(resolvedFormattingLocale, {
        style: 'currency',
        currency: currencyCode,
    });
    const parsedOptions = { currencyDisplay: CURRENCY_STYLE_TO_OVERRIDE };
    const overriddenCurrencySymbolString = overrideCurrencySymbol({ formattedString, currencyCode, parsedOptions });

    return overriddenCurrencySymbolString.replace(/[0-9]|\.|,/g, '').trim();
};

interface overrideCurrencySymbolParams {
    formattedString: string;
    currencyCode: string;
    parsedOptions?: {
        currencyDisplay?: string;
    };
}

/**
 * In a few cases (currencies) that the symbol might be '$' we need to make sure to override it,
 * so we won't display '$' as a currency that is not USD.
 * List of currencies we override: CAD AUD MXN
 * Note: We are overriding the symbol only the user requests display of a symbol (and not code)
 * @param {string} formattedString - The formatting string of the entire currency string (ex: '123 $'),
 * @param {string} currencyCode - The currency code that ie being used to format the currency (ex: 'AUD')
 * @param {Object} parsedOptions - The options object the formatter used (passed to Intl) in order to format
 * @returns {string} - The formatted string manipulated with the override currency symbol in it
 */
export const overrideCurrencySymbol = ({
    formattedString,
    currencyCode,
    parsedOptions,
}: overrideCurrencySymbolParams) => {
    if (
        DOLLAR_SYMBOL_OVERRIDE[currencyCode] &&
        parsedOptions?.currencyDisplay === CURRENCY_STYLE_TO_OVERRIDE &&
        formattedString.indexOf(DOLLAR_SYMBOL_OVERRIDE[currencyCode]) === -1
    ) {
        return formattedString.replace(DOLLAR_SYMBOL, `${DOLLAR_SYMBOL_OVERRIDE[currencyCode]}${DOLLAR_SYMBOL}`);
    }
    return formattedString;
};
