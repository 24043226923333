import { get } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { FORM_SOURCE, PANEL_SOURCE } from '../../utils/constants';
import * as SuggestionsSelector from '../Suggestions';
import { SearchBarMode } from '../../components/SearchBar/constants';

export const displaySearch = (state) => {
    if (!state) {
        return '';
    }

    const { value } = SuggestionsSelector.suggestionById(state, SuggestionsSelector.selectedSuggestionId(state));

    if (value) {
        return value;
    }

    const { search } = state;

    if (!search) {
        return '';
    }

    return search;
};

export const displaySearchCategory = (state) => {
    if (!state) {
        return '';
    }

    const { category } = SuggestionsSelector.suggestionById(state, SuggestionsSelector.selectedSuggestionId(state));
    return category ? category : '';
};

export const originalTerm = (state) => {
    const originalTerm = get(state, 'originalTerm');

    if (!originalTerm) {
        return '';
    }
    return originalTerm.replace(/[&/\\#()$~%*<>"{}]/g, '').toLowerCase();
};

export const search = (state) => {
    if (!state) {
        return '';
    }

    const { search } = state;

    if (!search) {
        return '';
    }

    return search.replace(/[&/\\#()$~%*<>"{}]/g, '').toLowerCase();
};

export const searchTitle = (state = {}) => {
    const { mode } = state;

    return mode === SearchBarMode.Pro ? 'search_bar.search_pro' : 'search_bar.search';
};

export const searchPlaceholder = (state = {}) => {
    const { mode, placeholder } = state;

    if (placeholder) {
        return placeholder;
    }

    return mode === SearchBarMode.Pro ? 'search_bar.placeholder_pro' : 'search_bar.placeholder';
};

export const isSearch = (state) =>
    !!search(state) &&
    !![...SuggestionsSelector.gigSuggestionIds(state), ...SuggestionsSelector.userSuggestionIds(state)].length;

export const source = (state = {}) => {
    const { source } = state;

    return source;
};

export const trigger = (state = {}) => (SuggestionsSelector.selectedSuggestionId(state) ? 'autocomplete' : 'enter');

export const customQueryParams = (state = {}) => state.customQueryParams || {};

export const baseQueryParams = (originalTerm, type, position) => {
    const { pageCtxId } = getContext();

    return {
        acmpl: 1,
        search_in: 'everywhere',
        'search-autocomplete-original-term': originalTerm,
        'search-autocomplete-available': 'true',
        'search-autocomplete-type': type,
        'search-autocomplete-position': position,
        ref_ctx_id: pageCtxId,
    };
};

export const footerPath = (state) => {
    const query = search(state);
    const params = {
        query,
        source: source(state),
        acmpl: 1,
        search_in: 'everywhere',
        'search-autocomplete-original-term': query,
        'search-autocomplete-available': 'false',
        'search-autocomplete-type': 'search-gigs',
        ...customQueryParams(state),
    };
    const pathName = state.mode === SearchBarMode.Pro ? 'pro_search_gigs' : 'search_gigs';

    return pathfinder(pathName, null, { query: params });
};

export const searchHistoryPath = (state, queryParams) => {
    const originalQuery = originalTerm(state);
    const query = displaySearch(state);
    const position = SuggestionsSelector.selectedSuggestionPosition(state);
    const params = {
        query,
        source: source(state),
        ...baseQueryParams(originalQuery, 'recent-gigs-suggest', position),
        ...queryParams,
        ...customQueryParams(state),
    };
    const pathName = state.mode === SearchBarMode.Pro ? 'pro_search_gigs' : 'search_gigs';

    return pathfinder(pathName, null, { query: params });
};

export const searchPopularPath = (state, queryParams) => {
    const originalQuery = originalTerm(state);
    const query = displaySearch(state);
    const { popular, selectedSuggestionId } = state;
    const { order, type } = popular[selectedSuggestionId];
    const params = {
        query,
        source: source(state),
        ...baseQueryParams(originalQuery, 'popular', order),
        'search-autocomplete-sub-type': type,
        ...queryParams,
        ...customQueryParams(state),
    };

    const pathName = state.mode === SearchBarMode.Pro ? 'pro_search_gigs' : 'search_gigs';

    return pathfinder(pathName, null, { query: params });
};

export const searchGigsPath = (state, queryParams) => {
    const originalQuery = originalTerm(state);
    const query = displaySearch(state);
    const { order = -1 } = SuggestionsSelector.selectedSuggestion(state) || {};
    const params = {
        query,
        source: source(state),
        ...baseQueryParams(originalQuery, 'suggest', order),
        ...queryParams,
        ...customQueryParams(state),
    };
    const pathName = state.mode === SearchBarMode.Pro ? 'pro_search_gigs' : 'search_gigs';

    return pathfinder(pathName, null, { query: params });
};

export const searchUsersPath = (state, queryParams) => {
    const query = search(state);
    const position = SuggestionsSelector.selectedSuggestionPosition(state);
    const params = {
        query,
        source: source(state),
        ...baseQueryParams(query, 'users-lookup', position),
        ...queryParams,
        ...customQueryParams(state),
    };

    return pathfinder('search_users', null, { query: params });
};

export const searchUserPath = (state, queryParams) => {
    const params = {
        ...queryParams,
        ...customQueryParams(state),
    };
    return pathfinder('short_user', { id: displaySearch(state) }, { query: params });
};

export const searchFallbackPath = (state, queryParams) => {
    const { pageCtxId } = getContext();
    const originalQuery = originalTerm(state);
    const query = displaySearch(state);
    const params = {
        query,
        source: source(state),
        ref_ctx_id: pageCtxId,
        search_in: 'everywhere',
        'search-autocomplete-original-term': originalQuery,
        ...queryParams,
        ...customQueryParams(state),
    };
    const pathName = state.mode === SearchBarMode.Pro ? 'pro_search_gigs' : 'search_gigs';

    return pathfinder(pathName, null, { query: params });
};

export const searchPath = (state, queryParams = {}) => {
    const selectedSuggestion = SuggestionsSelector.selectedSuggestion(state);

    if (selectedSuggestion.id === SuggestionsSelector.USERS_SEARCH) {
        return searchUsersPath(state, queryParams);
    }

    if (selectedSuggestion.group === SuggestionsSelector.HISTORY_SEARCH) {
        return searchHistoryPath(state, queryParams);
    }

    if (selectedSuggestion.group === SuggestionsSelector.POPULAR_SEARCH) {
        return searchPopularPath(state, queryParams);
    }

    if (selectedSuggestion.group === SuggestionsSelector.GIGS_SEARCH) {
        return searchGigsPath(state, queryParams);
    }

    if (selectedSuggestion.group === SuggestionsSelector.USER_SEARCH) {
        return searchUserPath(state);
    }

    return searchFallbackPath(state, queryParams);
};

export const shouldSubmitSearch = (state = {}, submitSource) => {
    const { submitOnSuggestionSelected } = state;

    return submitSource === FORM_SOURCE || (submitSource === PANEL_SOURCE && submitOnSuggestionSelected);
};
