import sendBigQueryEvent from '../../services/BigQuery';
import * as SearchSelector from '../../selectors/Search';
import { SUBMIT_SEARCH } from '../../actions';

const Search = (store) => (next) => (action) => {
    const { type } = action;

    if (type === SUBMIT_SEARCH) {
        const state = store.getState();

        sendBigQueryEvent({
            group: 'algos',
            type: 'submit_search',
            omnibox: {
                search_query: SearchSelector.search(state),
                click_type: SearchSelector.trigger(state),
            },
        });
    }

    next(action);
};

export default Search;
