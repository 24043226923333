import { OWLS_LOCALIZED_AUTOCOMPLETE } from './experimentsEnum';

export function autocompleteTests(abTests) {
    if (!abTests) {
        return {};
    }

    const tests = {};

    const localizationExpVariant = abTests[OWLS_LOCALIZED_AUTOCOMPLETE];
    if (localizationExpVariant) {
        tests[OWLS_LOCALIZED_AUTOCOMPLETE] = localizationExpVariant;
    }

    return tests;
}
