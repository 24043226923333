import { getValueOrField } from '../../utils';
import { DURATION, EASING } from '../../constants';
import type {
    Duration,
    DurationType,
    Easing,
    EasingType,
    FadeOverrideProps,
    VariantsKeys,
    VariantsType,
} from '../../types';

const getTiming = (
    variant: VariantsKeys,
    duration: DurationType = 'fast2',
    easing: EasingType = 'linear',
    delay?: DurationType
) => ({
    duration: DURATION[getValueOrField(duration, variant) as Duration],
    ease: EASING[getValueOrField(easing, variant) as Easing],
    delay: DURATION[getValueOrField(delay, variant) as Duration],
});

const variants: VariantsType<FadeOverrideProps> = {
    enter: ({ delay, duration, easing, endingOpacity = 1 } = {}) => ({
        opacity: [endingOpacity],
        timing: getTiming('enter', duration, easing, delay),
    }),
    exit: ({ delay, duration, easing } = {}) => ({
        opacity: [0],
        timing: getTiming('exit', duration, easing, delay),
    }),
};

export default variants;
