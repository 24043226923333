/**
 * Supported experiment variants.
 *
 * @type {Object<number>}
 */
export const VARIANTS = {
    A: 1,
    B: 2,
    C: 3,
};

/**
 * Current supported experiments.
 */
export const EXPERIMENTS = {
    CAT_MULTI_LOCALE_SETTINGS_HEADER: 3927,
    MEERKATS_LOHP_REVAMP: 4350,
    DOLPHINS_DOLPHINS_FIB_LOGOUT_PRO_MENUS_ID: 4732,
    MUSTANGS_ROUTING_SEARCH_BAR: 4706,
};
