import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { FiverrPro } from './components/FiverrPro';
import UpgradeToProLink from './components/UpgradeToProLink';

interface BusinessProSolutionsNavigationProps {
    isTransparent: boolean;
    onOpen: () => void;
}

export const BusinessProSolutionsNavigation: React.FC<BusinessProSolutionsNavigationProps> = ({
    isTransparent,
    onOpen,
}) => {
    const { userId } = getContext();

    return userId ? <UpgradeToProLink /> : <FiverrPro isTransparent={isTransparent} onOpen={onOpen} />;
};
