import React from 'react';
import getContext from './getContext';

/**
 * Wraps a given component with browser exposed context.
 * @param WrappedComponent - The component to provide the context to.
 * @return {Function}
 */
const withBrowserContext = (WrappedComponent) =>
    function WithBrowserContext(props) {
        const context = getContext();
        const enrichedProps = { ...context, ...props };

        return <WrappedComponent {...enrichedProps} />;
    };

export { withBrowserContext, getContext };
