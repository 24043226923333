import React, { forwardRef } from 'react';
import BaseButton from '../BaseButton';
import type { ButtonProps } from './types';
import { getConditionalFullWidth, getConditionalSize } from './utils';

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      disabled = false,
      intent = 'primary',
      size = 'md',
      variant = 'filled',
      loading = false,
      className,
      fullWidth,
      ...rest
    },
    ref
  ) => (
    <BaseButton
      {...rest}
      {...getConditionalSize(size)}
      width={getConditionalFullWidth(fullWidth)}
      variant={variant}
      intent={intent}
      loading={loading}
      disabled={disabled}
      ref={ref}
      className={className}
    >
      {children}
    </BaseButton>
  )
);

Button.displayName = 'Button';

export default Button;
