import React from 'react';
import { object } from 'prop-types';
import ResourcesTab from '@fiverr-private/resources_tab/src/ResourcesTab';
import { Button } from '@fiverr-private/fit';
import withContext from '../../lib/withContext';
import Nav from '../../lib/Nav';
import { GuestBecomeSellerLink } from '../../components/GuestLinks';
import JoinLink from '../../components/JoinLink';
import Layout from '../../components/Layout';
import LeanLocaleSettingsLink from '../../components/LocaleSettingsLinks/LeanLocaleSettingsLink';
import LoginLink from '../../components/LoginLink';
import { Logo } from '../../components/Logo';
import MainRow from '../../components/MainRow';
import Navicon from '../../components/Navicon';
import Search from '../../components/Search';
import { BusinessProSolutionsNavigation } from '../../components/BusinessProSolutionsNavigation';

import { FiverrGoAnimated } from '../../components/FiverrGoLinks/FiverrGoAnimated';
import styles from './styles.module.scss';

export const LoggedOut = ({ rollouts }) => {
    const inLynxesGoHeaderEntryRollout = rollouts?.inLynxesGoHeaderEntryRollout;

    return (
        <Layout>
            <MainRow>
                <Navicon />
                <Logo />
                <Search rollouts={rollouts} />
                <Nav alignToRight className="nav-with-space">
                    <Nav.Item className={styles.navButton} displayFromMD>
                        <BusinessProSolutionsNavigation />
                    </Nav.Item>
                    <Nav.Item displayFromLG>
                        <ResourcesTab headerType="logged_out" rollouts={rollouts} />
                    </Nav.Item>
                    <Nav.Item displayFromLG>
                        <LeanLocaleSettingsLink />
                    </Nav.Item>
                    <Nav.Item displayFromMD>
                        <GuestBecomeSellerLink />
                    </Nav.Item>
                    {inLynxesGoHeaderEntryRollout && (
                        <Nav.Item displayFromXL>
                            <FiverrGoAnimated />
                        </Nav.Item>
                    )}
                    <Nav.Item displayFromSM>
                        <LoginLink />
                    </Nav.Item>
                    <Nav.Item>
                        {inLynxesGoHeaderEntryRollout ? <JoinLink color={Button.COLORS.BLACK} /> : <JoinLink />}
                    </Nav.Item>
                </Nav>
            </MainRow>
        </Layout>
    );
};

LoggedOut.propTypes = {
    rollouts: object,
};

/**
 * @param {HeaderProps} props
 * @returns {React.ReactElement} Header
 */
export default withContext(LoggedOut);
