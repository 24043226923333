import { useState } from 'react';
import { logger } from '@fiverr-private/obs';
import CurrencyPlugin from '@fiverr-private/currencies_plugin';

export type Currency = ReturnType<CurrencyPlugin['getCurrency']>;
export const OPEN_DRAWER_EVENT_NAME = 'openBriefCreationDrawer';
export const WINDOW_INDICATION = 'isBriefCreationDrawerLoaded';

export const isDrawerMounted = () => !!window[WINDOW_INDICATION];

export interface DrawerProps {
    id?: string;
    source: string;
    currency: Currency;
    isOpen: boolean;
    setIsLoading?: (isLoading: boolean) => void;
    onClose?: (result: any) => void;
    initialPrompt?: string;
}

export type OpenDrawerParams = Omit<DrawerProps, 'isOpen'>;

export const openBriefCreationDrawer = ({
    currency,
    source,
    setIsLoading,
    id,
    onClose,
    initialPrompt,
}: OpenDrawerParams) => {
    if (!isDrawerMounted()) {
        logger.error('Creation drawer is not mounted');
        return false;
    }

    const event = new CustomEvent<DrawerProps>(OPEN_DRAWER_EVENT_NAME, {
        detail: {
            id,
            source,
            currency,
            isOpen: true,
            setIsLoading,
            onClose,
            initialPrompt,
        },
    });
    window.dispatchEvent(event);
    return true;
};

export const useOpenBriefCreationDrawer = (id?: string) => {
    const [isLoading, setIsLoading] = useState(false);

    const openBriefCreationDrawerInternal = ({ currency, source, onClose, initialPrompt }: OpenDrawerParams) => {
        openBriefCreationDrawer({ currency, source, id, setIsLoading, onClose, initialPrompt });
    };

    return { openBriefCreationDrawer: openBriefCreationDrawerInternal, isLoading };
};
