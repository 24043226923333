/* eslint-disable react/no-is-mounted */
import React, { Component, Suspense, lazy, createRef } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import Toggler from '@fiverr/ui_toggle';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { Container } from '@fiverr-private/layout_components';
import bigQueryMiddleware from '../../middlewares/BigQuery';
import searchMiddleware from '../../middlewares/Search';
import promotedGigsMiddleware from '../../middlewares/PromotedGigs';
import reducers from '../../reducers';
import { source } from '../../selectors/Search';
import sendBigQueryEvent from '../../services/BigQuery';
import sendMixPanelEvent from '../../services/MixPanel';
import SearchForm from '../SearchForm';
import { SubmitButton } from '../SubmitButton';
import { BIG_QUERY_FOCUS_TYPE, BIG_QUERY_FOCUS_GROUP } from '../../utils/analytics/bigQuery/constants';
import { MIXPANEL_EVENT_NAME_FOCUS } from '../../utils/analytics/mixpanel/constants';
import { mixpanelFocusProperties } from '../../utils/analytics/mixpanel';
import { calculateClientExperimentsGroups } from '../../utils/clientExperiments/utils';
import './style.scss';
import { SUBMIT_BUTTON_TYPE, THEME } from '../../utils/constants';
import { SearchBarPanelContext } from '../Pro/SearchPanelPro/context';
import { getRoutingSearchBarWidthClass, SUBMIT_BUTTON_OFFSET } from '../RoutingLayout';
import { SearchBarMode } from './constants';

export const store = (
    mode,
    source,
    placeholder,
    submitOnSuggestionSelected,
    searchWillSubmit,
    onSearch,
    customQueryParams,
    rollouts,
    currency,
    clientExperimentsGroups
) => {
    const initialState = {
        mode,
        source,
        placeholder,
        submitOnSuggestionSelected,
        searchWillSubmit,
        onSearch,
        customQueryParams,
        rollouts,
        currency,
        clientExperimentsGroups,
    };

    try {
        const { queryParameters = {} } = getContext();
        const { query } = queryParameters;
        const originalTerm = queryParameters['search-autocomplete-original-term'];

        initialState.search = query;
        initialState.originalTerm = originalTerm;
    } catch (error) {
        logger.warn(`Failed to parse queryParameters from Fiverr context, ${window.location.href}`);
    }

    return createStore(
        reducers,
        initialState,
        applyMiddleware(thunkMiddleware, bigQueryMiddleware, searchMiddleware, promotedGigsMiddleware)
    );
};

const SearchPanel = lazy(() => import(/* webpackChunkName: 'SearchPanel' */ '../Panel/SearchPanel'));
const SearchPanelPro = lazy(() => import(/* webpackChunkName: 'SearchPanelPro' */ '../Pro/SearchPanelPro'));

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.store = store(
            props.mode,
            props.source,
            props.placeholder,
            props.submitOnSuggestionSelected,
            props.searchWillSubmit,
            props.onSearch,
            props.customQueryParams,
            props.rollouts,
            props.currency,
            calculateClientExperimentsGroups(props.rollouts)
        );

        this.state = {
            formInputWidthInPixels: 0,
            isRoutingSearchBarOpen: !!this.store.getState().search,
        };

        this.containerRef = createRef();
        this.formInputRef = createRef();
        this.observer = null;
    }

    componentDidMount() {
        const { mode } = this.props;
        const formInputElement = this.formInputRef.current;
        if (!formInputElement || mode !== SearchBarMode.Routing) {
            return;
        }
        this.observer = new ResizeObserver(([entry]) => {
            if (!entry) {
                return;
            }
            window.requestAnimationFrame(() => {
                const newWidth = entry.contentRect.width;
                this.setState((prevState) =>
                    prevState.formInputWidthInPixels !== newWidth ? { formInputWidthInPixels: newWidth } : null
                );
            });
        });
        this.observer.observe(formInputElement);
        document.addEventListener('click', this.handleClickOutsideSearchBar);
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }

        document.removeEventListener('click', this.handleClickOutsideSearchBar);
    }

    handleClickOutsideSearchBar = (event) => {
        const container = this.containerRef.current;
        const inputElement = this.formInputRef.current?.querySelector('input.short-placeholder');

        if (!container || !inputElement) {
            return;
        }

        if (!container.contains(event.target)) {
            this.setState({ isRoutingSearchBarOpen: inputElement.value !== '' });
        }
    };

    sendFocusEvents() {
        sendBigQueryEvent({
            type: BIG_QUERY_FOCUS_TYPE,
            group: BIG_QUERY_FOCUS_GROUP,
        });
        sendMixPanelEvent(
            MIXPANEL_EVENT_NAME_FOCUS,
            mixpanelFocusProperties({
                source: source(this.store.getState()),
            })
        );
    }

    isPro() {
        const { experience } = getContext();
        return experience?.isBusiness;
    }

    render() {
        const {
            children,
            mode,
            formToQueryParams,
            submitOnEnter,
            abTests,
            theme,
            dimBackgroundEnabled,
            rollouts,
            submitButtonType,
            showDefaultSearchPanelForPro,
            isSearchOnly,
            showRoutingSearchBarAnimation,
        } = this.props;

        const showProSearchPanel = this.isPro() && !showDefaultSearchPanelForPro;
        const isRoutingSearchBar = this.props.mode === SearchBarMode.Routing;

        const routingSearchBarClass = className(
            'search-bar-package',
            'search_bar-package',
            getRoutingSearchBarWidthClass(isSearchOnly, this.state.isRoutingSearchBarOpen)
        );

        const searchBarClass = className('search-bar-package', 'search_bar-package', {
            'search-bar-pro': mode === SearchBarMode.Pro,
        });

        return (
            <Provider store={this.store}>
                <Toggler>
                    {({ open, close, isOpen, preventCloseAction }) => {
                        const onFocus = () => {
                            open();
                            this.sendFocusEvents();
                        };

                        return (
                            <Container
                                className={isRoutingSearchBar ? routingSearchBarClass : searchBarClass}
                                ref={(el) => {
                                    preventCloseAction(el);
                                    this.containerRef.current = el;
                                }}
                            >
                                <SearchForm
                                    onFocus={onFocus}
                                    formInputRef={this.formInputRef}
                                    onKeyDown={open}
                                    onSubmit={close}
                                    close={close}
                                    formToQueryParams={formToQueryParams}
                                    submitOnEnter={submitOnEnter}
                                    theme={theme}
                                    inSearchBarAnimationExp={abTests.inSearchBarAnimationExp}
                                    dimBackgroundEnabled={dimBackgroundEnabled}
                                    containerRef={this.containerRef}
                                    submitButtonType={submitButtonType}
                                    isRoutingSearchBarOpen={this.state.isRoutingSearchBarOpen}
                                    setIsRoutingSearchBarOpen={(isOpen) => {
                                        this.setState({ isRoutingSearchBarOpen: isOpen });
                                    }}
                                    isSearchOnly={isSearchOnly}
                                    showRoutingSearchBarAnimation={showRoutingSearchBarAnimation}
                                >
                                    {children}
                                </SearchForm>
                                {isOpen && (
                                    <Suspense fallback={null}>
                                        <Container
                                            position="relative"
                                            width={
                                                isRoutingSearchBar
                                                    ? this.state.formInputWidthInPixels + SUBMIT_BUTTON_OFFSET
                                                    : '100%'
                                            }
                                        >
                                            {showProSearchPanel ? (
                                                <SearchBarPanelContext.Provider
                                                    value={{
                                                        preventCloseActionRef: preventCloseAction,
                                                        bsm: this.props.bsm,
                                                        businessType: this.props.businessType,
                                                    }}
                                                >
                                                    <SearchPanelPro />
                                                </SearchBarPanelContext.Provider>
                                            ) : (
                                                <SearchPanel rollouts={rollouts} />
                                            )}
                                        </Container>
                                    </Suspense>
                                )}
                            </Container>
                        );
                    }}
                </Toggler>
            </Provider>
        );
    }
}

export const currencyObjectType = PropTypes.shape({
    code: PropTypes.string,
    template: PropTypes.string,
    symbol: PropTypes.string,
    name: PropTypes.string,
    exchange_rate: PropTypes.number,
});

SearchBar.propTypes = {
    onSearch: PropTypes.func,
    theme: PropTypes.string,
    placeholder: PropTypes.string,
    source: PropTypes.string,
    mode: PropTypes.oneOf([SearchBarMode.Default, SearchBarMode.Pro, SearchBarMode.LoggedOut, SearchBarMode.Routing]),
    bsm: PropTypes.shape({
        user_name: PropTypes.string,
        display_name: PropTypes.string,
        profile_image_url: PropTypes.string,
    }),
    businessType: PropTypes.string,
    submitOnSuggestionSelected: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
    customQueryParams: PropTypes.object,
    formToQueryParams: PropTypes.func,
    searchWillSubmit: PropTypes.func,
    abTests: PropTypes.shape({
        inSearchBarAnimationExp: PropTypes.bool,
    }),
    rollouts: PropTypes.object,
    dimBackgroundEnabled: PropTypes.bool,
    submitButtonType: PropTypes.oneOf([
        SUBMIT_BUTTON_TYPE.ICON,
        SUBMIT_BUTTON_TYPE.TEXT,
        SUBMIT_BUTTON_TYPE.BUTTON_INSIDE,
        SUBMIT_BUTTON_TYPE.ROUNDED,
    ]),
    currency: currencyObjectType,
    showDefaultSearchPanelForPro: PropTypes.bool,
    isSearchOnly: PropTypes.bool,
    showRoutingSearchBarAnimation: PropTypes.bool,
};

SearchBar.defaultProps = {
    theme: THEME.DEFAULT,
    mode: SearchBarMode.Default,
    bsm: {},
    submitOnSuggestionSelected: true,
    submitOnEnter: true,
    searchWillSubmit: () => true,
    onSearch: () => null,
    customQueryParams: {},
    abTests: {},
    rollouts: {},
    clientExperimentsGroups: {},
    dimBackgroundEnabled: false,
    submitButtonType: SUBMIT_BUTTON_TYPE.ICON,
    currency: {},
    showDefaultSearchPanelForPro: false,
    isSearchOnly: false,
    showRoutingSearchBarAnimation: false,
    isRoutingSearchBarOpen: false,
};

SearchBar.SubmitButton = SubmitButton;

export default SearchBar;
