import { useCallback, useEffect, useRef } from 'react';
import { compact, isNil } from 'lodash';
import { usePopoverContext } from '@fiverr-private/popover';
import type { MenuItem, MenuItems } from '../../Dropdown/types';
import useDropdownContext from '../../Dropdown/useDropdownContext';
import useMenuNavigation from '../../DropdownMenu/useMenuNavigation';
import { focusContent } from '../..';

const useContent = () => {
  const { setOpen, open } = usePopoverContext();
  const { values, anchorRef, items, searchWhenClosed, setFocusedItem, isMultiSelect } = useDropdownContext();
  const hasOpened = useRef(open);
  const contentRef = useRef<HTMLDivElement>(null);

  const onAnimationStart = () => {
    if (open) {
      if (searchWhenClosed) {
        setTimeout(() => focusContent({ items, setFocusedItem, values }), 50);
      } else {
        focusContent({ items, setFocusedItem, values });
      }
    }
  };

  useEffect(() => {
    if (open) {
      hasOpened.current = true;
    } else if (hasOpened.current) {
      hasOpened.current = false;
      anchorRef.current?.focus();
    }
  }, [open, hasOpened, anchorRef, setFocusedItem]);

  const addMenuItem = useCallback(
    (menuItem: MenuItem) => {
      const menuItemsElements =
        contentRef.current && Array.from(contentRef.current.querySelectorAll('[role="listitem"]'));
      const updatedMenuItems: MenuItems = [];

      items.push(menuItem);
      items.forEach((item) => {
        const itemIndex = menuItemsElements?.findIndex((child) => child === item.ref.current);
        if (itemIndex !== undefined && itemIndex !== -1 && !isNil(item)) {
          updatedMenuItems[itemIndex] = item;
        }
      });

      items.splice(0, items.length, ...compact(updatedMenuItems));
    },
    [items, contentRef]
  );

  const removeMenuItem = useCallback(
    (item: MenuItem['ref']) => {
      const index = items.findIndex(({ ref }) => ref.current === item.current);

      if (index > -1) {
        items.splice(index, 1);
      }
    },
    [items]
  );

  const closeMenu = useCallback(() => {
    if (!open) {
      return;
    }

    if (!isMultiSelect.current) {
      setOpen?.(false);
    }
  }, [open, isMultiSelect, setOpen]);

  const onTab = useCallback(() => {
    if (!open) {
      return;
    }

    setOpen?.(false);
  }, [open, setOpen]);

  useMenuNavigation({ items, onEnter: closeMenu, onSpace: closeMenu, onTab });

  return { closeMenu, addMenuItem, removeMenuItem, onAnimationStart, contentRef };
};

export default useContent;
