import { createPortal } from 'react-dom';
import { useCreateRootDivElement } from './hooks/useCreateRootDivElement';
import type { PortalProps } from './types';

const Portal = ({ children, containerClassName, containerElement, height }: PortalProps) => {
  const domElement = useCreateRootDivElement({
    className: containerClassName,
    shouldAppendToBody: !containerElement,
    style: { ...(height && { height }) },
  });

  if (containerElement) {
    return createPortal(children, containerElement);
  }

  return domElement ? createPortal(children, domElement) : null;
};

Portal.displayName = 'Portal';

export default Portal;
