import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';

export const DEFAULT_EXPERIMENT_FIELDS = {
    numOfGroups: 2,
    experimentType: EXPERIMENT_TYPE.GUEST,
    shouldAllocate: () => false,
    getRolloutResult: () => false,
};

export const ALLOCATIONS = {
    CONTROL: 1,
    TEST_B: 2,
    TEST_C: 3,
    TEST_D: 4,
};
