import React from 'react';
import { L2_ICON_SIZES } from '../../constants';
import type { L2IconType } from '../../types';

const ProSellerL2Icon = ({ size = 'sm' }: L2IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={L2_ICON_SIZES[size]}
    height={L2_ICON_SIZES[size]}
    fill="none"
    viewBox="0 0 48 48"
  >
    <g filter="url(#pro_seller_svg__a)">
      <path
        fill="#fff"
        d="M23.512 4c10.776 0 19.512 8.736 19.512 19.512s-8.736 19.512-19.512 19.512S4 34.288 4 23.512 12.736 4 23.512 4"
      />
      <path
        fill="#E4E5E7"
        fillRule="evenodd"
        d="M23.512 4.513c-10.492 0-18.999 8.506-18.999 19 0 10.492 8.507 18.998 19 18.998S42.51 34.005 42.51 23.512 34.005 4.513 23.512 4.513m19.512 19C43.024 12.735 34.288 4 23.512 4S4 12.736 4 23.512s8.736 19.512 19.512 19.512 19.512-8.736 19.512-19.512"
        clipRule="evenodd"
      />
      <path
        fill="url(#pro_seller_svg__b)"
        fillRule="evenodd"
        d="M34.642 35.6a16.39 16.39 0 0 0 5.301-12.088c0-9.075-7.356-16.431-16.43-16.431-9.076 0-16.432 7.356-16.432 16.431 0 4.782 2.042 9.086 5.302 12.088.922-4.345 5.554-7.65 11.13-7.65 5.574 0 10.206 3.305 11.129 7.65m-11.13-11.06a6.162 6.162 0 1 0 0-12.324 6.162 6.162 0 0 0 0 12.323"
        clipRule="evenodd"
      />
      <path fill="#fff" d="M35.707 27.415a8.293 8.293 0 1 1 0 16.585 8.293 8.293 0 0 1 0-16.585" />
      <path
        fill="#E4E5E7"
        fillRule="evenodd"
        d="M35.707 27.933a7.774 7.774 0 1 0 0 15.549 7.774 7.774 0 0 0 0-15.55M44 35.707a8.293 8.293 0 1 0-16.585 0 8.293 8.293 0 0 0 16.585 0"
        clipRule="evenodd"
      />
      <path
        fill="#222325"
        fillRule="evenodd"
        d="M36.598 30.844a1.4 1.4 0 0 0-1.781 0l-.824.675a1.4 1.4 0 0 1-.58.285l-1.033.235a1.44 1.44 0 0 0-1.11 1.418l.004 1.078c.001.221-.048.44-.143.64l-.463.968a1.464 1.464 0 0 0 .396 1.768l.83.669c.17.137.308.313.401.513l.456.972c.286.614.954.942 1.604.787l1.03-.244c.212-.05.433-.05.644 0l1.03.244a1.41 1.41 0 0 0 1.605-.786l.455-.973c.094-.2.231-.376.402-.513l.83-.669a1.464 1.464 0 0 0 .396-1.768l-.463-.969a1.5 1.5 0 0 1-.143-.64l.004-1.077c.003-.68-.46-1.27-1.11-1.418l-1.033-.235a1.4 1.4 0 0 1-.58-.285zm1.617 4.356a.55.55 0 0 0 0-.767.526.526 0 0 0-.754 0l-2.076 2.115-1.089-1.042a.526.526 0 0 0-.753.024.55.55 0 0 0 .024.767l1.465 1.402c.21.2.538.195.741-.012z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        id="pro_seller_svg__b"
        x1={35.053}
        x2={10.316}
        y1={46.632}
        y2={11.368}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BBCE00" />
        <stop offset={1} stopColor="#005C25" />
      </linearGradient>
      <filter
        id="pro_seller_svg__a"
        width={46}
        height={46}
        x={1}
        y={1}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_41849_22699" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_41849_22699" result="shape" />
      </filter>
    </defs>
  </svg>
);
ProSellerL2Icon.displayName = 'ProSellerL2Icon';
export default ProSellerL2Icon;
