import React from 'react';
import { Animate } from 'react-move';
import { renderChildren } from '@fiverr-private/ui_utils';
import type { FadeOverrideProps, TransitionProps } from '../../types';
import { useTransitionEvents } from '../../hooks';
import variants from './variants';

const Fade = ({
  delay,
  duration = 'fast2',
  easing = 'linear',
  unmountOnExit,
  in: isOpen = true,
  endingOpacity,
  onAnimationStart,
  onAnimationEnd,
  children,
}: TransitionProps & FadeOverrideProps) => {
  const custom = { delay, duration, easing, endingOpacity };

  const transitionConfig = isOpen ? variants.enter(custom) : variants.exit(custom);

  const { getEventsHandler } = useTransitionEvents({ onAnimationStart, onAnimationEnd, isOpen, unmountOnExit });

  return (
    <Animate
      show={unmountOnExit ? isOpen : true}
      start={variants.exit(custom)}
      enter={{
        ...transitionConfig,
        ...getEventsHandler('enter'),
      }}
      update={{
        ...transitionConfig,
        ...getEventsHandler('update'),
      }}
      leave={{
        ...variants.exit(custom),
        ...getEventsHandler('leave'),
      }}
    >
      {(animationState) =>
        renderChildren(children, {
          style: {
            ...(children.props.style || {}),
            display: animationState.opacity === 0 ? 'none' : undefined,
            visibility: animationState.opacity === 0 ? 'hidden' : 'visible',
            ...animationState,
          },
        })
      }
    </Animate>
  );
};

Fade.displayName = 'Fade';

export default Fade;
