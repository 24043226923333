import { pushEvent } from '../services/dataLayer';
import { Event } from './types';

/**
 *  On submit of search form
 */
export const searched = () => {
    pushEvent({
        event: Event.searched,
    });
};
