import { generateCssProperties, theme } from '@fiverr-private/theme';
import type { LayoutProps } from '../Layout/types';
import { BORDERS, DIMENSION } from './constants';
import type { DividerProps } from './types';

export const getLineStylingProps = ({
    direction = { default: 'horizontal' },
    borderWidth,
    borderStyle,
    borderColor,
    height,
    width,
}: Pick<
    DividerProps,
    'direction' | 'borderWidth' | 'borderStyle' | 'borderColor' | 'height' | 'width'
>): LayoutProps => {
    const borderProps = generateCssProperties(direction, BORDERS, borderWidth, '0');
    const dimensionProps = generateCssProperties(direction, DIMENSION, '100%', '0');
    return {
        ...borderProps,
        ...dimensionProps,
        width: width || dimensionProps.width,
        height: height || dimensionProps.height,
        borderStyle,
        borderColor,
    };
};

export const getDotStylingProps = ({
    height = '4px',
    width = '4px',
    borderColor,
}: Pick<DividerProps, 'height' | 'width' | 'borderColor'>): LayoutProps => ({
    height,
    width,
    backgroundColor: borderColor === 'border' ? theme.semanticBorderColors.border : borderColor,
    borderRadius: 'circle',
    alignSelf: 'center',
});
