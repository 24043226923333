export const BORDERS = {
    horizontal: 'borderTopWidth',
    vertical: 'borderLeftWidth',
};

export const DIMENSION = {
    horizontal: 'width',
    vertical: 'height',
};

export const DIRECTION = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
} as const;
