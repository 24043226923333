import { string, bool, object, oneOfType, arrayOf, func } from 'prop-types';

/**
 * The component's prop types.
 * @type {Object}
 */
export default {
    /**
     * The translation key to use.
     */
    k: oneOfType([string, arrayOf(string)]).isRequired,

    /**
     * The locale to translate to.
     */
    locale: string,

    /**
     * Templates that should be injected into the translation.
     */
    templates: arrayOf(func),

    /**
     * Interpolated parameters object.
     */
    params: object,

    /**
     * Indicates whether the translation strings contains markup to be parsed out.
     */
    parseMarkup: bool,
};
