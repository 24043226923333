import { get } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { logger } from '@fiverr-private/obs';
import { DEFAULT_LOCALIZATION_CONTEXT, LocalizationContext } from '../shared/constants';
import { LOCALIZATION_CONTEXT_DOES_NOT_EXIST } from './constants';

const errorLoggedKey = Symbol();

/**
 * Returns the current available localization context for browser run time environment.
 * @returns {Object} - localization context object
 */
export const getLocalizationContext = () => {
    const { computedLocale } = getContext();
    let localizationContext;

    if (computedLocale) {
        localizationContext = {
            formattingLocale: computedLocale,
        };
    } else {
        localizationContext = get(window, 'initialData.LocalizationContext');
    }

    if (!localizationContext) {
        // We would like to log error in the browser only once per page view
        if (window && !window[errorLoggedKey]) {
            window[errorLoggedKey] = true;
            logger.error({ message: LOCALIZATION_CONTEXT_DOES_NOT_EXIST });
        }

        return DEFAULT_LOCALIZATION_CONTEXT;
    }

    return localizationContext;
};

/**
 * Wraps a function and injects dynamically the localization context (browser run time)
 * as the first parameter. Based on FiverrContext or LocalizationContext
 * @param baseFunc {Function} - the function to wrap
 * @returns {Function} - localizationContext wrapped function
 */
export const withBrowserContext =
    <T extends LocalizationContext, K>(baseFunc: (args: T) => K) =>
    (args: Omit<T, 'localizationContext'>): K => {
        const localizationContext = getLocalizationContext();
        return baseFunc({ localizationContext, ...args } as T);
    };
