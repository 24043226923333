import React from 'react';
import { Animate } from 'react-move';
import { renderChildren } from '@fiverr-private/ui_utils';
import type { SlideFadeProps, TransitionProps } from '../../types';
import { useTransitionEvents } from '../../hooks';
import variants from './variants';

const SlideFade = ({
  reverse = true,
  offsetX = 'none',
  offsetY = 'sm',
  delay,
  duration = 'moderate1',
  easing = { enter: 'easeIn', exit: 'easeOut' },
  unmountOnExit,
  in: isOpen,
  onAnimationStart,
  onAnimationEnd,
  children,
}: TransitionProps & SlideFadeProps) => {
  const custom = { offsetX, offsetY, reverse, delay, duration, easing };
  const transitionConfig = isOpen ? variants.enter(custom) : variants.exit(custom);
  const { getEventsHandler } = useTransitionEvents({
    onAnimationStart,
    onAnimationEnd,
    isOpen: !!isOpen,
    unmountOnExit,
  });

  return (
    <Animate
      show={unmountOnExit ? isOpen : true}
      start={variants.exit(custom)}
      enter={{
        ...transitionConfig,
        ...getEventsHandler('enter'),
      }}
      update={{
        ...transitionConfig,
        ...getEventsHandler('update'),
      }}
      leave={{
        ...variants.exit(custom),
        ...getEventsHandler('leave'),
      }}
    >
      {({ x, y, ...rest }) =>
        renderChildren(children, {
          style: {
            ...(children.props.style || {}),
            ...rest,
            display: rest.opacity === 0 || (!reverse && !isOpen) ? 'none' : undefined,
            transform: isOpen || reverse ? `translate(${x}px,${y}px)` : undefined,
          },
        })
      }
    </Animate>
  );
};

SlideFade.displayName = 'SlideFade';

export default SlideFade;
