import React, { forwardRef } from 'react';
import Typography from '../Typography';
import { TextProps } from '../Typography/types';

const Text = forwardRef<HTMLElement, TextProps>(
  (
    {
      as = 'p',
      color = 'bodyPrimary',
      fontWeight = 'normal',
      size = { default: 'b_md' },
      fontFamily,
      children,
      textOverflow,
      ...restProps
    }: TextProps,
    ref
  ) => (
    <Typography
      ref={ref}
      as={as}
      size={size}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      textOverflow={textOverflow}
      color={color}
      {...restProps}
    >
      {children}
    </Typography>
  )
);
Text.displayName = 'Text';

export default Text;
