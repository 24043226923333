import React, { forwardRef, ReactNode } from 'react';
import { mapResponsiveStyles, Types } from '@fiverr-private/theme';
import Flex from '../Flex';
import Divider from '../Divider';
import { DividerDirection } from '../Divider/types';
import { PROPERTIES } from './constants';
import { StackProps } from './types';

const Stack = forwardRef<HTMLElement, StackProps>(
  ({ children, withDivider, direction, dividerProps = {}, gap = { default: '3' }, ...restProps }: StackProps, ref) => {
    const defaultDirection: Types.ResponsiveFlexDirectionType = 'row';
    const dividerResponsiveDirection =
      mapResponsiveStyles(direction || defaultDirection, (value = defaultDirection) => PROPERTIES[value]) ||
      PROPERTIES[direction as DividerDirection];

    const childrenArray = Array.isArray(children) && React.Children.toArray(children);
    const childrenLength = childrenArray && childrenArray.length;

    const withDividerChildren = (childrenElements: ReactNode, idx: number) => (
      <>
        {childrenElements}
        {idx < Number(childrenLength) - 1 ? (
          <Divider
            direction={dividerResponsiveDirection}
            height={dividerProps.variant === 'dot' ? undefined : 'auto'}
            {...dividerProps}
          />
        ) : null}
      </>
    );

    return (
      <Flex ref={ref} direction={direction} gap={gap} {...restProps}>
        {withDivider && childrenArray ? React.Children.map(childrenArray, withDividerChildren) : children}
      </Flex>
    );
  }
);

Stack.displayName = 'Stack';

export default Stack;
