import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { SearchBar } from '@fiverr-private/search_bar';
import { abTests, localeSettings, user } from '../../PropTypes';

import './style.scss';

const SearchWrapper = (
    { className, dimBackgroundEnabled = true, theme = 'dark', ...props },
    { locale, abTests, user = {}, currency = {} }
) => (
    <div className={classNames('fiverr-header-search', className)}>
        <SearchBar
            source="top-bar"
            locale={locale}
            userId={user.id}
            abTests={abTests}
            currency={currency}
            theme={theme}
            dimBackgroundEnabled={dimBackgroundEnabled}
            {...props}
        />
    </div>
);

SearchWrapper.propTypes = {
    className: string,
    theme: string,
    dimBackgroundEnabled: bool,
};

SearchWrapper.contextTypes = {
    locale: localeSettings.locale,
    abTests,
    user,
    currency: localeSettings.currency,
};

export default SearchWrapper;
