import { SET_HISTORY, CLEAR_HISTORY } from '../../actions';

const history = (state = null, action) => {
    switch (action.type) {
        case SET_HISTORY:
            return action.payload;
        case CLEAR_HISTORY:
            return {};
        default:
            return state;
    }
};

export default history;
