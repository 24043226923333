import { useRef } from 'react';
import type { TransitionEventType } from '../types';

interface UseTransitionEvents {
  isOpen: boolean;
  onAnimationStart?: () => void;
  onAnimationEnd?: () => void;
  unmountOnExit?: boolean;
}

const useTransitionEvents = ({
  isOpen,
  onAnimationStart,
  onAnimationEnd,
  unmountOnExit = false,
}: UseTransitionEvents) => {
  const hasOpened = useRef(isOpen);
  const startState = useRef<undefined | boolean>(undefined);
  const endState = useRef<undefined | boolean>(undefined);

  const start = (type: TransitionEventType) => {
    if (startState.current === isOpen) {
      return;
    }
    switch (type) {
      case 'enter':
        if (isOpen) {
          onAnimationStart?.();
        }
        break;

      case 'update':
        if (isOpen || (!unmountOnExit && hasOpened.current)) {
          onAnimationStart?.();
        }
        break;

      case 'leave':
        onAnimationStart?.();
        break;

      default:
        break;
    }
    startState.current = isOpen;
  };

  const end = (type: TransitionEventType) => {
    if (endState.current === isOpen) {
      return;
    }
    switch (type) {
      case 'enter':
        if (isOpen) {
          onAnimationEnd?.();
          hasOpened.current = true;
        }
        break;

      case 'update':
        if (isOpen) {
          hasOpened.current = true;
        }
        if (isOpen || (!unmountOnExit && hasOpened.current)) {
          onAnimationEnd?.();
        }
        break;

      case 'leave':
        onAnimationEnd?.();
        break;

      default:
        break;
    }
    endState.current = isOpen;
  };

  const getEventsHandler = (type: TransitionEventType) =>
    onAnimationStart || onAnimationEnd
      ? {
          events: {
            ...(onAnimationStart ? { start: () => start(type) } : {}),
            ...(onAnimationEnd ? { end: () => end(type) } : {}),
          },
        }
      : {};

  return {
    getEventsHandler,
  };
};

export default useTransitionEvents;
