import React from 'react';
import { bool, func } from 'prop-types';

import { rollouts } from '../../../../PropTypes';

import { useDolphinsFibLogoutMenusExperimentAllocation } from './useDolphinsFibLogoutMenusExperimentAllocation';
import { ProDropdown } from './ProDropdown';
import { FiverrProButton } from './FiverrProButton';

export const FiverrPro = (
    { isTransparent, onOpen },
    { rollouts: { inDolphinsProLohpDropdownMenuRollout: inRollout } }
) => {
    const { inControlGroup, inGroupB, inGroupC } = useDolphinsFibLogoutMenusExperimentAllocation({ inRollout });

    const commonDropdownProps = {
        isTransparent,
        onOpen,
    };

    let loggedOutContent = <ProDropdown {...commonDropdownProps} menuType="default" />;

    if (inControlGroup) {
        loggedOutContent = <ProDropdown {...commonDropdownProps} menuType="default" />;
    } else if (inGroupC) {
        loggedOutContent = <ProDropdown {...commonDropdownProps} menuType="extended" />;
    } else if (inGroupB) {
        loggedOutContent = <FiverrProButton />;
    }

    return loggedOutContent;
};

FiverrPro.propTypes = {
    isTransparent: bool,
    onOpen: func,
};

FiverrPro.contextTypes = {
    rollouts,
};
