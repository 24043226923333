import pathfinder from '@fiverr-private/pathfinder';

export const LOGGED_OUT = 'LoggedOut';
export const BUYER = 'Buyer';
export const LEAN = 'Lean';
export const BUSINESS_SOLUTIONS = 'BusinessSolutions';
export const BUSINESS_PRO = 'BusinessPro';
export const BUSINESS_PRO_LIHP = 'BusinessProLihp';
export const BUSINESS_PRO_VISITOR = 'BusinessProVisitor';
export const BUSINESS_PRO_VISITOR_LOGGED_OUT = 'BusinessProVisitorLoggedOut';
export const BUSINESS_PRO_VISITOR_CATALOG = 'BusinessProVisitorCatalog';
export const BUSINESS_PRO_VISITOR_CATALOG_LOGGED_OUT = 'BusinessProVisitorCatalogLoggedOut';
export const CERTIFIED = 'Certified';
export const CERTIFIED_HOMEPAGE = 'CertifiedHomepage';
export const LOGGED_OUT_CERTIFIED = 'LoggedOutCertified';
export const LOGGED_OUT_CERTIFIED_HOMEPAGE = 'LoggedOutCertifiedHomepage';
export const LEAN_PRO_MARKETING = 'LeanProMarketing';
export const LEAN_BUSINESS = 'LeanBusiness';
export const LEAN_PRO = 'LeanPro';
export const SELLER = 'Seller';
export const SELLER_PRO = 'SellerPro';
export const LOGGED_OUT_HOMEPAGE = 'LoggedOutHomepage';
export const LOGGED_OUT_HOMEPAGE_GERMAN = 'LoggedOutHomepageGerman';
export const LOGO_MAKER = 'LogoMaker';
export const LOGO_MAKER_LP = 'LogoMakerLP';
export const LOGGED_OUT_LOGO_MAKER = 'LoggedOutLogoMaker';
export const LOGGED_OUT_LOGO_MAKER_LP = 'LoggedOutLogoMakerLP';
export const TEAM_ACCCOUNT_MEMBER = 'TeamAccountMember';
export const TEAM_ACCCOUNT_ADMIN = 'TeamAccountAdmin';
export const TYPES = {
    LOGGED_OUT,
    BUYER,
    BUSINESS_SOLUTIONS,
    BUSINESS_PRO,
    BUSINESS_PRO_LIHP,
    BUSINESS_PRO_VISITOR,
    BUSINESS_PRO_VISITOR_LOGGED_OUT,
    BUSINESS_PRO_VISITOR_CATALOG,
    BUSINESS_PRO_VISITOR_CATALOG_LOGGED_OUT,
    LEAN_BUSINESS,
    LEAN,
    LEAN_PRO,
    LEAN_PRO_MARKETING,
    SELLER,
    SELLER_PRO,
    LOGGED_OUT_HOMEPAGE,
    LOGO_MAKER,
    LOGO_MAKER_LP,
    LOGGED_OUT_LOGO_MAKER,
    LOGGED_OUT_LOGO_MAKER_LP,
    CERTIFIED,
    CERTIFIED_HOMEPAGE,
    LOGGED_OUT_CERTIFIED,
    LOGGED_OUT_CERTIFIED_HOMEPAGE,
    LOGGED_OUT_HOMEPAGE_GERMAN,
    TEAM_ACCCOUNT_MEMBER,
    TEAM_ACCCOUNT_ADMIN,
};

export const TOP_NAV_SOURCE = 'top_nav';
export const TOP_BAR_SOURCE = 'top_bar';
export const HEADER_POPUP_SOURCE = 'header_pop_up';
export const HEADER_NAV_SOURCE = 'header_nav';
export const HEADER_SOURCE = 'user_menu';
export const HEADER_BUSINESS_SOURCE = 'header_business_navigation';
export const LOGO_MAKER_SOURCE = 'logo_maker';
export const LOGO_MAKER_NAV_SOURCE = 'logo_maker';
export const PACKAGE_CLASSNAME = 'header-package';

export const BODY_SIDEBAR_SHOWN_CLASS = 'menu-shown';
export const LG_DISPLAY_WIDTH = 1160;

export const LOGO_BASE_CLASS = 'site-logo';
export const LOGO_BASE_PATH = pathfinder(
    'root',
    {},
    {
        query: {
            source: TOP_NAV_SOURCE,
        },
    }
);

/**
 * Group name for the header BigQuery events.
 */
export const BIGQUERY_GROUP = 'header_clicks';

export const INTEGRATIONS = {
    WORDPRESS: 'wordpress',
    W3SCHOOLS: 'w3schools',
    ADALO: 'adalo',
    ELEMENTOR: 'elementor',
    WOO: 'woo',
    NONE: 'none',
} as const;

export const SELLER_PLUS_STATUS = {
    ENROLLED: 'ENROLLED',
    ELIGIBLE: 'ELIGIBLE',
    NONE: 'NONE',
};

export const SELLER_PLUS_TIER = {
    STANDARD: 'STANDARD',
    PREMIUM: 'PREMIUM',
};

export const KICKSTART_STATUS = {
    ENROLLED: 'ENROLLED',
    ELIGIBLE: 'ELIGIBLE',
};

/**
 * Prefix for Grafana stats metric.
 */
export const STATS_METRIC_PREFIX_PACKAGE_NAME = 'packages.header';

export const LOGO_MAKER_TRANSLATION_PREFIX = 'header.logo_maker';
