import { useEffect, useCallback, useState } from 'react';
import { isNil } from 'lodash';
import { EVENTS } from './constants';
import { Shortcut } from './types';
import { getDefaultTarget, getObservedShortcut, mapShortcutsToKeys, shouldTrigger } from './utils';

// Listens for keyboard shortcuts for a given target
const useKeyboardShortcuts = (
    // The shortcuts to listen to
    shortcuts: Shortcut[],

    // A function that should return the HTMLElement target to listen to, defaults to document body
    getTarget?: () => HTMLElement | null,

    options: {
        // Whether to useCapture on the key down / up listeners or not
        // @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
        useCapture?: boolean;

        // Whether to support long press of a shortcut
        allowRepeat?: boolean;
    } = {}
) => {
    const [keys, setKeys] = useState<string[]>([]);
    const { useCapture = false, allowRepeat = false } = options;

    const keyDownHandler = useCallback(
        (keyDownEvent: KeyboardEvent) => {
            const { key, code, target, repeat } = keyDownEvent;

            if (isNil(key) && isNil(code)) {
                return;
            }

            const loweredKey = key.toLowerCase();
            const loweredCode = code.toLowerCase();

            const shortcutKeys = mapShortcutsToKeys(shortcuts);

            const observedShortcut = getObservedShortcut({
                key: loweredKey,
                code: loweredCode,
                target: target as HTMLElement,
                repeat,
                allowRepeat,
                shortcutKeys,
            });

            if (!observedShortcut) {
                return;
            }

            const pressedKeys = [...keys];

            if (!repeat || !allowRepeat) {
                pressedKeys.push(observedShortcut);
            }

            shortcuts.forEach((shortcut) => {
                const shortcutOwnKeys = mapShortcutsToKeys([shortcut]);

                if (shouldTrigger(shortcutOwnKeys, pressedKeys)) {
                    shortcut.handler(keyDownEvent);
                }
            });

            setKeys(pressedKeys);
        },
        [shortcuts, keys, allowRepeat]
    );

    const keyUpHandler = useCallback(
        ({ key, code, target }: KeyboardEvent) => {
            if (isNil(key) && isNil(code)) {
                return;
            }

            const loweredKey = key.toLowerCase();
            const loweredCode = code.toLowerCase();

            const shortcutKeys = mapShortcutsToKeys(shortcuts);

            const observedShortcut = getObservedShortcut({
                key: loweredKey,
                code: loweredCode,
                target: target as HTMLElement,
                shortcutKeys,
            });

            if (!observedShortcut) {
                return;
            }

            setKeys(keys.filter((k) => k !== observedShortcut));
        },
        [shortcuts, keys]
    );

    useEffect(() => {
        const listenerTarget = (getTarget && getTarget()) || getDefaultTarget();

        listenerTarget.addEventListener(
            EVENTS.KEYDOWN,
            keyDownHandler as EventListenerOrEventListenerObject,
            useCapture
        );
        return () =>
            listenerTarget.removeEventListener(
                EVENTS.KEYDOWN,
                keyDownHandler as EventListenerOrEventListenerObject,
                useCapture
            );
    }, [keyDownHandler, getTarget, useCapture]);

    useEffect(() => {
        const listenerTarget = (getTarget && getTarget()) || getDefaultTarget();

        listenerTarget.addEventListener(EVENTS.KEYUP, keyUpHandler as EventListenerOrEventListenerObject, useCapture);
        return () =>
            listenerTarget.removeEventListener(
                EVENTS.KEYUP,
                keyUpHandler as EventListenerOrEventListenerObject,
                useCapture
            );
    }, [keyUpHandler, getTarget, useCapture]);
};

export default useKeyboardShortcuts;
