import React from 'react';
import { logger } from '@fiverr-private/obs';
import { TEMPLATE_REGEX, INVALID_NUMBER_OF_TEMPLATES, INVALID_TEMPLATE_TYPE } from './constants';

/**
 * Logs an error message when an invalid template type was used.
 * @param {*} template The template used.
 */
const logInvalidTemplateError = (template) => {
    logger.error([INVALID_TEMPLATE_TYPE, typeof template, `(${JSON.stringify(template)})`].join(' '));
};

/**
 * Checks whether a given function is actually a function.
 * @param {Function} fn The function to check.
 * @return {Boolean}
 */
const isFunction = (fn) => typeof fn === 'function';

/**
 * A simple render function factory for wrapping plain text.
 * @param {String} text The text to wrap.
 * @return {React.Component}
 */
const plainTextFactory = (text) => () => <>{text}</>; // eslint-disable-line react/display-name

/**
 * A render function factory for wrapping text with templates.
 * @param {String} text The text to wrap.
 * @param {Function} fn The template to render.
 * @return {React.Component}
 */
const templateFactory = (text, fn) => () => <>{fn(text)}</>; // eslint-disable-line react/display-name

/**
 * Validates that the passed templates:
 * 1. Match the number of patterns.
 * 2. Are functions.
 * @param {String[]} translationParts The pattern regex matches.
 * @param {Function[]} templates The templates passed.
 */
const validateTemplates = (translationParts, templates) => {
    const numberOfTemplates = templates.length;
    const numberOfTemplateDeclarations = (translationParts.length - 1) / 2;

    if (numberOfTemplateDeclarations !== numberOfTemplates) {
        logger.error(INVALID_NUMBER_OF_TEMPLATES, {
            numberOfTemplates,
            numberOfTemplateDeclarations,
        });
    }

    templates.filter((template) => !isFunction(template)).forEach(logInvalidTemplateError);
};

/**
 * Finds all template patterns and wraps them with the template (component) that matches its index,
 * while cleaning up the templates' declaration symbols.
 * @param {String} translation The translation into which the templates will be injected.
 * @param {Function[]} templates The templates that will be injected.
 * @return {React.Component}
 */
const injectTemplates = (translation, templates) => {
    const translationParts = translation.split(TEMPLATE_REGEX);

    validateTemplates(translationParts, templates);

    const wrappers = translationParts.map((translationPart, index) => {
        const currentTemplate = templates[(index - 1) / 2];
        const isTemplate = index % 2 === 1; // Every second item is a match (a template)

        if (!isTemplate || !currentTemplate || !isFunction(currentTemplate)) {
            return plainTextFactory(translationPart);
        }

        return templateFactory(translationPart, currentTemplate);
    });

    return (
        <>
            {wrappers.map((Wrapper, index) => (
                <Wrapper key={index} />
            ))}
        </>
    );
};

export default injectTemplates;
