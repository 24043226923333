import * as actions from '../../actions';

const suggestions = (state = {}, action) => {
    switch (action.type) {
        case actions.SET_SUGGESTIONS:
            return action.payload.suggestions;
        case actions.UNSET_SUGGESTIONS:
            return action.payload.ids.reduce((acc, id) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [id]: deletedValue, ...newState } = acc;

                return newState;
            }, state);
        default:
            return state;
    }
};

export default suggestions;
