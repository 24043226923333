import { sortBy } from 'lodash';
import * as SearchSelector from '../Search';
import { trimAndEscapeForRegExp } from '../../utils';

export const USERS_SEARCH = 'users';
export const USER_SEARCH = 'user';
export const HISTORY_SEARCH = 'history';
export const GIGS_SEARCH = 'gig';
export const POPULAR_SEARCH = 'popular';

export const suggestionById = (state, suggestionId) => {
    if (!state) {
        return {};
    }

    if (!suggestionId) {
        return {};
    }

    const { suggestions, selectedSuggestionId } = state;
    if (!suggestions) {
        return {};
    }

    if (selectedSuggestionId === USERS_SEARCH && suggestionId === USERS_SEARCH) {
        return {
            id: USERS_SEARCH,
            group: USER_SEARCH,
            isSelected: true,
        };
    }

    const suggestion = suggestions[suggestionId];
    if (!suggestion) {
        return {};
    }

    suggestion.isSelected = suggestionId === selectedSuggestionId;

    return suggestion;
};

export const groupSuggestionIds = (state = {}, group) => {
    if (!group) {
        return [];
    }

    if (!state) {
        return [];
    }

    if (!state.suggestions) {
        return [];
    }

    return sortBy(Object.values(state.suggestions), ['order'])
        .filter((suggestion) => suggestion.group === group)
        .map(({ id }) => id);
};

export const historySuggestionIds = (state) => {
    const search = SearchSelector.search(state);
    const searchRegExp = new RegExp(trimAndEscapeForRegExp(search));

    return groupSuggestionIds(state, HISTORY_SEARCH)
        .map((suggestionId) => suggestionById(state, suggestionId))
        .filter(({ value }) => !search || searchRegExp.test(value))
        .map(({ id }) => id);
};

export const promotedGigsSuggestions = (state) => {
    if (!state || !state.promotedGigsSuggestions || !state.promotedGigsSuggestions.gigs) {
        return [];
    }

    return state.promotedGigsSuggestions.gigs;
};
export const promotedGigsSubCategoryConfig = (state) => {
    if (!state || !state.promotedGigsSuggestions || !state.promotedGigsSuggestions.subCategoryConfig) {
        return {};
    }

    return state.promotedGigsSuggestions.subCategoryConfig;
};

export const gigSuggestionIds = (state) => groupSuggestionIds(state, GIGS_SEARCH);

export const userSuggestionIds = (state) => groupSuggestionIds(state, USER_SEARCH);

export const orderedSuggestionIds = (state) => [
    ...historySuggestionIds(state),
    ...(SearchSelector.isSearch(state) ? [] : popularSuggestionIds(state)),
    ...gigSuggestionIds(state),
    USERS_SEARCH,
    ...userSuggestionIds(state),
];

export const selectedSuggestionId = (state = {}) => {
    const { selectedSuggestionId = '' } = state;

    return selectedSuggestionId;
};

export const isHistory = (state) => !!historySuggestionIds(state).length;

export const selectedSuggestion = (state) => suggestionById(state, selectedSuggestionId(state));

export const selectedSuggestionPosition = (state = {}) =>
    orderedSuggestionIds(state).indexOf(selectedSuggestionId(state));

export const popularSuggestionIds = (state) => groupSuggestionIds(state, POPULAR_SEARCH);

export const isPopular = (state) => !!popularSuggestionIds(state).length;
