import { Click, ClickType, ElementType } from '@fiverr-private/bianka_js';
import { bianka } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { Buttons } from './types';

const CtaButtonClickedEvent = {
    [Buttons.POST_A_BRIEF]: {
        EVENT_TYPE: 'clicked_on_post_a_brief_cta',
        ELEMENT_NAME: 'post_a_brief_cta',
        ELEMENT_TEXT: 'brief',
    },
    [Buttons.EXPERT_SOURCING]: {
        EVENT_TYPE: 'clicked_on_expert_sourcing_cta',
        ELEMENT_NAME: 'expert_sourcing_cta',
        ELEMENT_TEXT: 'sourcing',
    },
};

export const onRoutingButtonClick = (source, selectedButton) => {
    const { pageCtxId, organizationId } = getContext();

    bianka.reportActivity({
        type: Click,
        event: {
            type: CtaButtonClickedEvent[selectedButton].EVENT_TYPE,
            page: { name: source, ctxId: pageCtxId },
            ...(organizationId && { organization: { id: organizationId } }),
            clickType: ClickType.UNSPECIFIED,
            element: {
                type: ElementType.BUTTON,
                name: CtaButtonClickedEvent[selectedButton].ELEMENT_NAME,
                text: {
                    value: CtaButtonClickedEvent[selectedButton].ELEMENT_TEXT,
                },
            },
        },
    });
};
