import { ReactElement } from 'react';
import { filterChildrenByType } from '@fiverr-private/ui_utils';

export const getIsMultiSelect = (children) => {
    const menuGroups = (filterChildrenByType(children, 'MenuGroup') || []) as ReactElement[];

    if (menuGroups.length > 1 || menuGroups.some((group) => group.props.isMulti === true)) {
        return true;
    }

    return false;
};

export const focusContent = ({ items, setFocusedItem, values }) => {
    const currentHoveredItem = items.find((item) => !item.disabled && item.ref.current?.matches?.(':hover'));
    if (currentHoveredItem) {
        setFocusedItem(currentHoveredItem.ref);
        return;
    }

    const hasSelectedItems = Boolean(Object.keys(values).length);
    if (hasSelectedItems) {
        const firstSelectedItemIdx = items.findIndex((item) =>
            Array.isArray(values[item.name])
                ? (values[item.name] as string[]).includes(item.value as string)
                : values[item.name] === item.value
        );
        setFocusedItem(items[firstSelectedItemIdx]?.ref);
    } else {
        const firstItem = items.find((item) => !item.disabled);
        if (firstItem?.ref) {
            setFocusedItem(firstItem?.ref);
        }
    }
};
