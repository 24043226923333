import { bianka, BIANKA_ACTIVITY_TYPES, mixpanel } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { Click, ClickType, ElementType } from '@fiverr-private/bianka_js';
import { ClickedOnLayoutProMenus } from '@fiverr-private/bianka_js/protobuf/bianka/event/activity/v2/extension/layout_menus/clicked_on_pro_menus';
import { ComponentName } from '@fiverr-private/bianka_js/protobuf/bianka/event/activity/v2/extension/layout_menus/common';

const COMPONENT_NAME = {
    products: ComponentName.PRODUCTS,
    resources: ComponentName.RESOURCES,
};

type EventTypeName = string;

const sendMixpanelEvent = (eventType: EventTypeName) => mixpanel.track(`pro_lo_header - ${eventType}`, {});

const clickedBiEvent = (data: {
    eventType: EventTypeName;
    elementName: string;
    elementType: 'link' | 'button';
    componentName: 'products' | 'resources' | 'pro_dropdown' | null;
}) => {
    const { userId, pageCtxId } = getContext();

    bianka.reportActivity<Click>({
        type: BIANKA_ACTIVITY_TYPES.CLICK,
        event: {
            type: data.eventType,
            clickType: ClickType.LEFT,
            page: {
                name: 'business_lohp',
                ctxId: pageCtxId,
            },
            user: {
                id: userId as number,
            },
            element: {
                name: data.elementName,
                type: data.elementType === 'button' ? ElementType.BUTTON : ElementType.LINK,
            },
        },
        additionalData: {
            type: ClickedOnLayoutProMenus,
            message: {
                component: {
                    name: data.componentName
                        ? COMPONENT_NAME[data.componentName] || ComponentName.UNSPECIFIED
                        : ComponentName.UNSPECIFIED,
                },
            },
        },
    });
};

export const eventsApi = {
    clickedOnButton: (data: Omit<Parameters<typeof clickedBiEvent>[0], 'elementType'>) => {
        sendMixpanelEvent(data.eventType);
        clickedBiEvent({
            ...data,
            elementType: 'button',
        });
    },
    clickedOnLink: (data: Omit<Parameters<typeof clickedBiEvent>[0], 'elementType'>) => {
        sendMixpanelEvent(data.eventType);
        clickedBiEvent({
            ...data,
            elementType: 'link',
        });
    },
};
