import React from 'react';
import PropTypes from 'prop-types';

const PersonIcon = ({ size, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 14 14">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.00001 6C8.10457 6 9 5.10458 9 3.99999C9 2.89543 8.10458 2 7.00001 2C5.89543 2 5 2.89543 5 3.99999C5 5.10457 5.89543 6 7.00001 6ZM7.00001 7.5C8.933 7.5 10.5 5.933 10.5 3.99999C10.5 2.067 8.933 0.5 7.00001 0.5C5.06701 0.5 3.5 2.067 3.5 3.99999C3.5 5.933 5.06701 7.5 7.00001 7.5Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.04132 12C2.20623 11.5337 3.02069 10 7.00015 10C10.9793 10 11.7938 11.5337 11.9587 12H2.04132ZM7.00015 8.5C1.54721 8.5 0.5 11.2431 0.5 12.1231C0.5 13.0017 1.21044 13.5 1.87399 13.5H12.1262C12.7893 13.5 13.5 13.0021 13.5 12.1231C13.5 11.2431 12.4527 8.5 7.00015 8.5Z"
        />
    </svg>
);

PersonIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

PersonIcon.displayName = 'PersonIcon';
export default PersonIcon;
