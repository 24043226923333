import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Dropdown, DropdownAnchor, DropdownContent } from '@fiverr-private/dropdown';
import { user, abTests, rollouts } from '../../../../PropTypes';
import { DropdownTriggerButton } from '../../../DropdownTriggerButton';
import { getBQData } from '../../../../utils/business/bi-utils';
import { reportMixpanelClick } from '../../../../utils/reporter';

import { DefaultMenu } from './DefaultMenu';
import { ExtendedMenu } from './ExtendedMenu';

const T_PREFIX = 'header.fiverr_pro_entry';

type MenuType = 'default' | 'extended';

interface ProDropdownProps {
    isTransparent: boolean;
    onOpen: () => void;
    menuType: MenuType;
}

export const ProDropdown: React.FC<ProDropdownProps> = ({ isTransparent, onOpen, menuType }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen && menuType === 'extended') {
            reportMixpanelClick({
                eventName: 'Pro button on fiverr header menu - dropdown impression',
            });
        }
    }, [isOpen, menuType]);

    let menu;

    switch (menuType) {
        case 'default':
            menu = <DefaultMenu />;
            break;
        case 'extended':
            menu = <ExtendedMenu />;
            break;

        default:
            menu = <DefaultMenu />;
            break;
    }

    const onMenuOpen = () => {
        if (!isOpen) {
            onOpen?.();
        }

        setIsOpen(!isOpen);
    };
    return (
        <Dropdown position="bottom-end" isOpen={isOpen} onOpenChanged={onMenuOpen}>
            <DropdownAnchor embed>
                <DropdownTriggerButton
                    title={<I18n k={`${T_PREFIX}.title`} />}
                    bigQueryData={getBQData({
                        type: menuType === 'extended' ? 'open_menu_fiverr_pro_extended' : 'open_menu_fiverr_pro',
                        component: { name: 'Header' },
                    })}
                    isOpen={isOpen}
                    onClick={onMenuOpen}
                    isTransparent={isTransparent}
                />
            </DropdownAnchor>
            <DropdownContent closeOnClickOutside>{menu}</DropdownContent>
        </Dropdown>
    );
};

ProDropdown.propTypes = {
    isTransparent: bool.isRequired,
    onOpen: func.isRequired,
};

ProDropdown.contextTypes = {
    abTests,
    user,
    rollouts,
};
