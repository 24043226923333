import React, { useEffect, useState } from 'react';
import { RoundedSubmitButton } from './RoundedSubmitButton';
import RoutingButtons from './RoutingButtons';
import { ROUTING_LAYOUT_ANIMATION_DURATION, useRoutingSearchBarContext } from './lib';

export const RoutingLayout = () => {
    const { isSearchOnly } = useRoutingSearchBarContext();
    const [shouldRenderRoutingLayout, setShouldRenderRoutingLayout] = useState(!isSearchOnly);

    useEffect(() => {
        let timeout;
        if (isSearchOnly) {
            timeout = setTimeout(() => setShouldRenderRoutingLayout(false), ROUTING_LAYOUT_ANIMATION_DURATION);
        } else {
            setShouldRenderRoutingLayout(true);
        }
        return () => clearTimeout(timeout);
    }, [isSearchOnly]);

    return (
        <>
            <RoundedSubmitButton />
            {shouldRenderRoutingLayout && <RoutingButtons />}
        </>
    );
};

export { getRoutingSearchBarWidthClass, SUBMIT_BUTTON_OFFSET, RoutingSearchBarContext } from './lib';

export { RoundedSubmitButton, RoutingButtons };
