export const BIG_QUERY_FOCUS_TYPE = 'focus_in_search_box';
export const BIG_QUERY_FOCUS_GROUP = 'user_action';
export const BIG_QUERY_EMPTY_STATE = 'empty_state';
export const BIG_QUERY_SEARCH_STATE = 'search_state';

export const BI_EVENTS = {
    IMPRESSION: {
        GROUP: 'user_impressions',
        ACTION_TYPE: 'impression',
        FEATURE_NAME: 'search_bar_suggestions',
    },
    CLICK: {
        GROUP: 'user_actions',
        ACTION_TYPE: 'click',
    },
};
