import React from 'react';
import { L2_ICON_SIZES } from '../../constants';
import type { L2IconType } from '../../types';

const TeamCollaborationL2Icon = ({ size = 'sm' }: L2IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={L2_ICON_SIZES[size]}
    height={L2_ICON_SIZES[size]}
    fill="none"
    viewBox="0 0 48 48"
  >
    <g filter="url(#team_collaboration_svg__a)">
      <path
        fill="#fff"
        d="M11.246 11.093c0-1.077.883-1.95 1.971-1.95h29.565c1.089 0 1.971.873 1.971 1.95v15.61a1.96 1.96 0 0 1-1.97 1.951H13.216a1.96 1.96 0 0 1-1.97-1.951z"
      />
      <path
        fill="#E4E5E7"
        fillRule="evenodd"
        d="M11 11.094c0-1.213.993-2.196 2.217-2.196h29.566c1.224 0 2.217.983 2.217 2.196v15.61a2.206 2.206 0 0 1-2.217 2.194H13.217A2.206 2.206 0 0 1 11 26.703zm2.217-1.708c-.952 0-1.724.765-1.724 1.708v15.61c0 .942.772 1.707 1.724 1.707h29.566c.952 0 1.724-.765 1.724-1.708v-15.61c0-.942-.772-1.707-1.724-1.707z"
        clipRule="evenodd"
      />
      <path fill="#C5C6C9" d="M14 11.969h14.985v13.86H14z" />
      <path fill="#E8F094" d="M30.957 11.969H42v13.86H30.956z" />
      <path
        fill="#fff"
        d="M3.247 24.2c0-1.08.884-1.956 1.974-1.956h33.558c1.09 0 1.974.876 1.974 1.956v17.6c0 1.08-.884 1.956-1.974 1.956H5.221A1.965 1.965 0 0 1 3.247 41.8z"
      />
      <path
        fill="#E4E5E7"
        fillRule="evenodd"
        d="M3 24.2c0-1.215.994-2.2 2.22-2.2h33.56A2.21 2.21 0 0 1 41 24.2v17.6a2.21 2.21 0 0 1-2.22 2.2H5.22A2.21 2.21 0 0 1 3 41.8zm2.22-1.711A1.72 1.72 0 0 0 3.495 24.2v17.6a1.72 1.72 0 0 0 1.727 1.711h33.558a1.72 1.72 0 0 0 1.727-1.711V24.2a1.72 1.72 0 0 0-1.727-1.711z"
        clipRule="evenodd"
      />
      <path fill="url(#team_collaboration_svg__b)" d="M37.792 25.178H6.208v15.644h31.584z" />
      <path
        fill="#fff"
        d="M21.42 34.956c1.908 0 3.454-1.533 3.454-3.423s-1.546-3.422-3.454-3.422-3.455 1.532-3.455 3.422 1.547 3.423 3.455 3.423M28.154 41.8c-.785-2.754-3.296-4.79-6.302-4.889h-.46c-3.005.1-5.517 2.135-6.301 4.889zM3 6a2 2 0 0 1 2-2h15a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
      />
      <path
        fill="#E4E5E7"
        fillRule="evenodd"
        d="M20 4.5H5A1.5 1.5 0 0 0 3.5 6v5A1.5 1.5 0 0 0 5 12.5h15a1.5 1.5 0 0 0 1.5-1.5V6A1.5 1.5 0 0 0 20 4.5M5 4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h15a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"
        clipRule="evenodd"
      />
      <path
        fill="#222325"
        d="M9 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M14 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M17.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
      />
    </g>
    <defs>
      <linearGradient
        id="team_collaboration_svg__b"
        x1={44.968}
        x2={34.615}
        y1={47.059}
        y2={1.658}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.065} stopColor="#BBCE00" />
        <stop offset={0.72} stopColor="#005C25" />
      </linearGradient>
      <filter
        id="team_collaboration_svg__a"
        width={48}
        height={46}
        x={0}
        y={1}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_39984_15338" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_39984_15338" result="shape" />
      </filter>
    </defs>
  </svg>
);
TeamCollaborationL2Icon.displayName = 'TeamCollaborationL2Icon';
export default TeamCollaborationL2Icon;
