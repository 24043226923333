import type { SlideDirectionsType } from '../types';

export const getValueOrField = (value?: string | number | { [key: string]: string | number }, field?: string) =>
    typeof value === 'object' && field ? value[field] : value;

export const slideTransition = (direction: SlideDirectionsType = 'right') => SlideTransitionVariants[direction];

const SlideTransitionVariants: Record<SlideDirectionsType, { [key: string]: any }> = {
    left: {
        position: { left: 0, top: 0, bottom: 0, width: '100%' },
        enter: { x: [0], y: [0] },
        exit: { x: [-100], y: [0] },
    },
    right: {
        position: { right: 0, top: 0, bottom: 0, width: '100%' },
        enter: { x: [0], y: [0] },
        exit: { x: [100], y: [0] },
    },
    top: {
        position: { top: 0, left: 0, right: 0, maxWidth: '100vw' },
        enter: { x: [0], y: [0] },
        exit: { x: [0], y: [-100] },
    },
    bottom: {
        position: { bottom: 0, left: 0, right: 0, maxWidth: '100vw' },
        enter: { x: [0], y: [0] },
        exit: { x: [0], y: [100] },
    },
};
