import * as contactMeClicked from './contactMeClicked';
import * as gig from './gig';
import * as onboardingQuestionnaire from './onboardingQuestionnaire';
import * as searched from './searched';

/**
 * Events map
 */
export const events = {
    gig,
    onboardingQuestionnaire,
    contactMeClicked,
    searched,
};
