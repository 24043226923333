import { useRef, useEffect, CSSProperties } from 'react';
import { useIsClient } from '@fiverr-private/hooks';

interface UseCreateRootDivElementConfig {
    className?: string;
    shouldAppendToBody?: boolean;
    style?: CSSProperties;
}

/**
 * Creates a div element under the DOM root
 */
export const useCreateRootDivElement = ({
    className,
    shouldAppendToBody = true,
    style = {},
}: UseCreateRootDivElementConfig = {}) => {
    const domElementRef = useRef<HTMLElement | null>(null);

    const isClient = useIsClient();

    if (isClient && !domElementRef.current) {
        const element = document.createElement('div');
        domElementRef.current = element;
        element.focus();

        if (className) {
            element.classList.add(className);
        }

        Object.entries(style).forEach(([key, value]) => {
            element.style[key] = value;
        });
    }

    useEffect(() => {
        const { current: domElement } = domElementRef;

        if (shouldAppendToBody && domElement) {
            document.body.appendChild(domElement);

            return () => {
                document.body.removeChild(domElement);
            };
        }

        return () => undefined;
    }, [shouldAppendToBody, isClient]);

    return domElementRef.current;
};
