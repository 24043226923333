import axios from 'axios';
import { getContext } from '@fiverr-private/fiverr_context';
import { random } from '@fiverr-private/futile';
import pathfinder from '@fiverr-private/pathfinder';
import { stats } from '@fiverr-private/obs';
import { autocompleteTests } from '../../utils/experiments/autocomplete';
import { HTTP_STATUS, STATS_PREFIX } from '../../utils/constants';
import { API_ENDPOINTS } from '../constants';
import { SearchBarMode } from '../../components/SearchBar/constants';

const { uid } = random;

const GIG = 'gig';
const USER = 'user';

export const get = (search, mode) => {
    const startTime = Date.now();

    const { abTests, locale } = getContext();

    const query = {
        ...(autocompleteTests(abTests) && autocompleteTests(abTests)),
        callback: 'autocompleteCallback',
        from_medusa_header: 'true',
        locale,
        pro_only: (mode === SearchBarMode.Pro).toString(),
        query: search,
    };

    const url = pathfinder(API_ENDPOINTS.SEARCH, null, { query });

    return axios
        .get(url)
        .then((response) => {
            const { status, headers = {}, data: { suggestions = [], users_suggestions = [] } = {} } = response;

            if (status !== HTTP_STATUS.SUCCESS) {
                return {};
            }

            const results = {};

            if (Array.isArray(suggestions)) {
                let offset = 1;
                suggestions.forEach(({ value, categories }, index) => {
                    const id = uid();
                    results[id] = {
                        id,
                        value,
                        group: GIG,
                        order: offset,
                    };
                    offset++;
                    if (index === 0) {
                        offset = addSuggestionCategories(results, value, categories, offset);
                    }
                });
            }

            if (Array.isArray(users_suggestions)) {
                users_suggestions.forEach(({ value }, index) => {
                    const id = uid();
                    results[id] = {
                        id,
                        value,
                        group: USER,
                        order: index + 1,
                    };
                });
            }

            if (headers?.service_name === 'v2') {
                stats.time(STATS_PREFIX, 'omnibox.rtt.v2', Date.now() - startTime);
            } else {
                stats.time(STATS_PREFIX, 'omnibox.rtt.v5', Date.now() - startTime);
            }

            return results;
        })
        .catch(() => ({}));
};

function addSuggestionCategories(results, value, categories, offset) {
    categories?.forEach((category) => {
        const id = uid();
        results[id] = {
            id,
            value,
            category,
            group: GIG,
            order: offset,
        };
        offset++;
    });
    return offset;
}
