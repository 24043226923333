import { getContext } from '@fiverr-private/fiverr_context';
import { logger } from '@fiverr-private/obs';
import { isServer } from './helpers';
import { LOGGER_ENRICHMENT } from './helpers/constants';
import { calculateGroup } from './calculation';
import { ClientAllocateParams, ClientDangerouslyAllocateParams } from './types';
import { allocateApi, dangerouslyAllocateApi } from './api';

/**
 * Allocates to an experiment and returns the group.
 * Calculates the group for the given experiment, based on FiverrContext identifiers:
 * ExperimentType: `guest` -> using the uid (userGuid). ExperimentType: `user` -> using the userId
 * Returns the allocation immediately and makes an un-awaited call the the experiment service to send the allocation BI event if needed.
 *
 * @param params - params object
 * @param params.experimentId - Experiment identifier.
 * @param params.experimentType - The type of experiment. Either 'user' or 'guest'.
 * @param params.numOfGroups - The total number of groups in the experiment (must be >= 2).
 * @param params.rolloutResult - True if new participants should be allocated, else false. Rollout calculated in server.
 * @param params.enrichment - Optional object that can contain extra bi enrichment (orderId, formattingLocale)
 *
 * @return - group on success, else undefined.
 */
export const clientAllocate = ({
    experimentId,
    experimentType,
    numOfGroups,
    rolloutResult,
    enrichment = {},
}: ClientAllocateParams): number | undefined => {
    if (isServer()) {
        logServerUse({ experimentId });
        return;
    }
    const context = getContext();
    if (context.knownCrawler) {
        return;
    }

    const prevAllocation = Number(context.abTests?.[experimentId]);
    if (prevAllocation) {
        return prevAllocation;
    }

    if (!rolloutResult) {
        return;
    }

    const group = calculateGroup({ experimentId, experimentType, numOfGroups, context });
    if (!group) {
        return;
    }

    allocateApi(experimentId, experimentType, numOfGroups, group, enrichment); // not awaited

    return group;
};

/**
 * Forcefully allocates to a specified group for an experiment.
 * Allocates based on ExperimentType: `guest` -> using the uid (userGuid). ExperimentType: `user` -> using the userId
 * Makes an un-awaited call the the experiment service to send the allocation BI event if needed.
 *
 * @param params - params object
 * @param params.experimentId - Experiment identifier (number).
 * @param params.experimentType - The type of experiment. Either 'user' or 'guest'.
 * @param params.group - The group to allocate to.
 * @param params.enrichment - Optional object that can contain extra bi enrichment (orderId, formattingLocale)
 *
 * @return - true on success, false otherwise.
 */
export const clientDangerouslyAllocate = ({
    experimentId,
    experimentType,
    group,
    enrichment = {},
}: ClientDangerouslyAllocateParams): boolean => {
    if (isServer()) {
        logServerUse({ experimentId });
        return false;
    }

    const { abTests, knownCrawler } = getContext();
    if (knownCrawler) {
        return false;
    }

    if (Number(abTests[experimentId.toString()]) === group) {
        return true;
    }

    dangerouslyAllocateApi(experimentId, experimentType, group, enrichment); // not awaited

    return true;
};

/**
 * Logs usage of function when used in NodeJS runtime.
 * @param enrichment - to be included in log.
 */
const logServerUse = (enrichment: { [key: string]: any }) => {
    logger.warn(
        'clientAllocate functions cannot be used in the server (SSR components or Express middleware). See the docs for more info.',
        {
            ...enrichment,
            LOGGER_ENRICHMENT,
        }
    );
};
