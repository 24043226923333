import { UserInputError } from './helpers/errors';
import { EXPERIMENT_TYPE, ERROR_MSG } from './helpers/constants';
import { ExperimentType } from './helpers/types';
import { IdentifierParams } from './helpers/interfaces';

type IdentifierResolver = (params: IdentifierParams) => string | number;

const guestIdentifierResolver = (params: IdentifierParams) => {
    const { userGuid } = params;
    if (!userGuid) {
        throw new UserInputError(ERROR_MSG.NO_GUEST_ID);
    }

    return userGuid;
};

const userIdentifierResolver = (params: IdentifierParams) => {
    const { userId } = params;
    if (!userId) {
        throw new UserInputError(ERROR_MSG.NO_USER);
    }

    return userId;
};

/**
 * Maps experiment type to the correct participant identifier resolver.
 */
const EXPERIMENT_TYPE_TO_IDENTIFIER_MAP = new Map<ExperimentType, IdentifierResolver>([
    [EXPERIMENT_TYPE.USER, userIdentifierResolver],
    [EXPERIMENT_TYPE.GUEST, guestIdentifierResolver],
]);

/**
 * Returns the participant identifier based on the ExperimentType
 * ExperimentType: `guest` -> uid (userGuid). ExperimentType: `user` -> userId
 *
 * @param params - All params needed for calculation
 */
export const getParticipantIdentifier = (params: IdentifierParams): number | string =>
    (EXPERIMENT_TYPE_TO_IDENTIFIER_MAP.get(params.experimentType) ?? guestIdentifierResolver)(params);
