/**
 * Pathfinder key for allocation endpoint.
 */
export const ALLOCATE_ENDPOINT = 'experiment_allocate_api';

/**
 * Pathfinder key for dangerous allocation endpoint.
 */
export const DANGEROUSLY_ALLOCATE_ENDPOINT = 'experiment_dangerously_allocate_api';

/**
 * Experiment type - determines which identifier is used for calculation.
 */
export const EXPERIMENT_TYPE = {
    GUEST: 'guest',
    USER: 'user',
} as const;

/**
 * All possible experiment states.
 */
export const EXPERIMENT_STATE = {
    ACTIVE: 'active',
    LOCKED: 'locked',
    DISABLED: 'disabled',
} as const;

export const MIN_NUMBER_OF_GROUPS = 2;

export const ERROR_MSG = {
    ALLOC_GROUPS: `To compute a new group, the number of groups must be at least ${MIN_NUMBER_OF_GROUPS}`,
    NO_USER: 'Experiment Type "user" can only be used when logged in - no userId supplied.',
    NO_GUEST_ID: 'Must supply guest id when calculating for "guest" experiments',
};

export const LOGGER_ENRICHMENT = {
    domain: '@fiverr-private/experiments_package',
};
