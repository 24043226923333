import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { API_ENDPOINTS } from '../constants';

/**
 * Retrieves the current user history and popular tags
 * @return {Promise<String[]>}
 */
export const get = () => {
    const url = pathfinder(API_ENDPOINTS.INITIAL_AUTOCOMPLETE);
    return axios
        .get(url)
        .then(({ data }) => data)
        .catch(() => []);
};
