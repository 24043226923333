import BezierEasing from 'bezier-easing';
import { theme } from '@fiverr-private/theme';

const CSS_EASING_PREFIX_LENGTH = 13;

const parseCssEasingToJS = (easing: string) => {
    const parsePart = (part: string) => parseFloat(part.trim());

    const parts = easing.substring(CSS_EASING_PREFIX_LENGTH, easing.length - 1).split(',');
    return BezierEasing(parsePart(parts[0]), parsePart(parts[1]), parsePart(parts[2]), parsePart(parts[3]));
};

export const ENTER = 'enter';
export const EXIT = 'exit';

export const EASING = {
    easeIn: parseCssEasingToJS(theme.animationTiming.easeIn),
    easeOut: parseCssEasingToJS(theme.animationTiming.easeOut),
    easeInOut: parseCssEasingToJS(theme.animationTiming.easeInOut),
    linear: parseCssEasingToJS(theme.animationTiming.linear),
} as const;

export const DURATION = theme.numericAnimationDurations;

export const SLIDE_FADE_OFFSETS = {
    none: 0,
    sm: 8,
    md: 16,
    lg: 32,
} as const;
