import { SET_POPULAR } from '../../actions';

const popular = (state = null, action) => {
    switch (action.type) {
        case SET_POPULAR:
            return action.payload;
        default:
            return state;
    }
};

export default popular;
