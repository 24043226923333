import pathfinder from '@fiverr-private/pathfinder';
import { CtaData } from '@fiverr-private/business_success_sign_modal';
import type { BiEnrichment } from '../../events/bigQuery';
import { countEventDispatch } from '../../events/countEvent';
import { shouldDisplayMigrationPopup } from '../UserMigration';
import { MigrationBiEnrichment, MigrationFlowContext } from '../UserMigration/types';
import { ModalType } from './types';

export const MIGRATION_MODAL_EVENT_NAME = 'fipMigrationModal:open' as keyof WindowEventMap;

export interface SeamlessModalPayloadValue {
    source: string;
    biEnrichment: BiEnrichment;
    withLoadingStep?: boolean;
    onSuccess?(args: { organizationId: string }): void;
    onClose?: () => void;
}

export interface FullMigrationModalPayloadValue {
    source: string;
    biEnrichment: MigrationBiEnrichment;
    flowContext?: MigrationFlowContext;
    redirectCtaData?: CtaData;
    onSuccess?: () => void;
    onSkip?: () => void;
    /** @deprecated remove along with useOldModalMigrationEvents */
    withSkipAction?: boolean;
    /** @deprecated use onLoadError */
    onError?: () => void;
    onLoadError?: (err?: unknown) => void;
}

export type ModalPayloadValue = SeamlessModalPayloadValue | FullMigrationModalPayloadValue;

export interface ModalPayload {
    variant: ModalType;
    payload: ModalPayloadValue;
    callbackWhenNotMounted?: () => void;
}

export const IS_MIGRATION_MANAGER_MOUNTED = 'IS_MIGRATION_MODAL_MANAGER_MOUNTED';

export const openFipMigrationModal = (detail: ModalPayload) => {
    if (!window[IS_MIGRATION_MANAGER_MOUNTED]) {
        const { callbackWhenNotMounted, payload, variant } = detail;
        if (callbackWhenNotMounted) {
            callbackWhenNotMounted?.();

            return;
        }

        if (variant === ModalType.FullMigration) {
            goToFullMigrationPage({ source: payload.source });
        }
    }

    const event = new CustomEvent(MIGRATION_MODAL_EVENT_NAME, {
        detail,
    });

    countEventDispatch({ type: detail.variant, source: detail.payload.source });
    window.dispatchEvent(event);
};

export const shouldDisplayFullMigrationModal = shouldDisplayMigrationPopup;

export const openFipFullMigrationModal = (payload: FullMigrationModalPayloadValue) => {
    openFipMigrationModal({
        variant: ModalType.FullMigration,
        payload,
    });
};

export const goToFullMigrationPage = ({ source }: { source: ModalPayloadValue['source'] }) => {
    const linkToMigration = pathfinder('business_user_migration_new', {}, { query: { source } });
    window.location.assign(linkToMigration);
};
