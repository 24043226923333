import React, { forwardRef } from 'react';
import { Types } from '@fiverr-private/theme';
import Layout from '../Layout';

const Flex = forwardRef<HTMLElement, Types.FlexLayoutProps>(
  ({ children, display = 'flex', ...restProps }: Types.FlexLayoutProps, ref) => (
    <Layout ref={ref} display={display} {...restProps}>
      {children}
    </Layout>
  )
);

Flex.displayName = 'Flex';

export default Flex;
