export const SYSTEM_ICON_SIZES = {
    xs: 12,
    sm: 16,
    md: 20,
    lg: 24,
} as const;

export const L1_ICON_SIZES = {
    sm: 24,
    md: 32,
} as const;

export const L2_ICON_SIZES = {
    sm: 48,
    md: 56,
    lg: 64,
} as const;

export const FLAG_ICON_SIZES = {
    sm: 16,
    md: 20,
    lg: 24,
} as const;

export const FIVERR_LOGOS_SIZES = {
    sm: {
        width: 90,
        height: 28,
    },
    md: {
        width: 120,
        height: 36,
    },
} as const;
