import { mapResponsiveStyles, type Types } from '@fiverr-private/theme';
import type { ButtonSize, ButtonProps } from './types';

interface ResponsiveStyles extends Pick<Types.BoxType, 'paddingX' | 'paddingY' | 'lineHeight' | 'fontSize'> {}

const STYLES_BY_SIZE: {
    [key in ButtonSize]: ResponsiveStyles;
} = {
    sm: {
        paddingY: '1.5',
        paddingX: '3',
        lineHeight: 'b_sm',
        fontSize: 'b_sm',
    },
    md: {
        paddingY: '2',
        paddingX: '4',
        lineHeight: 'b_md',
        fontSize: 'b_md',
    },
    lg: {
        paddingY: '2.5',
        paddingX: '5',
        lineHeight: 'b_lg',
        fontSize: 'b_md',
    },
};

export const getConditionalSize = (size: ButtonProps['size']): ResponsiveStyles => {
    if (typeof size === 'object') {
        return {
            paddingX: mapResponsiveStyles(size, (value) => STYLES_BY_SIZE[value as string].paddingX),
            paddingY: mapResponsiveStyles(size, (value) => STYLES_BY_SIZE[value as string].paddingY),
            fontSize: mapResponsiveStyles(size, (value) => STYLES_BY_SIZE[value as string].fontSize),
            lineHeight: mapResponsiveStyles(size, (value) => STYLES_BY_SIZE[value as string].lineHeight),
        };
    }

    return STYLES_BY_SIZE[size || 'md'];
};

export const getConditionalFullWidth = (fullWidth: ButtonProps['fullWidth']): Types.BoxType['width'] => {
    if (typeof fullWidth === 'object') {
        return mapResponsiveStyles(fullWidth, (value) => (value ? '100%' : 'initial'));
    }

    return fullWidth ? '100%' : undefined;
};
