import React from 'react';
import { Popover } from '@fiverr-private/popover';
import type { DropdownPopoverProps, DropdownProps } from './types';
import DropdownContextProvider from './DropdownContextProvider';

const Dropdown = ({
  children,
  onChange,
  defaultValues,
  values,
  searchWhenClosed = false,
  isOpen,
  onOpenChanged,
  initialOpen,
  disableFlip,
  position = 'bottom-start',
  keyboardNavigationLoop = false,
  searchOnKeyPress = true,
  selectedIndicatorPosition = 'left',
  theme = 'light',
}: DropdownProps & DropdownPopoverProps) => (
  <Popover
    position={position}
    isOpen={isOpen}
    onOpenChanged={onOpenChanged}
    initialOpen={initialOpen}
    disableFlip={disableFlip}
  >
    <DropdownContextProvider
      onChange={onChange}
      defaultValues={defaultValues}
      values={values}
      searchWhenClosed={searchWhenClosed}
      keyboardNavigationLoop={keyboardNavigationLoop}
      searchOnKeyPress={searchOnKeyPress}
      selectedIndicatorPosition={selectedIndicatorPosition}
      theme={theme}
    >
      {children}
    </DropdownContextProvider>
  </Popover>
);
export default Dropdown;
