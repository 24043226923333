import React from 'react';
import PropTypes from 'prop-types';
import { Container, Divider, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { uppercase, routingButtonShowTextClass, useRoutingSearchBarContext } from '../lib';
import { showRoutingButtonAnimation, hideRoutingButtonAnimation } from './RoutingButtonStyles.ve.css';

export const RoutingButton = ({ icon, text, onClick }) => {
    const { isSearchOnly, isRoutingSearchBarOpen, showRoutingSearchBarAnimation } = useRoutingSearchBarContext();

    const getAnimationClass = () => {
        if (!showRoutingSearchBarAnimation) {
            return;
        }
        return isSearchOnly ? hideRoutingButtonAnimation : showRoutingButtonAnimation;
    };

    return (
        <Container gap="1" display="flex" direction="row" className={getAnimationClass()}>
            <Stack>
                <Divider direction="vertical" height="36px" />
            </Stack>
            <Stack
                paddingX="4"
                paddingY="2"
                justifyContent="center"
                alignItems="center"
                display="inlineFlex"
                gap="2"
                backgroundColor={{ default: 'transparent', hover: 'grey_300' }}
                height="40px"
                borderRadius="circle"
                overflow="hidden"
                whiteSpace="nowrap"
                cursor="pointer"
                onClick={() => onClick && onClick()}
            >
                {icon}
                <Container
                    color="grey_1200"
                    lineHeight="h_md"
                    className={isRoutingSearchBarOpen ? routingButtonShowTextClass : undefined}
                >
                    <Text size="b_sm" fontWeight="semibold" className={uppercase}>
                        {text}
                    </Text>
                </Container>
            </Stack>
        </Container>
    );
};

RoutingButton.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.node,
    onClick: PropTypes.func,
};
