import { combineReducers } from 'redux';
import search from './search';
import originalTerm from './originalTerm';
import selectedSuggestionId from './selectedSuggestionId';
import suggestions from './suggestions';
import history from './history';
import popular from './popular';
import suggestionDirection from './suggestionDirection';
import activeExperiments from './activeExperiments';
import promotedGigsSuggestions from './promotedGigsSuggestions';

export default combineReducers({
    search,
    originalTerm,
    selectedSuggestionId,
    suggestions,
    promotedGigsSuggestions,
    history,
    popular,
    activeExperiments,
    suggestionDirection,
    mode: (state = 'default') => state,
    source: (state = 'unknown') => state,
    placeholder: (state = '') => state,
    submitOnSuggestionSelected: (state = true) => state,
    searchWillSubmit: (state = '') => state,
    onSearch: (state = () => null) => state,
    customQueryParams: (state = {}) => state,
    rollouts: (state = {}) => state,
    currency: (state = {}) => state,
    clientExperimentsGroups: (state = {}) => state,
});
