import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import type { Types } from '@fiverr-private/theme';
import { renderChildren } from '@fiverr-private/ui_utils';
import type { PopoverAnchorProps } from '../types';
import { usePopoverContext } from '../PopoverContext';

const PopoverAnchor = ({
  children,
  as = 'div',
  disabled,
  dataTestId,
  ariaHasPopup,
  fullWidth,
  onFocus,
  onBlur,
  flex,
  display,
  embed,
}: PopoverAnchorProps) => {
  const {
    open,
    setOpen,
    triggerEvent,
    floatingData: { refs },
  } = usePopoverContext();

  const triggerEventListeners = {
    click: {
      onClick: () => {
        setOpen(!open);
      },
    },
    hover: {
      onMouseEnter: () => {
        setOpen(true);
      },
      onMouseLeave: () => {
        setOpen(false);
      },
    },
  };

  const getEventListeners = () => {
    if (disabled) {
      return {};
    }
    return {
      onFocus,
      onBlur,
      ...triggerEventListeners[triggerEvent],
    };
  };

  const accessibleAttributes: Types.ContainerProps =
    triggerEvent === 'click'
      ? {
          'aria-expanded': open,
          'aria-haspopup': ariaHasPopup || 'true',
          ...(embed ? {} : { role: 'button', tabIndex: -1 }),
        }
      : {};

  return (
    <Container
      as={as}
      height="fit-content"
      maxWidth="100%"
      width={fullWidth ? '100%' : 'fit-content'}
      flex={flex}
      display={display}
      ref={refs.setReference}
      data-testid={dataTestId}
      {...getEventListeners()}
      {...(embed ? {} : accessibleAttributes)}
    >
      {embed ? renderChildren(children, { ...accessibleAttributes }) : children}
    </Container>
  );
};

PopoverAnchor.displayName = 'PopoverAnchor';

export default PopoverAnchor;
