import React from 'react';
import { ModalType, openFipMigrationModal } from '@fiverr-private/pro_migration';
import { Button } from '@fiverr-private/button';
import { mixpanel } from '@fiverr-private/obs';
import { atoms } from '@fiverr-private/theme';
import { I18n } from '@fiverr-private/i18n-react';
import { reportBigQueryClick } from '../../../utils/reporter';

const UpgradeToProLink = () => {
    const bqData = {
        group: 'header_clicks',
        type: 'clicked_header.upgrade_to_pro',
        page: {
            element: {
                name: 'upgrade_to_pro',
                text: 'upgrade to pro',
            },
        },
        user: {
            navigation_mode: 'buying',
        },
    };
    const handleClick = () => {
        openFipMigrationModal({
            variant: ModalType.SeamlessUpgradeToPro,
            payload: {
                source: 'top_nav',
                biEnrichment: {
                    pageElementName: 'seamless_top_nav',
                },
            },
        });
        reportBigQueryClick(bqData);
        mixpanel.track('Clicked on Upgrade to Pro - Fiverr menu', {});
    };

    return (
        <Button variant="ghost" onClick={handleClick} className={atoms({ whiteSpace: 'nowrap' })}>
            <I18n k="header.fiverr_pro_entry.alt_title_upgrade" />
        </Button>
    );
};

export default UpgradeToProLink;
