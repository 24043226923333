import { createContext, useContext } from 'react';
import { IUseDropdownContext } from './types';

export const DropdownContext = createContext<IUseDropdownContext>({} as IUseDropdownContext);

const useDropdownContext = () => {
    const context = useContext(DropdownContext);

    if (!context) {
        throw new Error('useDropdownContext must be used within a DropdownProvider');
    }

    return context;
};

export default useDropdownContext;
