import { ANY_KEY, BLACKLISTED_TARGETS } from './constants';
import { Shortcut } from './types';

interface IsObservedEventProps {
    // The current event key property
    key: string;

    // The current event code property
    code: string;

    // The current event target
    target: HTMLElement;

    // Whether it's a repeat event
    repeat?: boolean;

    // Whether repeated event allowed
    allowRepeat?: boolean;

    // The observed shortcut keys
    shortcutKeys: string[];
}

// Checks whether a given event should be check for shortcut triggers
export const getObservedShortcut = ({
    key,
    code,
    target,
    repeat = false,
    allowRepeat,
    shortcutKeys,
}: IsObservedEventProps) => {
    if ((repeat && !allowRepeat) || BLACKLISTED_TARGETS.includes(target.tagName.toLowerCase())) {
        return;
    }

    return shortcutKeys.find(
        (shortcutKey) =>
            shortcutKey === ANY_KEY || shortcutKey.toLowerCase() === key || shortcutKey.toLowerCase() === code
    );
};

// Checks should trigger shortcuts according to current pressed keys
export const shouldTrigger = (
    // The observed shortcuts
    shortcuts: string[],

    // The current pressed keys
    keys: string[]
) => keys.length === shortcuts.length && keys.every((key, index) => key === shortcuts[index]);

// The default shortcuts listener target to use
export const getDefaultTarget = () => document.body;

export const mapShortcutsToKeys = (shortcuts: Shortcut[]) => shortcuts.map(({ trigger }) => trigger).flat();
