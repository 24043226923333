import { pushEvent } from '../services/dataLayer';
import { Event } from './types';

/**
 * Publish Order options opened event on a gig page
 */
export const orderOptionsOpened = () => {
    pushEvent({
        event: Event.gigOrderOptionsOpened,
    });
};
