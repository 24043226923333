export const API_ENDPOINTS = {
    SEARCH: 'search_layout_omnibox',
    INITIAL_AUTOCOMPLETE: 'autocomplete_initial_state',
    PROMOTED_GIGS: 'promoted_gigs_in_search_bar_ajax',
};

export const OPERATION_STATUS = {
    SUCCESS: 'success',
    FAILED: 'failed',
};
