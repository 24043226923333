import React from 'react';
import { L2_ICON_SIZES } from '../../constants';
import type { L2IconType } from '../../types';

const SatisfactionGuaranteeL2Icon = ({ size = 'sm' }: L2IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={L2_ICON_SIZES[size]}
    height={L2_ICON_SIZES[size]}
    fill="none"
    viewBox="0 0 48 48"
  >
    <g filter="url(#satisfaction_guarantee_svg__a)">
      <path fill="#fff" d="M3 16a2 2 0 0 1 2-2h33a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <path
        stroke="#E4E5E7"
        strokeWidth={0.5}
        d="M3.25 16c0-.966.784-1.75 1.75-1.75h33c.967 0 1.75.784 1.75 1.75v20A1.75 1.75 0 0 1 38 37.75H5A1.75 1.75 0 0 1 3.25 36z"
      />
    </g>
    <path fill="url(#satisfaction_guarantee_svg__b)" d="M3.5 18h36v5h-36z" />
    <path fill="#E8F094" d="M6 26h10v4H6z" />
    <g fill="#C5C6C9" filter="url(#satisfaction_guarantee_svg__c)">
      <path d="m22.684 27.27.314.913-1.308.298.869.952-.838.567L21 28.952 20.287 30l-.847-.567.868-.952L19 28.183l.325-.914 1.245.471-.084-1.24h1.036l-.094 1.24zM28.183 27.27l.314.913-1.309.298.869.952-.837.567-.723-1.048L25.786 30l-.848-.567.869-.952-1.309-.298.325-.914 1.245.471-.084-1.24h1.037l-.095 1.24zM33.68 27.27l.315.913-1.308.298.868.952-.837.567-.722-1.048L31.284 30l-.848-.567.869-.952-1.308-.298.324-.914 1.246.471-.084-1.24h1.036l-.094 1.24z" />
    </g>
    <g filter="url(#satisfaction_guarantee_svg__d)">
      <path fill="#fff" d="M17 35a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H19a2 2 0 0 1-2-2z" />
      <path
        stroke="#E4E5E7"
        strokeWidth={0.5}
        d="M17.25 35c0-.967.784-1.75 1.75-1.75h24c.967 0 1.75.783 1.75 1.75v8A1.75 1.75 0 0 1 43 44.75H19A1.75 1.75 0 0 1 17.25 43z"
      />
    </g>
    <path
      fill="#222325"
      d="M22.795 36.142a.22.22 0 0 1 .41 0l.645 1.706a.22.22 0 0 0 .19.142l1.755.122a.22.22 0 0 1 .13.386l-1.37 1.202a.22.22 0 0 0-.068.218l.435 1.809a.22.22 0 0 1-.335.235l-1.465-.977a.22.22 0 0 0-.244 0l-1.465.977a.22.22 0 0 1-.335-.235l.435-1.81a.22.22 0 0 0-.069-.217l-1.369-1.202a.22.22 0 0 1 .13-.386l1.755-.122a.22.22 0 0 0 .19-.142z"
    />
    <path fill="#C5C6C9" d="M29 37h5v1.5h-5zM29 40h9v1.5h-9z" />
    <g filter="url(#satisfaction_guarantee_svg__e)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m35.707 4.041-8 2.531a1.04 1.04 0 0 0-.707.985v8.208c0 6.258 8.077 8.982 8.683 9.186l.024.008a1.1 1.1 0 0 0 .586 0c.36-.11 8.707-2.818 8.707-9.193V7.557c0-.451-.28-.848-.707-.985l-8-2.531a1.07 1.07 0 0 0-.586 0"
        clipRule="evenodd"
      />
      <path
        stroke="#E4E5E7"
        strokeWidth={0.5}
        d="M27.25 7.557a.79.79 0 0 1 .534-.747l7.995-2.53a.8.8 0 0 1 .442 0l7.996 2.53c.32.103.533.4.533.747v8.208c0 3.067-2.006 5.272-4.111 6.742-2.097 1.463-4.24 2.159-4.417 2.212a.84.84 0 0 1-.44.001l-.02-.006c-.296-.1-2.439-.823-4.503-2.29-2.068-1.47-4.009-3.65-4.009-6.658z"
      />
    </g>
    <path
      fill="url(#satisfaction_guarantee_svg__f)"
      fillRule="evenodd"
      d="m35.772 6.033-6.222 2.05a.84.84 0 0 0-.55.796v6.645c0 5.066 6.282 7.27 6.753 7.436l.019.007a.8.8 0 0 0 .456 0c.28-.089 6.772-2.282 6.772-7.443V8.88a.83.83 0 0 0-.55-.797l-6.222-2.049a.8.8 0 0 0-.456 0"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M38.13 15.122c0 .974-.74 1.46-1.522 1.588v.79h-.781v-.782c-.94-.112-1.672-.702-1.697-1.899h.89c.008.678.35 1.045.807 1.165v-1.979c-.948-.287-1.514-.806-1.514-1.643 0-.934.699-1.46 1.514-1.58V10h.782v.798c.798.143 1.488.71 1.513 1.691h-.898c-.017-.494-.274-.798-.615-.941v1.843c.881.231 1.521.742 1.521 1.731m-2.894-2.808c0 .407.192.662.59.846v-1.644c-.332.104-.59.367-.59.798m1.373 1.93v1.716c.34-.12.598-.39.598-.806 0-.407-.15-.702-.599-.91"
    />
    <defs>
      <filter
        id="satisfaction_guarantee_svg__a"
        width={43}
        height={30}
        x={0}
        y={11}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_42047_3867" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_42047_3867" result="shape" />
      </filter>
      <filter
        id="satisfaction_guarantee_svg__c"
        width={20.995}
        height={9.5}
        x={16}
        y={23.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_42047_3867" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_42047_3867" result="shape" />
      </filter>
      <filter
        id="satisfaction_guarantee_svg__d"
        width={34}
        height={18}
        x={14}
        y={30}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_42047_3867" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_42047_3867" result="shape" />
      </filter>
      <filter
        id="satisfaction_guarantee_svg__e"
        width={24}
        height={27}
        x={24}
        y={1}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_42047_3867" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_42047_3867" result="shape" />
      </filter>
      <linearGradient
        id="satisfaction_guarantee_svg__b"
        x1={3.5}
        x2={11.547}
        y1={16.5}
        y2={36.074}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005C25" />
        <stop offset={1} stopColor="#BBCE00" />
      </linearGradient>
      <linearGradient
        id="satisfaction_guarantee_svg__f"
        x1={29}
        x2={49.09}
        y1={0.9}
        y2={6.489}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#005C25" />
        <stop offset={1} stopColor="#BBCE00" />
      </linearGradient>
    </defs>
  </svg>
);
SatisfactionGuaranteeL2Icon.displayName = 'SatisfactionGuaranteeL2Icon';
export default SatisfactionGuaranteeL2Icon;
