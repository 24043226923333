import { TOP_NAV_SOURCE } from '../../constants';

export const buildLinkHref = ({ link, source = TOP_NAV_SOURCE }: { link: string; source?: string }) => {
    try {
        const url = new URL(link);
        url.searchParams.set('source', source);
        return url.toString();
    } catch (err) {
        return `${link}?source=${source}`;
    }
};
