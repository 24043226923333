import axios from 'axios';
import { isNil } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { API_ENDPOINTS, OPERATION_STATUS as OperationType } from '../constants';
import { reportRequestStatus, reportRequestTime } from '../../utils/apis';
import { EMPTY_PROMOTED_GIGS_SUGGESTIONS } from './constants';

/**
 * Retrieves the promoted gigs and associated sub-category based on autocomplete queries.
 */
export const getPromotedGigs = async (autoCompleteQueries) => {
    const { pageCtxId, userAgent } = getContext();

    try {
        const url = pathfinder(API_ENDPOINTS.PROMOTED_GIGS);

        const startTime = Date.now();

        const { data } = await axios.post(url, {
            query_string: autoCompleteQueries[0],
            user_agent: userAgent,
            page_size: 4,
            number_of_promoted_gigs: 4,
            page_ctx_id: pageCtxId,
            is_search_bar: true,
        });

        reportRequestTime(API_ENDPOINTS.PROMOTED_GIGS, OperationType.SUCCESS, startTime);
        reportRequestStatus(API_ENDPOINTS.PROMOTED_GIGS, OperationType.SUCCESS);

        if (!data || isNil(data.gigs) || data.gigs.length !== 4 || data.gigs.some((gig) => isNil(gig.auction))) {
            return EMPTY_PROMOTED_GIGS_SUGGESTIONS;
        }

        return parseResponse(data);
    } catch (error) {
        if (error?.response?.status) {
            reportRequestStatus(API_ENDPOINTS.PROMOTED_GIGS, `${OperationType.FAILED}.${error.response.status}`);
        } else {
            reportRequestStatus(API_ENDPOINTS.PROMOTED_GIGS, `${OperationType.FAILED}.unknown`);
        }

        logger.error(error, {
            description: 'Fetch Promoted Gigs Suggestions',
            autoCompleteQueries,
        });

        return EMPTY_PROMOTED_GIGS_SUGGESTIONS;
    }
};

const parseResponse = (response) => {
    const subCategoryConfig = parseSubCategoryConfig(response);
    const gigs = parsePromotedGigs(response);

    return {
        gigs,
        subCategoryConfig,
    };
};

const parseSubCategoryConfig = (response) => {
    if (response && isNil(response.dlc) && isNil(response.dlc_parent)) {
        logger.warn('Error parsing sub category - dlc not supplied');
    }

    return {
        subCategoryId: response.dlc_parent,
        nestedSubCategoryId: response.dlc,
        currentDLC: response.dlc,
    };
};

const parsePromotedGigs = (response) => {
    if (!response.gigs) {
        logger.warn('Error parsing gigs - no gigs found');
        return [];
    }

    return response.gigs
        .filter((promotedGig) => !!promotedGig)
        .map((gig) => {
            const {
                gig_id,
                seller_id,
                seller_name,
                buying_review_rating,
                buying_review_rating_count,
                cached_slug,
                title,
                price_i,
                assets = [],
                auction,
                translatedTitle,
            } = gig;

            if (isNil(gig_id) || isNil(title) || isNil(price_i)) {
                throw new Error('Error parsing promoted gig - Missing required fields');
            }

            return {
                id: gig_id,
                gigId: gig_id,
                slug: cached_slug,
                title,
                translatedTitle,
                rating: buying_review_rating,
                ratingCount: buying_review_rating_count,
                priceInDollars: price_i,
                assets,
                seller: {
                    id: seller_id,
                    username: seller_name,
                },
                auctionId: auction?.id,
                auction,
            };
        });
};
