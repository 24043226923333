import React from 'react';
import { SearchIcon } from '@fiverr-private/icons';
import { IconButton } from '@fiverr-private/button';
import { Container } from '@fiverr-private/layout_components';
import { theme } from '@fiverr-private/theme';
import { useRoutingSearchBarContext } from '../lib/context';

const RoundedSubmitButton = () => {
    const { disabled } = useRoutingSearchBarContext();

    return (
        <Container paddingRight="1.5" display="flex" justifyContent="center" alignItems="center">
            <IconButton
                disabled={disabled}
                size="sm"
                aria-label="search"
                variant="filled"
                intent="primary"
                shape="circle"
                backgroundColor={{
                    default: disabled ? 'transparent' : 'grey_1200',
                    hover: disabled ? 'transparent' : 'grey_1100',
                }}
            >
                <SearchIcon color={disabled ? theme.colors.grey_1200 : 'white'} />
            </IconButton>
        </Container>
    );
};

export { RoundedSubmitButton };
