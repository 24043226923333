/**
 * The symbol used to find a matching pattern.
 * @type {String}
 */
const TEMPLATE_SYMBOL = '_';

/**
 * The regex used to match patterns.
 * @type {RegExp}
 */
export const TEMPLATE_REGEX = new RegExp(
    `\\${TEMPLATE_SYMBOL}([^${TEMPLATE_SYMBOL}${TEMPLATE_SYMBOL}]+)${TEMPLATE_SYMBOL}`,
    'g'
);

/**
 * The error message logged when the number of templates doesn't match the number of patterns.
 * @type {String}
 */
export const INVALID_NUMBER_OF_TEMPLATES =
    'The number of template declarations does not match the number of templates provided.';

/**
 * The error message logged when passed an invalid template.
 * @type {String}
 */
export const INVALID_TEMPLATE_TYPE = 'Templates must be functions, instead got:';
