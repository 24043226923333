import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import Link from '../Links/Link';
import { HEADER_NAV_SOURCE } from '../../constants';

import { fiverrGoText } from './FiverrGo.styles.ve.css';

export const FiverrGoAnimated = () => {
    const hrefExplore = pathfinder('go_explore_landing_page', {}, { query: { source: HEADER_NAV_SOURCE } });
    const text = translate('header.try_fiverr_go');

    return (
        <Link className={`${fiverrGoText}`} href={hrefExplore}>
            {text}
        </Link>
    );
};
