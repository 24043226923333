import { useCallback, useState } from 'react';
import { useEventListener, useIsomorphicLayoutEffect } from '../..';

const isWindow = (element: HTMLElement | Window | null): element is Window => element === window;

const useElementSize = (element: HTMLElement | Window | null) => {
    const [elementSize, setElementSize] = useState({
        width: 0,
        height: 0,
    });

    const handleSize = useCallback(() => {
        if (!element) {
            return;
        }

        setElementSize({
            width: isWindow(element) ? element.innerWidth : element.scrollWidth,
            height: isWindow(element) ? element.innerHeight : element.scrollHeight,
        });
    }, [element]);

    useEventListener('resize', handleSize, typeof window !== 'undefined' ? window : null);

    useIsomorphicLayoutEffect(() => {
        handleSize();
    }, [element]);

    return elementSize;
};

export default useElementSize;
