import { useState, useCallback, useEffect } from 'react';
import { omitBy, isEmpty, isUndefined } from 'lodash';
import { getValues } from './utils';
import type { Values, ItemValue, UseDropdownStateProps } from './types';

const useDropdownState = ({ defaultValues, values: _values, onChange: _onChange }: UseDropdownStateProps) => {
    const controlled = !isUndefined(_values);
    const [values, setValues] = useState<Values>(getValues(_values, defaultValues));

    useEffect(() => {
        setValues(getValues(_values, defaultValues));
    }, [defaultValues, _values, setValues]);

    const onChange = useCallback(
        (currentInput: Pick<HTMLInputElement, 'checked' | 'name' | 'value' | 'type'>) => {
            const { checked, name, value, type } = currentInput;
            let newValue: Values;

            if (type === 'checkbox') {
                if (checked) {
                    newValue = {
                        ...values,
                        [name]: values[name] ? [...(values[name] as ItemValue[]), value] : [value],
                    };
                } else {
                    newValue = {
                        ...values,
                        [name]: (values[name] as ItemValue[])?.filter((itemValue) => itemValue !== value),
                    };
                }
            } else {
                newValue = {
                    ...values,
                    [name]: value,
                };
            }

            if (!controlled) {
                setValues(omitBy(newValue, isEmpty));
            }

            _onChange?.(newValue);
        },
        [controlled, _onChange, values]
    );

    return { onChange, defaultValues, values, setValues };
};

export default useDropdownState;
