import { SET_ACTIVE_EXPERIMENTS } from '../../actions';

const activeExperiments = (state = {}, action) => {
    switch (action.type) {
        case SET_ACTIVE_EXPERIMENTS:
            return action.payload;
        default:
            return state;
    }
};

export default activeExperiments;
