import { getContext } from '@fiverr-private/fiverr_context';
import { Domingo } from '@fiverr-private/domingo';
import pathfinder from '@fiverr-private/pathfinder';
import { translate } from '../../utils/translate';
import { eventsApi } from './events';
import { ProMenuButton, ProMenuItem, OnClickParams, ProDropdown, ProDropdownItem } from './pro-menus.types';

export const getProMenusCopiesConfig = () => {
    const { url } = getContext();

    const path = (...args: Parameters<ReturnType<typeof Domingo>['path']>) =>
        Domingo(url)
            .removePath()
            .pro()
            .path(...args);

    const buildUrl = (name: Parameters<typeof path>[0]) => path(name).url;
    const buildProSolutions = (slug: string) => path('fiverr_pro_product_page', { slug }).url; // /pro-solutions/:slug

    const onClickButton =
        (params: OnClickParams): ProMenuItem['onClick'] =>
        () =>
            eventsApi.clickedOnButton({ ...params });

    const onClickLink =
        (params: OnClickParams): ProMenuItem['onClick'] =>
        () =>
            eventsApi.clickedOnLink({ ...params });

    return {
        plans: {
            buttonText: translate('plans'),
            link: buildProSolutions('plans'), // 'https://pro.fiverr.com/pro-solutions/plans',
            onClick: onClickButton({
                eventType: 'clicked_on_plans',
                elementName: 'plans',
                componentName: null,
            }),
        } satisfies ProMenuButton,
        pro_products: {
            buttonText: translate('pro_products.button'),
            onClick: onClickButton({
                eventType: 'clicked_on_products',
                elementName: 'products',
                componentName: 'products',
            }),
            benefits: {
                title: translate('pro_products.benefits_title'),
                items: [
                    {
                        text: translate('pro_products.benefits.freelance_talent'),
                        link: buildProSolutions('hiring-freelance-talent'), // 'https://pro.fiverr.com/pro-solutions/hiring-freelance-talent',
                        onClick: onClickLink({
                            eventType: 'clicked_on_hiring_and_onboarding',
                            elementName: 'hiring_and_onboarding',
                            componentName: 'products',
                        }),
                    },
                    {
                        text: translate('pro_products.benefits.collaboration_tools'),
                        link: buildProSolutions('collaboration-payments'), // 'https://pro.fiverr.com/pro-solutions/collaboration-payments',
                        onClick: onClickLink({
                            eventType: 'clicked_on_team_collaboration',
                            elementName: 'team_collaboration',
                            componentName: 'products',
                        }),
                    },
                    {
                        text: translate('pro_products.benefits.satisfaction_guarantee'),
                        link: buildProSolutions('satisfaction-guarantee'), // 'https://pro.fiverr.com/pro-solutions/satisfaction-guarantee',
                        onClick: onClickLink({
                            eventType: 'clicked_on_satisfaction_guarantee',
                            elementName: 'satisfaction_guarantee',
                            componentName: 'products',
                        }),
                    },
                    {
                        text: translate('pro_products.benefits.business_rewards_program'),
                        link: buildProSolutions('pro-business-rewards'), // 'https://pro.fiverr.com/pro-solutions/pro-business-rewards',
                        onClick: onClickLink({
                            eventType: 'clicked_on_business_rewards_program',
                            elementName: 'business_rewards_program',
                            componentName: 'products',
                        }),
                    },
                    {
                        text: translate('pro_products.benefits.fiverr_enterprise'),
                        link: buildProSolutions('enterprise'), // 'https://pro.fiverr.com/pro-solutions/enterprise',
                        onClick: onClickLink({
                            eventType: 'clicked_on_enterprise',
                            elementName: 'enterprise',
                            componentName: 'products',
                        }),
                    },
                ] satisfies ProMenuItem[],
            },
            services: {
                title: translate('pro_products.services_title'),
                items: [
                    {
                        text: translate('pro_products.services.expert_sourcing'),
                        link: buildProSolutions('expert-sourcing-services'), // 'https://pro.fiverr.com/pro-solutions/expert-sourcing-services',
                        onClick: onClickLink({
                            eventType: 'clicked_on_expert_sourcing',
                            elementName: 'expert_sourcing',
                            componentName: 'products',
                        }),
                    },
                    {
                        text: translate('pro_products.services.project_planning'),
                        link: buildProSolutions('project-planning'), // 'https://pro.fiverr.com/pro-solutions/project-planning',
                        onClick: onClickLink({
                            eventType: 'clicked_on_project_planning',
                            elementName: 'project_planning',
                            componentName: 'products',
                        }),
                    },
                    {
                        text: translate('pro_products.services.project_management'),
                        link: buildProSolutions('project-management-service'), // 'https://pro.fiverr.com/pro-solutions/project-management-service',
                        onClick: onClickLink({
                            eventType: 'clicked_on_project_management_service',
                            elementName: 'project_management_service',
                            componentName: 'products',
                        }),
                    },
                    {
                        text: translate('pro_products.services.compliance'),
                        link: buildProSolutions('compliance-services'), // 'https://pro.fiverr.com/pro-solutions/compliance-services',
                        onClick: onClickLink({
                            eventType: 'clicked_on_compliance_services',
                            elementName: 'compliance_services',
                            componentName: 'products',
                        }),
                    },
                ] satisfies ProMenuItem[],
            },
        } satisfies ProMenuButton,
        pro_resources: {
            buttonText: translate('pro_resources.button'),
            onClick: onClickButton({
                eventType: 'clicked_on_resources',
                elementName: 'resources',
                componentName: 'resources',
            }),
            items: [
                // README: Temporary remove, page is not ready
                // {
                //     text: translate('pro_resources.blog'),
                //     link: 'https://pro.fiverr.com/pro-resources/blog/hub',
                //     onClick: onClick({
                //         eventType: 'clicked_on_blog',
                //         elementName: 'blog',
                //         componentName: 'resources',
                //     })
                // },
                {
                    text: translate('pro_resources.case_studies'),
                    link: buildProSolutions('case-studies'),
                    onClick: onClickLink({
                        eventType: 'clicked_on_case_studies',
                        elementName: 'case_studies',
                        componentName: 'resources',
                    }),
                },
                {
                    text: translate('pro_resources.support'),
                    link: 'https://help-pro.fiverr.com/hc',
                    onClick: onClickLink({
                        eventType: 'clicked_on_help_and_support',
                        elementName: 'help_and_support',
                        componentName: 'resources',
                    }),
                },
                {
                    text: translate('pro_resources.contact_sales'),
                    link: buildUrl('fbs_contact_us_page'),
                    onClick: onClickLink({
                        eventType: 'clicked_on_contact_sales',
                        elementName: 'contact_sales',
                        componentName: 'resources',
                    }),
                },
            ] satisfies ProMenuItem[],
        } satisfies ProMenuButton,
        pro_dropdown: {
            buttonText: translate('pro_dropdown.button'),

            items: [
                {
                    key: 'satisfaction',
                    text: translate('pro_dropdown.items.satisfaction.title'),
                    description: translate('pro_dropdown.items.satisfaction.description'),
                    link: path('content_pages_page', { page_slug: 'pro-satisfaction-guarantee' }).url,
                    iconName: 'SatisfactionGuaranteeL2Icon',
                },
                {
                    key: 'rewards',
                    text: translate('pro_dropdown.items.rewards.title'),
                    description: translate('pro_dropdown.items.rewards.description'),
                    link: path('fiverr_pro_product_page', { slug: 'pro-business-rewards' }).url,
                    iconName: 'RewardsProgramL2Icon',
                },
                {
                    key: 'sourcing',
                    text: translate('pro_dropdown.items.sourcing.title'),
                    description: translate('pro_dropdown.items.sourcing.description'),
                    link: path('content_pages_page', { page_slug: 'pro-professional-services' }).url,
                    iconName: 'ProServicesL2Icon',
                },
                {
                    key: 'collaboration',
                    text: translate('pro_dropdown.items.collaboration.title'),
                    description: translate('pro_dropdown.items.collaboration.description'),
                    link: path('content_pages_page', { page_slug: 'collaboration-payments' }).url,
                    iconName: 'TeamCollaborationL2Icon',
                },
                {
                    key: 'offer_services',
                    text: translate('pro_dropdown.items.offer_services.title'),
                    description: translate('pro_dropdown.items.offer_services.description'),
                    link: pathfinder('content_pages_page', { page_slug: 'pro-freelancers' }),
                    iconName: 'ProSellerL2Icon',
                },
            ] satisfies ProDropdownItem[],
        } satisfies ProDropdown,
    };
};
