import { isString } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { SearchBarMode } from '../../components/SearchBar/constants';
import { PROMOTED_GIGS_SUPPORTED_COUNTRY_CODES } from './constants';

class PromotedGigsService {
    showPromotedGigsSuggestions = (mode) => {
        const { countryCode, isMobile, experience } = getContext();

        return (
            this.isUserFromSupportedCountry(countryCode) &&
            !isMobile &&
            !experience?.isBusiness &&
            mode !== SearchBarMode.LoggedOut
        );
    };

    isUserFromSupportedCountry = (countryCode) => {
        if (!countryCode || !isString(countryCode)) {
            return false;
        }

        return PROMOTED_GIGS_SUPPORTED_COUNTRY_CODES.includes(countryCode.toLowerCase());
    };
}

export default PromotedGigsService;
