import { getContext } from '@fiverr-private/fiverr_context';
import { bigQuery } from '@fiverr-private/obs';

const enrichEvent = (params) => {
    const { locale, userId, pageCtxId } = getContext();

    return {
        ...params,
        user: {
            ...(userId && { id: userId }),
            locale,
        },
        page: {
            ctx_id: pageCtxId,
        },
    };
};

const sendBigQueryEvent = (event) => {
    const enrichedEvent = enrichEvent(event);

    bigQuery.send(enrichedEvent);
};

export default sendBigQueryEvent;
