import { logger } from '@fiverr-private/obs';
import { isBrowser } from '../../../utils/isBrowser';
import { SigningModalType } from '../../../shared/constants/signingModalType';
import { triggerServiceGrafanaReporter } from '../../events/grafana/grafana.reporter';
import {
    triggerApiMapSymbol,
    queuedTriggerMapSymbol,
    queuedArgumentsMapSymbol,
    FALLBACK_TIMEOUT_MS,
} from './constants';
import { QueuedTrigger, TriggerCallback } from './types';

export class TriggerService {
    constructor() {
        if (!isBrowser()) {
            return;
        }

        window[triggerApiMapSymbol] = window[triggerApiMapSymbol] || new Map<string, TriggerCallback>();
        window[queuedTriggerMapSymbol] = window[queuedTriggerMapSymbol] || new Map<string, QueuedTrigger[]>();
        window[queuedArgumentsMapSymbol] = window[queuedArgumentsMapSymbol] || new Map<string, any[]>();
    }

    registerTriggerApi = (signingModalType: SigningModalType, callback: TriggerCallback) => {
        if (!isBrowser()) {
            return;
        }

        window[triggerApiMapSymbol].set(signingModalType.name, callback);

        this.handleQueuedTrigger(signingModalType);
    };

    handleQueuedTrigger = (signingModalType: SigningModalType) => {
        if (!isBrowser()) {
            return;
        }

        const pendingCallbacks = window[queuedTriggerMapSymbol].get(signingModalType.name);

        if (!pendingCallbacks) {
            return;
        }

        const queuedArguments = window[queuedArgumentsMapSymbol].get(signingModalType.name) || [];

        this.trigger(signingModalType, ...queuedArguments);

        pendingCallbacks.forEach((callback) => callback());

        window[queuedTriggerMapSymbol].delete(signingModalType.name);
        window[queuedArgumentsMapSymbol].delete(signingModalType.name);
    };

    trigger = async (signingModalType: SigningModalType, ...args: any[]) =>
        new Promise<void>((resolve, reject) => {
            if (!isBrowser()) {
                return;
            }

            const triggerCallback = window[triggerApiMapSymbol].get(signingModalType.name);

            if (!triggerCallback) {
                const rejectTriggerTimeout = setTimeout(() => {
                    logger.warn(`Identification Trigger: Failed to resolve a trigger for ${signingModalType.name}`);
                    triggerServiceGrafanaReporter.count(`identification.trigger.regular.fail.${signingModalType.name}`);

                    const existingPendingCallbacks = window[queuedTriggerMapSymbol].get(signingModalType.name) || [];
                    const filteredPendingCallbacks = existingPendingCallbacks.filter(
                        (callback) => callback !== resolveTrigger
                    );
                    window[queuedTriggerMapSymbol].set(signingModalType.name, filteredPendingCallbacks);

                    reject();
                }, FALLBACK_TIMEOUT_MS);

                const resolveTrigger = () => {
                    clearTimeout(rejectTriggerTimeout);
                    resolve();
                };

                const pendingCallbacks = window[queuedTriggerMapSymbol].get(signingModalType.name) || [];
                const newPendingCallbacks = [...pendingCallbacks, resolveTrigger];

                window[queuedTriggerMapSymbol].set(signingModalType.name, newPendingCallbacks);
                window[queuedArgumentsMapSymbol].set(signingModalType.name, args);

                return;
            }

            triggerCallback(...args);
            resolve();
        });
}

export default new TriggerService();
