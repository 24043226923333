import React, {
  AriaAttributes,
  FocusEvent,
  FocusEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { PopoverAnchor, usePopoverContext } from '@fiverr-private/popover';
import { renderChildren } from '@fiverr-private/ui_utils';
import { MenuItem } from '../Dropdown/types';
import useDropdownContext from '../Dropdown/useDropdownContext';
import type { DropdownAnchorProps } from './types';

const DropdownAnchor = forwardRef<HTMLElement, DropdownAnchorProps>(
  ({ disabled, children, fullWidth, onFocus, onBlur, embed }, ref) => {
    const { open, setOpen } = usePopoverContext();
    const { items, values, searchWhenClosed, anchorRef, onChange, isMultiSelect, searchOnKeyPress } =
      useDropdownContext();
    const hasOpened = useRef(open);

    useImperativeHandle(ref, () => anchorRef.current as HTMLElement);

    useEffect(() => {
      if (open) {
        hasOpened.current = true;
      }
    }, [open, hasOpened]);

    useEffect(() => {
      if (open) {
        const focusedElement = anchorRef.current as HTMLDivElement;
        onFocus?.({ target: focusedElement } as FocusEvent<HTMLDivElement>);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearchKeys = useCallback(
      (event: KeyboardEvent) => {
        const { key } = event;

        const isValidKey = /^[a-zA-Z0-9]$/.test(key);
        if (!isValidKey) {
          return;
        }

        event.preventDefault();

        if (isMultiSelect.current || !searchWhenClosed || open) {
          return;
        }

        const selectedValue = Object.values(values)[0];
        const selectedValueIndex = items.findIndex((item) => item.value === selectedValue);
        const listStartIndex = selectedValueIndex === -1 ? 0 : selectedValueIndex;

        const list = [...items.slice(listStartIndex), ...items.slice(0, listStartIndex)];

        for (const item of list) {
          const itemElement = item.ref.current;
          const isSelected = item?.value === selectedValue;
          const firstLetter = itemElement?.textContent?.[0].toLocaleLowerCase();

          if (firstLetter === key.toLowerCase() && !isSelected && !item.disabled) {
            onChange(item as MenuItem & Pick<HTMLInputElement, 'checked' | 'name' | 'value' | 'type'>);
            break;
          }
        }
      },
      [isMultiSelect, items, onChange, open, searchWhenClosed, values]
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        const { code } = event;

        if (code === 'ArrowUp' || code === 'ArrowDown' || code === 'Space') {
          event.preventDefault();
          setOpen(true);
        } else if (searchOnKeyPress) {
          handleSearchKeys(event);
        }
      },
      [handleSearchKeys, setOpen, searchOnKeyPress]
    );

    useEffect(() => {
      const anchorElement = anchorRef.current;
      if (!anchorElement) {
        return;
      }
      if (!open) {
        anchorElement?.addEventListener('keydown', handleKeyDown);
      } else {
        anchorElement.removeEventListener('keydown', handleKeyDown);
      }
      return () => anchorElement.removeEventListener('keydown', handleKeyDown);
    }, [anchorRef, handleKeyDown, open]);

    const handleBlur: FocusEventHandler<HTMLDivElement> = (event) => {
      if (open) {
        return;
      }
      onBlur?.(event);
    };

    const handleFocus: FocusEventHandler<HTMLDivElement> = (event) => {
      if (hasOpened.current) {
        hasOpened.current = false;
        return;
      }
      onFocus?.(event);
    };

    return (
      <PopoverAnchor
        fullWidth={fullWidth}
        disabled={disabled}
        ariaHasPopup="listbox"
        onFocus={handleFocus}
        onBlur={handleBlur}
        embed={embed}
      >
        {embed
          ? (anchorProps: Pick<AriaAttributes, 'aria-expanded' | 'aria-haspopup'>) =>
              renderChildren(children, { ref: anchorRef, ...anchorProps })
          : renderChildren(children, { ref: anchorRef })}
      </PopoverAnchor>
    );
  }
);

DropdownAnchor.displayName = 'DropdownAnchor';

export default DropdownAnchor;
