import { isObject } from 'lodash';
import { random } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import * as SuggestionsSelector from '../selectors/Suggestions';
import * as InitialStateApi from '../apis/InitialState';
import { clearLocalStorageHistory, getLocalStorageHistory } from '../utils/localStorageHistory';
import { NUM_OF_DISPLAYED_RECENT_SEARCHES } from '../utils/localStorageHistory/constants';
import * as PromotedGigsApi from '../apis/PromotedGigsApi';

export const SET_SEARCH = 'set_search';
export const setSearch = (search) => ({
    type: SET_SEARCH,
    payload: { search },
});

export const SET_ORIGINAL_TERM = 'set_original_term';
export const setOriginalTerm = (originalTerm) => ({
    type: SET_ORIGINAL_TERM,
    payload: { originalTerm },
});

export const SUBMIT_SEARCH = 'submit_search';
export const submitSearch = ({ queryParams = {}, submitSource = 'unknown' }) => ({
    type: SUBMIT_SEARCH,
    payload: {
        queryParams,
        submitSource,
    },
});

export const SET_SUGGESTIONS = 'set_suggestions';
export const setSuggestions =
    (fetchedSuggestions = {}) =>
    (dispatch, getState) => {
        const { history = {}, popular = {} } = getState();
        return dispatch({
            type: SET_SUGGESTIONS,
            payload: { suggestions: { ...popular, ...history, ...fetchedSuggestions } },
        });
    };

export const UNSET_SUGGESTIONS = 'unset_suggestions';
export const unsetSuggestions = (ids) => ({
    type: UNSET_SUGGESTIONS,
    payload: { ids },
});

export const SELECT_SUGGESTION = 'select_suggestion';
export const selectSuggestion = (id) => ({
    type: SELECT_SUGGESTION,
    payload: { id },
});

export const DIRECTIONS = {
    NEXT: 'next',
    BACK: 'back',
};

export const SET_DIRECTION = 'set_direction';
export const setDirection = (direction) => ({
    type: SET_DIRECTION,
    payload: direction,
});

export const SET_POPULAR = 'set_popular';
export const SET_ACTIVE_EXPERIMENTS = 'set_active_experiments';
export const setPopular = (popular = []) => ({
    type: SET_POPULAR,
    payload: suggestionsTransformer(popular, SuggestionsSelector.POPULAR_SEARCH),
});

export const setActiveExperiments = (data) => ({
    type: SET_ACTIVE_EXPERIMENTS,
    payload: data,
});

export const CLEAR_HISTORY = 'clear_history';
export const clearHistory = () => (dispatch, getState) => {
    const state = getState();
    const ids = SuggestionsSelector.historySuggestionIds(state);

    clearLocalStorageHistory();
    dispatch(unsetSuggestions(ids));
    dispatch({ type: CLEAR_HISTORY });
};

export const SET_HISTORY = 'set_history';
export const setHistory = (history = []) => ({
    type: SET_HISTORY,
    payload: suggestionsTransformer(history, SuggestionsSelector.HISTORY_SEARCH),
});

export const SET_PROMOTED_GIGS_SUGGESTIONS = 'set_promoted_gigs_suggestions';

export const setPromotedGigsSuggestions = (data) => ({
    type: SET_PROMOTED_GIGS_SUGGESTIONS,
    payload: data,
});

export const loadPromotedGigsByAutocompleteQueries = (queries, currentDLC) => (dispatch) => {
    // PromotedGigsApi.getPromotedGigs() already has catch block
    // no need to handle request errors.
    // eslint-disable-next-line promise/catch-or-return
    PromotedGigsApi.getPromotedGigs(queries).then((data) => {
        const { currentDLC: dlc } = data?.subCategoryConfig || {};

        // We do not want to update the gigs if the dlc has not changed
        if (dlc === currentDLC) {
            return;
        }

        dispatch(setPromotedGigsSuggestions(data));
    });
};

export const getInitialState = () => (dispatch, getState) => {
    const { suggestions } = getState();
    const { userId } = getContext();

    const recentSearchesFromLocalStorage = getLocalStorageHistory()
        .slice(0, NUM_OF_DISPLAYED_RECENT_SEARCHES)
        .map(({ search }) => search);

    if (userId) {
        dispatch(setHistory(recentSearchesFromLocalStorage));
    }

    // InitialStateApi.get() already has catch block
    // no need to handle request errors.
    // eslint-disable-next-line promise/catch-or-return
    InitialStateApi.get().then(({ popular_searches, active_experiments }) => {
        dispatch(setPopular(popular_searches));
        dispatch(setSuggestions(suggestions));
        dispatch(setActiveExperiments(active_experiments));
    });
};

export const SEARCH_WILL_SUBMIT = 'search_will_submit';
export const searchWillSubmit = ({ searchPath = '' }) => ({
    type: SEARCH_WILL_SUBMIT,
    payload: { searchPath },
});

export const selectNextSuggestion = () => (dispatch, getState) => {
    const state = getState();
    const suggestionIds = SuggestionsSelector.orderedSuggestionIds(state);
    const selectedSuggestionId = SuggestionsSelector.selectedSuggestionId(state);
    const selectedIndex = suggestionIds.indexOf(selectedSuggestionId);

    // If no selected suggestion -> select the first suggestion
    if (selectedSuggestionId === '') {
        return dispatch(selectSuggestion(suggestionIds[0]));
    }

    // If no selected suggestion found -> select no suggestion
    if (selectedIndex === -1) {
        return dispatch(selectSuggestion(''));
    }

    // If selected suggestion is the last -> select no suggestion
    if (selectedIndex === suggestionIds.length - 1) {
        return dispatch(selectSuggestion(''));
    }

    // Select next suggestion
    return dispatch(selectSuggestion(suggestionIds[selectedIndex + 1]));
};

export const selectPreviousSuggestion = () => (dispatch, getState) => {
    const state = getState();
    const suggestionIds = SuggestionsSelector.orderedSuggestionIds(state);
    const selectedSuggestionId = SuggestionsSelector.selectedSuggestionId(state);
    const selectedIndex = suggestionIds.indexOf(selectedSuggestionId);

    // If no selected suggestion -> select the last suggestion
    if (selectedSuggestionId === '') {
        return dispatch(selectSuggestion(suggestionIds[suggestionIds.length - 1]));
    }

    // If no selected suggestion found -> select no suggestion
    if (selectedIndex === -1) {
        return dispatch(selectSuggestion(''));
    }

    // If selected suggestion is the first -> select no suggestion
    if (selectedIndex === 0) {
        return dispatch(selectSuggestion(''));
    }

    // Select previous suggestion
    return dispatch(selectSuggestion(suggestionIds[selectedIndex - 1]));
};

const suggestionsTransformer = (suggestions, type) =>
    suggestions.reduce((acc, value, index) => {
        const id = random.uid();
        return {
            ...acc,
            [id]: {
                id,
                value,
                group: type,
                order: index + 1,
                ...(isObject(value) && { ...value }),
            },
        };
    }, {});
