/**
 * Save method to retrive dataLayer with an initial fallback
 * @returns dataLayer
 */
const getDataLayer = () => {
    if (typeof window === 'undefined') {
        return [];
    }

    window.dataLayer = window.dataLayer || [];

    return window.dataLayer;
};

/**
 * Push event to dataLayer
 * @param {string} event Event name
 */
export const pushEvent = (event: any) => {
    getDataLayer().push(event);
};
