import React, { useCallback } from 'react';
import { bool, node, object, func } from 'prop-types';
import { isEmpty, noop } from 'lodash';
import classnames from 'classnames';
import { white } from '@fiverr-private/sass/helpers/index';
import { Button } from '@fiverr-private/button';
import { Icon } from '@fiverr-private/fit';
import { ChevronDownIcon } from '@fiverr-private/icons';
import { reportBigQueryClick, reportMixpanelClick } from '../../utils/reporter';
import styles from './DropdownTriggerButton.module.scss';

export const DropdownTriggerButton = ({
    title,
    isTransparent,
    isOpen,
    bigQueryData,
    mpData,
    onClick: onButtonClick = noop,
}) => {
    const onClick = useCallback(() => {
        !isEmpty(bigQueryData) && reportBigQueryClick(bigQueryData);
        !isEmpty(mpData) && reportMixpanelClick(mpData);
        onButtonClick();
    }, [bigQueryData, mpData, onButtonClick]);

    return (
        <Button
            className={classnames(styles.buttonTrigger, { [styles.buttonTransparent]: isTransparent })}
            intent="secondary"
            variant="ghost"
            onClick={onClick}
        >
            {title}
            <span className="flex flex-items-center">
                <Icon
                    className={classnames(styles.chevron, { [styles.chevronIsOpen]: isOpen })}
                    color={isTransparent ? white : undefined}
                    size={12}
                >
                    <ChevronDownIcon />
                </Icon>
            </span>
        </Button>
    );
};

DropdownTriggerButton.propTypes = {
    title: node,
    isTransparent: bool,
    isOpen: bool,
    bigQueryData: object,
    mpData: object,
    onClick: func,
};
