import { constants } from '../lib';
import propTypes from './propTypes';

const I18nProvider = ({ children, locale = constants.DEFAULT_LOCALE }) => {
    // eslint-disable-next-line no-undef
    const contextualizedI18n = i18n.spawn(locale);

    return children({ i18n: contextualizedI18n });
};

I18nProvider.propTypes = {
    ...propTypes,
};

export default I18nProvider;
