import { getContext } from '@fiverr-private/fiverr_context';

/**
 * Wrapper to i18n.has that encapsulates the need to add $scope:locale
 * @param {String} key - String representing dot notation
 * @param {Object} data - Interpolation data
 * @returns {Boolean} - TRUE if the translation exists
 */
export const hasTranslation = (key: string, data: Record<string, unknown> = {}): boolean => {
    const { locale } = getContext();
    data = {
        ...data,
        $scope: data.$scope || locale,
    };

    return i18n.has(key, data);
};
