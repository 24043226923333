/**
 * Trim string.
 * @param {string} str - string to trim.
 * @returns {string}
 */
export const trimForRegExp = (str) => (str || '').trim();

/**
 * Escape string to be supported by RegExp constructor.
 * @param {string} str - string to escape.
 * @returns {string}
 */
export const escapeForRegExp = (str) => (str || '').replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');

/**
 * Shortcut for trim and escape.
 * @param {string} str - string to strim and escape.
 * @returns {string}
 */
export const trimAndEscapeForRegExp = (str) => escapeForRegExp(trimForRegExp(str));

export const adjustCurrencyObject = (currency) => {
    if (!currency) {
        return {};
    }

    const { code, template, symbol, exchange_rate: exchangeRate, round_amount: roundAmount } = currency;

    if (!code) {
        return currency;
    }

    return {
        name: code,
        rate: parseFloat(exchangeRate),
        template: template.replace('{{symbol}}', symbol).replace('{{code}}', code),
        forceRound: roundAmount !== undefined,
        forceRoundFromAmount: roundAmount,
        symbol,
    };
};
