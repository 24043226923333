import React from 'react';

import classnames from 'classnames';
import { Menu } from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/i18n-react';
import Link from '../../../Links/Link';
import { getBQData } from '../../../../utils/business/bi-utils';
import { OptionItem } from '../OptionItem';

import { getProFreelancerUrl, getProUrl } from '../../utils/url';
import styles from './DefaultMenu.module.scss';

const T_PREFIX = 'header.fiverr_pro_entry';

export const DefaultMenu = () => {
    const linkProps = {
        className: styles.optionLink,
        target: '_blank',
    };

    const HREF = {
        pro: getProUrl(),
        freelancer: getProFreelancerUrl(),
    };

    return (
        <Menu className={classnames(styles.container, 'header-package business-entry solutions-menu')}>
            <Menu.Item className={styles.menuItem}>
                <Link {...linkProps} href={HREF.pro} bigQueryData={getBQData({ type: 'hire_freelancer' })}>
                    <OptionItem
                        icon={<span className={styles.hireIcon} />}
                        title={<I18n k={`${T_PREFIX}.menu.hire_freelancer.title`} />}
                        subTitle={<I18n k={`${T_PREFIX}.menu.hire_freelancer.sub_title`} />}
                    />
                </Link>
            </Menu.Item>
            <Menu.Item className={styles.menuItem}>
                <Link {...linkProps} href={HREF.freelancer} bigQueryData={getBQData({ type: 'i_am_freelancer' })}>
                    <OptionItem
                        icon={<span className={styles.iamIcon} />}
                        title={<I18n k={`${T_PREFIX}.menu.iam_freelancers.title`} />}
                        subTitle={<I18n k={`${T_PREFIX}.menu.iam_freelancers.sub_title`} />}
                    />
                </Link>
            </Menu.Item>
        </Menu>
    );
};
