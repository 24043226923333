import React, { useState, useRef } from 'react';
import {
  useFloating,
  autoUpdate,
  flip,
  shift,
  offset as offsetFunc,
  arrow,
  autoPlacement,
} from '@floating-ui/react-dom';
import { getContext } from '@fiverr-private/fiverr_context';
import type { PopoverProps } from './types';
import { PopoverContext } from './PopoverContext';

const Popover = ({
  children,
  isOpen,
  initialOpen = false,
  position = 'top',
  offset = 4,
  onOpenChanged = () => undefined,
  triggerEvent = 'click',
  disableFlip = false,
}: PopoverProps) => {
  const [innerOpen, setInnerOpen] = useState(initialOpen);
  const arrowRef = useRef<HTMLDivElement>(null);
  const { isTouch } = getContext();
  const open = isOpen === undefined ? innerOpen : isOpen;
  const isAutoPosition = position === 'auto';
  const positionSide = position.split('-')[0];
  const isHorizontalPosition = positionSide === 'right' || positionSide === 'left';
  const arrowExists = arrowRef?.current;
  const arrowMiddleware = arrowExists ? arrow({ element: arrowRef, padding: 8 }) : undefined;
  const arrowOffset = arrowExists ? Math.sqrt(2 * arrowRef.current.offsetWidth ** 2) / 2 : 0;
  const flipMiddleware = disableFlip || isAutoPosition ? undefined : flip();
  const autoPlacementMiddleware = isAutoPosition ? autoPlacement() : undefined;

  const setOpen = (value: boolean) => {
    setInnerOpen(value);
    onOpenChanged(value);
  };

  const floatingData = useFloating({
    placement: isAutoPosition ? undefined : position,
    open,
    whileElementsMounted: autoUpdate,
    middleware: [
      offsetFunc(offset + arrowOffset),
      shift({ mainAxis: !isHorizontalPosition }),
      flipMiddleware,
      autoPlacementMiddleware,
      arrowMiddleware,
    ],
  });

  return (
    <PopoverContext.Provider
      value={{
        floatingData,
        setOpen,
        open,
        arrowRef,
        triggerEvent: isTouch || triggerEvent === 'click' ? 'click' : 'hover',
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};

Popover.displayName = 'Popover';

export default Popover;
