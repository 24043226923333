import { string, func } from 'prop-types';

/**
 * The component's prop types.
 * @type {Object}
 */
export default {
    /**
     * The component's content to provide.
     */
    children: func.isRequired,

    /**
     * The locale to spawn a contextual i18n instance that will be provided to the passed children.
     */
    locale: string,
};
