import { ReactElement } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { injectTemplates, shouldInjectTemplates, TemplatesParam } from '../injectTemplates';

export interface TranslateOptionsParams {
    params?: object;
    locale?: string;
}

export interface TranslateOptionsWithTemplatesParams extends TranslateOptionsParams {
    templates?: TemplatesParam;
}

/**
 * Wrapper to i18n.t that encapsulates the need to add $scope:locale in render flow (CSR, SSR), while also providing `templates` support.
 * @param {String} key - String representing dot notation of the translation key
 * @param {Object} options - the options param that enables customization when needed.
 * @param {Object} options.params - Interpolation params
 * @param {Object} options.templates - Custom template to interpolate
 * @param {String} options.locale - Custom locale, usually when used outside the render flow (CSR, SSR)
 * @returns {String | React.Component} - Translated and interpolated string or a React Component
 */
export function translate<T extends string | ReactElement = string>(key: string, options?: TranslateOptionsParams): T;
export function translate<T extends string | ReactElement = ReactElement>(
    key: string,
    options: TranslateOptionsWithTemplatesParams
): T;
export function translate(key: string, options: TranslateOptionsWithTemplatesParams = {}): string | ReactElement {
    if (!key) {
        throw new Error('key must be provided to `translate`.');
    }

    let locale;

    if (options.locale) {
        locale = options.locale;
    } else {
        locale = getContext().locale;
    }

    const data = {
        ...options.params,
        $scope: locale,
    };

    let output;
    try {
        output = i18n.t(key, data);
    } catch (e) {
        output = key;
    }

    if (shouldInjectTemplates(output)) {
        return injectTemplates(output, options.templates);
    }

    return output;
}
