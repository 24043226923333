/**
 * The default localization context to use when context does not exists over the current runtime
 * @type {Object}
 */
export const DEFAULT_LOCALIZATION_CONTEXT: localizationContextParams = {
    browserLanguage: '',
    countryCode: '',
    currency: '',
    experienceLocale: 'en-US',
    formattingLocale: 'en-GB',
    shouldFormat: true,
};

export interface localizationContextParams {
    browserLanguage?: string;
    countryCode?: string;
    currency?: string;
    experienceLocale?: string;
    formattingLocale?: string;
    shouldFormat?: boolean;
}

export interface LocalizationContext {
    localizationContext?: localizationContextParams;
}
