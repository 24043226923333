import { SET_DIRECTION } from '../../actions';

const suggestionDirection = (state = '', action) => {
    switch (action.type) {
        case SET_DIRECTION:
            return action.payload;
        default:
            return state;
    }
};

export default suggestionDirection;
