import { SET_PROMOTED_GIGS_SUGGESTIONS } from '../../actions';

/**
 * Promoted Gigs suggestions reducer
 * stores promoted gigs suggestions in state
 */
const promotedGigsSuggestions = (state = {}, action) => {
    switch (action.type) {
        case SET_PROMOTED_GIGS_SUGGESTIONS:
            return action.payload;
        default:
            return state;
    }
};

export default promotedGigsSuggestions;
