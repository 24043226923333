import { useEffect } from 'react';
import useLatest from '../useLatest';

const useEventListener = (
    eventName: string,
    handler: (event: Event) => void,
    element: HTMLElement | Window | null,
    options?: AddEventListenerOptions
) => {
    const savedHandler = useLatest(handler);

    useEffect(() => {
        if (!(element && element.addEventListener)) {
            return;
        }

        const listener = (event) => savedHandler.current(event);

        element.addEventListener(eventName, listener, options);

        return () => {
            element.removeEventListener(eventName, listener, options);
        };
    }, [eventName, element, options, savedHandler]);
};

export default useEventListener;
