import { pushEvent } from '../services/dataLayer';
import { Event } from './types';

/**
 * Publish Onboarding Questionnaire event "Buyer or Buyer and Seller"
 */
export const buyerNext = () => {
    pushEvent({
        event: Event.onboardingQuestionnaireBuyerNext,
    });
};

/**
 * Publish Onboarding Questionnaire event "Buyer or Buyer and Seller+Not only Personal Use"
 */
export const notOnlyPersonalUseNext = () => {
    pushEvent({
        event: Event.onboardingQuestionnaireNotOnlyPersonalUseNext,
    });
};

/**
 * Publish Onboarding Questionnaire event "Buyer or Buyer and Seller+Primary job or side job or both+company size"
 */
export const companySizeNext = ({ size }) => {
    pushEvent({
        event: Event.onboardingQuestionnaireCompanySizeNext,
        onboardingQuestionnaireCompanySize: size,
    });
};

/**
 * Publish Onboarding Questionnaire event "Buyer or Buyer and Seller+Primary job or side job or both+any answer+any answer"
 */
export const buyerFinish = () => {
    pushEvent({
        event: Event.onboardingQuestionnaireBuyerFinish,
    });
};
