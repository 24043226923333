import { pushEvent } from '../services/dataLayer';
import { Event } from './types';

/**
 *  Contact me clicked event for gig page and seller pages
 */
export const contactMeClicked = () => {
    pushEvent({
        event: Event.contactMeClicked,
    });
};
