import React, { forwardRef } from 'react';
import Flex from '../Flex';
import { CenterProps } from './types';

const Center = forwardRef<HTMLElement, CenterProps>(
  ({ children, direction = { default: 'column' }, ...restProps }: CenterProps, ref) => (
    <Flex
      ref={ref}
      alignItems={{ default: 'center' }}
      justifyContent={{ default: 'center' }}
      direction={direction}
      {...restProps}
    >
      {children}
    </Flex>
  )
);

Center.displayName = 'Center';

export default Center;
