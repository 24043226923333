import React from 'react';
import { createSanitizedMarkup, SanitizationGroup } from '@fiverr-private/futile';
import { constants } from '../lib';
import injectTemplates from './injectTemplates';
import propTypes from './propTypes';

const I18n = ({ k, templates, parseMarkup = false, params = {}, locale = constants.DEFAULT_LOCALE }) => {
    if (!k) {
        return null;
    }

    let output;

    try {
        // eslint-disable-next-line no-undef
        output = i18n.t(k, { ...params, $scope: locale });
    } catch (e) {
        output = k;
    }

    if (templates) {
        return injectTemplates(output, templates);
    }

    if (parseMarkup) {
        return <span dangerouslySetInnerHTML={createSanitizedMarkup(output, SanitizationGroup.BASE)} />;
    }

    return output;
};

I18n.propTypes = {
    ...propTypes,
};

export default I18n;
