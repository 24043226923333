
import { hydrateCSRTemplate } from "@fiverr-private/layout_lib/CSRTemplate";

import Header from '/home/circleci/app/packages/layout-header-component/src/entries/LoggedOut';

const element = typeof document !== 'undefined' && document.querySelector('#Header');
if (element) {
    const props = window.initialData['Header'];

    hydrateCSRTemplate(Header, props, element, 'Header', 'LoggedOut');
}
export default Header;
